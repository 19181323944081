const initialState = {
  currentLanguage: 'cn',
  id: sessionStorage.getItem('id') || '',
  login: sessionStorage.getItem('loginStatus') || false,
  token: sessionStorage.getItem('token') || '',
  role: sessionStorage.getItem('userRole') || '',
  username: localStorage.getItem('CacheUsername') || '',
  checkResultdate: '',
  checkResultPlatForm: [],
  UserIDForSearch:  sessionStorage.getItem('UserIDForSearch') || '',
  UserForSearch: sessionStorage.getItem('UserForSearch') || '',
  SearchDateFrom:  sessionStorage.getItem('SearchDateFrom') || '',
  SearchDateTo:  sessionStorage.getItem('SearchDateTo') || '',
  PlatformListToSearch: JSON.parse(sessionStorage.getItem('PlatformForSearch')) || [],
  redModeToSearch: sessionStorage.getItem('PatternForSearch') || '',
  ReceiptData: [],
  ReBuyData: '',
  ReBuyUser: '',
  SelectedUserKeyFormula: '',
  UserAndDownlineData: JSON.parse(sessionStorage.getItem('getUserDownlineData')) || '',
  // UserAndDownlineData: '',
  ReportData: JSON.parse(sessionStorage.getItem('ReportData')) || '',
  printerIP: '',
  JingNan: '',
  // PlatformListToSearch: [],
  // redModeToSearch: '',
  BuyMode: '',
  firstTimeLog: parseInt(sessionStorage.getItem('FirstTimeLogin')),
  AbleRed: parseInt(sessionStorage.getItem('AbleRed')) || '',
  OneStarBao: parseInt(sessionStorage.getItem('OneStarBao')),
  currentKeyboard: 'KeyboardA',
  loginv2: sessionStorage.getItem('loginv2') || false,
  AbleSGD: parseInt(sessionStorage.getItem('AbleSGD')) || '',
  oldTicketDate: sessionStorage.getItem('oldTicketDate') || '',
  oldTicketDateBy: sessionStorage.getItem('oldTicketDateBy') || '',
  oldTicketNumber: sessionStorage.getItem('oldTicketNumber') || '',
  oldTicketIDType:  sessionStorage.getItem('oldTicketIDType') || '',
  oldTicketIndex:  sessionStorage.getItem('oldTicketIndex') || '',
  oldTicketTotal:  sessionStorage.getItem('oldTicketTotal') || '',
  AbleTHB: parseInt(sessionStorage.getItem('AbleTHB')) || '',
};
  
function reducer(state = initialState, action) {
  switch (action.type) {
  case 'SET_LANGUAGE':
    return {
      ...state,
      currentLanguage: action.payload,
    };
  case 'CHECK_RESULT':
    return {
      ...state,
      checkResultdate: action.payload.checkResultdate,
      checkResultPlatForm: action.payload.checkResultPlatForm,
    };
  case 'RECEIPTDATA_FOR_PASS':
    return {
      ...state,
      ReceiptData: action.payload.ReceiptData,
      BuyMode: action.payload.BuyMode,
    };
  case 'REPORTDATA_FOR_PASS':
    return {
      ...state,
      ReportData: action.payload.ReportData,
    };
  case 'REBUYDATA_FOR_PASS':
    return {
      ...state,
      ReBuyData: action.payload.ReBuyData,
      ReBuyUserID: action.payload.ReBuyUserID,
      ReBuyUser: action.payload.ReBuyUser,
    };
  case 'INFO_FOR_SEARCH':
    return {
      ...state,
      UserIDForSearch: action.payload.UserIDForSearch,
      UserForSearch: action.payload.UserForSearch,
      SearchDateFrom: action.payload.SearchDateFrom,
      SearchDateTo: action.payload.SearchDateTo,
      SelectedUserKeyFormula: action.payload.SelectedUserKeyFormula,
      PlatformListToSearch: action.payload.PlatformListToSearch,
      redModeToSearch: action.payload.redModeToSearch,
    };
  case 'USER_DOWNLINE_DATA':
    return {
      ...state,
      UserAndDownlineData: action.payload.UserAndDownlineData,
    };
  case 'LOGIN_STATUS':
    return {
      ...state,
      login: action.payload.login,
      id: action.payload.id,
      username: action.payload.username,
      role: action.payload.role,
      token: action.payload.token,
      firstTimeLog: action.payload.firstTimeLog,
    };
  case 'Able_Red':
    return {
      ...state,
      AbleRed: action.payload.AbleRed,
    };
  case 'OneStarBao':
    return {
      ...state,
      OneStarBao: action.payload.OneStarBao,
    };
  case 'SET_KEYBOARD':
    return {
      ...state,
      currentKeyboard: action.payload,
    };
  case 'Able_SGD':
    return {
      ...state,
      AbleSGD: action.payload.AbleSGD,
    };
  case 'Able_THB':
    return {
      ...state,
      AbleTHB: action.payload.AbleTHB,
    };
  case 'OldTicketInfo':
    return {
      ...state,
      oldTicketDate: action.payload.oldTicketDate,
      oldTicketDateBy: action.payload.oldTicketDateBy,
      oldTicketNumber: action.payload.oldTicketNumber,
      oldTicketIDType: action.payload.oldTicketIDType,
      oldTicketIndex: action.payload.oldTicketIndex,
      oldTicketTotal: action.payload.oldTicketTotal,
    };  
  default:
    return state;
  }
}
export default reducer;
  