export function processEditPasteText(
  providedPF,
  displayPlatform,
  ToprocessOriData,
  JingNan,
) {
  let replaceWord = '';
  let processArraytoReturn = [];
  let PFConvertArray = [];
  let covertprovidedPF = providedPF.split('');
  for (var x = 0; x < covertprovidedPF.length; x++) {
    PFConvertArray.push(
      covertprovidedPF[x]
        .replace(/1/g, 'M')
        .replace(/2/g, displayPlatform === 'MPTSWBK' ? 'P' : 'K')
        .replace(/3/g, 'T')
        .replace(/4/g, 'S')
        .replace(/5/g, 'W')
        .replace(/6/g, 'B')
        .replace(/7/g, displayPlatform === 'MPTSWBK' ? 'K' : 'D')
        .replace(/8/g, '8')
        .replace(/9/g, '9')
        .replace(/[*]/g, ''),
    );
  }
  for (let i = 0; i < ToprocessOriData.length; i++) {
    if (displayPlatform === 'MPTSWBK') {
      replaceWord = ToprocessOriData[i]
        .toUpperCase()
        .replace(/-/g, '#')
        .replace(/ /g, '#')
        .replace(/万能/g, 'M')
        .replace(/萬能/g, 'M')
        .replace(/MAGNUM/g, 'M')
        .replace(/MAG/g, 'M')
        .replace(/跑马/g, 'P')
        .replace(/跑馬/g, 'P')
        .replace(/KUDA/g, 'P')
        .replace(/TOTO/g, 'T')
        .replace(/多多/g, 'T')
        .replace(/新加坡/g, 'S')
        .replace(/SINGAPORE/g, 'S')
        .replace(/SG/g, 'S');
    } else {
      replaceWord = ToprocessOriData[i]
        .toUpperCase()
        .replace(/-/g, '#')
        .replace(/ /g, '#')
        .replace(/万能/g, 'M')
        .replace(/萬能/g, 'M')
        .replace(/MAGNUM/g, 'M')
        .replace(/MAG/g, 'M')
        .replace(/跑马/g, 'K')
        .replace(/跑馬/g, 'K')
        .replace(/KUDA/g, 'K')
        .replace(/TOTO/g, 'T')
        .replace(/多多/g, 'T')
        .replace(/新加坡/g, 'S')
        .replace(/SINGAPORE/g, 'S')
        .replace(/SG/g, 'S');
    }
    processArraytoReturn.push(replaceWord);
  }
  let FinalResult = [];
  for (let y = 0; y < processArraytoReturn.length; y++) {
    let replaceDetectWord = '';
    if (!processArraytoReturn[y].startsWith('D#')) {
      if (displayPlatform === 'MPTSWBK') {
        replaceDetectWord = processArraytoReturn[y]
          .replace(/M/g, '1')
          .replace(/P/g, '2')
          .replace(/T/g, '3')
          .replace(/S/g, '4')
          .replace(/W/g, '5')
          .replace(/B/g, '6')
          .replace(/K/g, '7');
      } else {
        replaceDetectWord = processArraytoReturn[y]
          .replace(/M/g, '1')
          .replace(/K/g, '2')
          .replace(/T/g, '3')
          .replace(/S/g, '4')
          .replace(/W/g, '5')
          .replace(/B/g, '6')
          .replace(/D/g, '7');
      }
    } else {
      replaceDetectWord = processArraytoReturn[y];
    }
    if (JingNan === 1 && y === 0 && !replaceDetectWord.includes('#')) {
      FinalResult.push('#' + replaceDetectWord);
    } else {
      FinalResult.push(replaceDetectWord);
    }
  }

  return FinalResult;
}
