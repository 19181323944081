import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Reducers from './reducers';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import CheckLoginStatus from './checkLoginStatus';

function App() {
  const middlewares = applyMiddleware(thunk);
 
  const store = createStore(
    Reducers,
    middlewares,
  )
  return (
    <Provider store={store}>
      <CheckLoginStatus />
    </Provider>
  );
}
export default App;
