import '../App.css';
// import JSEncrypt from 'jsencrypt';
import React from 'react';
import { connect } from 'react-redux';
import {language} from '../language';
import LoadingLogo from '../img/loading.gif';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';
const { CreateBetOrder, CancelBet, CreateBetOrderSGD, CreateBetOrderTHB } = require('../Api');

class receiptAfterSubmit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      date: new Date(),
      BTData: '',
      TicketStatus: true,
      ProcessData: '',
      todayDate: moment().format('YYYY-MM-DD'),
      SelectModeModalVisible: false,
      deviceListModal: false,
      deviceList: [],
      reBuyOption: [],
      lineData: [
        {
          originalWord: '',
          translatedWord: '',
          Mode: '',
        },
      ],
    }
  }

  async LogoutFunction() {
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('AbleRed');
    sessionStorage.removeItem('FirstTimeLogin');
    sessionStorage.removeItem('Username');
    sessionStorage.removeItem('loginStatus');
    sessionStorage.removeItem('getUserDownlineData');
    sessionStorage.removeItem('ReceiptData');
    sessionStorage.removeItem('UserIDForSearch');
    sessionStorage.removeItem('UserForSearch');
    sessionStorage.removeItem('SearchDateFrom');
    sessionStorage.removeItem('SearchDateTo');
    sessionStorage.removeItem('PatternForSearch');
    sessionStorage.removeItem('PlatformForSearch');
    sessionStorage.removeItem('ReportData');
    this.props.setUserDownlineData('');
    this.props.setLoginStatus(false, '', '', '', '');
  }

  async ProcessRebuyOption() {
    const rebuyOption = this.state.reBuyOption;
    rebuyOption.push(language[this.props.currentLanguage].Ticket);
    if (this.props.AbleRed === 1) {
      rebuyOption.push(language[this.props.currentLanguage].RedTicket);
    }
    if (this.props.AbleSGD === 1) {
      rebuyOption.push(language[this.props.currentLanguage].Ticket + '(SGD)');
    }
    if (this.props.AbleTHB === 1) {
      rebuyOption.push(language[this.props.currentLanguage].Ticket + '(THB)');
    }
    rebuyOption.push(language[this.props.currentLanguage].SimpleBT);
    this.setState({reBuyOption: rebuyOption});
  }

  async componentDidMount() {
    if (this.props.UserAndDownlineData === '') {
      this.props.history.push('/')
    }
    const Receipt = this.props.ReceiptData.receipt || '';
    if (Receipt === '') {
      this.props.history.goBack();
    } else {
      this.RenderWord(this.props.ReceiptData.receipt);
      this.ProcessRebuyOption();
      this.setState({
        BTData: this.props.ReceiptData.receipt,
        loading: false,
      });
    } 
  }
  
  async onpressCancel() {
    if ( this.state.TicketStatus === true) {
      if (window.confirm(language[this.props.currentLanguage].CancelConfirmation)) {
        this.CancelReceiptFunction();
      }

    } else {
      if (window.confirm(language[this.props.currentLanguage].RebuyConfirmation)) {
        this.onpressEditRebuy();
      }
    }
  }

  async onpressEditRebuy() {
    if (this.state.TotalNum !== 0) {
      if (this.state.nokiaPlatform) {
        this.setState({SelectModeModalVisible: true});
      } else {
        this.setState({SelectModeModalVisible: true});
      }
    } else {
      alert(
        language[this.props.currentLanguage].NoTicket,
      );
    }
  }

  async CancelReceiptFunction() {
    this.setState({loading: true});
    const CancelResult = await CancelBet(
      this.props.ReceiptData.ServerID,
      this.props.token,
      this.props.username,
    );
    if (CancelResult.error === 'Exceed Date' || CancelResult.error === 'Ticket expired') {
      alert(
        language[this.props.currentLanguage].CancelExceedDate,
      );
      this.setState({loading: false});
    } else if (CancelResult.error === 'database  error') {
      alert(
        CancelResult.error,
      );
      this.setState({loading: false});
    } else if (CancelResult.error === 'OverTimeLimit') {
      alert(
        language[this.props.currentLanguage].CancelOverDayTime,
      );
      this.setState({loading: false});
    } else if (CancelResult.error === 'DontHaveAutho') {
      alert(
        language[this.props.currentLanguage].NoAuthoCancel,
      );
      this.setState({loading: false});
    } else if (CancelResult.error === 'Invalid ServerID') {
      alert(
        'Invalid ServerID',
      );
      this.setState({loading: false});
    } else if (CancelResult.error === 'AlreadyCancalled') {
      alert(
        language[this.props.currentLanguage].AlreadyCancel,
      );
      this.setState({loading: false});
    } else if (CancelResult.error === 'Order not finished yet, please wait for awhile') {
      alert(
        language[this.props.currentLanguage].WaitOrderFinish,
      );
      this.setState({loading: false});
    } else if (CancelResult.error) {
      alert(
        CancelResult.error,
      );
      this.setState({loading: false});
    } else if (CancelResult === 'TimeOut') {
      this.LogoutFunction();
    } else {
      this.setState({
        loading: false,
        BTData: CancelResult.CancelReceipt,
        TicketStatus: false,
      });
      this.RenderWord(CancelResult.CancelReceipt);
    }
  }

  async RecallReceiptFunction() {
    let OrderReceiptData = '';
    this.setState({loading: true});
    if (this.props.ReceiptData.Red === 1) {
      OrderReceiptData = await CreateBetOrder(
        this.props.ReceiptData.UserID,
        this.props.ReceiptData.OriginalText,
        this.props.token,
        this.props.username,
        true,
        true,
        '',
        'mobile',
      );
    } else if (this.props.ReceiptData.SGD === 1) {
      OrderReceiptData = await CreateBetOrderSGD(
        this.props.UserIDForSearch,
        this.props.ReceiptData.OriginalText,
        this.props.token,
        this.props.username,
        true,
        true,
        '',
        'mobile',
      );
    } else if (this.props.ReceiptData.THAI === 1) {
      OrderReceiptData = await CreateBetOrderTHB(
        this.props.UserIDForSearch,
        this.props.ReceiptData.OriginalText,
        this.props.token,
        this.props.username,
        true,
        true,
        '',
        'mobile',
      );
    } else {
      OrderReceiptData = await CreateBetOrder(
        this.props.ReceiptData.UserID,
        this.props.ReceiptData.OriginalText,
        this.props.token,
        this.props.username,
        false,
        true,
        '',
        'mobile',
      );
    }
    if (OrderReceiptData.error === 'User No Package') {
      alert(
        language[this.props.currentLanguage].NoBetPackage,
      );
      this.setState({loading: false});
    } else if (OrderReceiptData.error === 'No Date to Bet') {
      alert(
        language[this.props.currentLanguage].NoDateBet,
      );
      this.setState({loading: false});
    } else if (OrderReceiptData.error === '5D6D in wrong game') {
      alert(
        language[this.props.currentLanguage].Wrong5D6D,
      );
      this.setState({loading: false});
    } else if (OrderReceiptData.error === 'Date error') {
      alert(
        language[this.props.currentLanguage].BuyDateWarn,
      );
      this.setState({loading: false});
    } else if (OrderReceiptData.error === 'Not enough date') {
      alert(
        language[this.props.currentLanguage].NoEnufDate,
      );
      this.setState({loading: false});
    } else if (OrderReceiptData.error === 'Game ShutDown') {
      alert(
        language[this.props.currentLanguage].GameShutDown,
      );
      this.setState({loading: false});
    } else if (OrderReceiptData.error === 'S Game ShutDown') {
      alert(
        language[this.props.currentLanguage].SGameShutDown,
      );
      this.setState({loading: false});
    } else if (OrderReceiptData.error === 'No Game on that day') {
      alert(
        language[this.props.currentLanguage].DateNoAvailable,
      );
      this.setState({loading: false});
    } else if (OrderReceiptData.error === 'Platform error') {
      alert(language[this.props.currentLanguage].PlatFormErr,);
    } else if (OrderReceiptData.error === 'Account Inactive') {
      alert(
        language[this.props.currentLanguage].AccountInactive,
      );
    } else if (OrderReceiptData.error === 'Exceed Personal Daily Limit') {
      alert(
        language[this.props.currentLanguage].ExceedPerLimit,
      );
    } else if (OrderReceiptData.error === 'User Red Lower Than Commission') {
      alert(
        'User Red Lower Than Commission',
      );
    } else if (OrderReceiptData.error === 'MKTSWBD ShutDown') {
      alert(
        language[this.props.currentLanguage].MKTSWBDoff,
      );
    } else if (OrderReceiptData.error === 'All Game ShutDown') {
      alert(
        language[this.props.currentLanguage].AllGameoff,
      );
    }  else if (OrderReceiptData.error === 'Over Number Limit') {
      alert(
        language[this.props.currentLanguage].OverNumberLimit,
      );
    } else if (OrderReceiptData.error === 'Format Error') {
      alert(language[this.props.currentLanguage].FormatError);
    } else if (OrderReceiptData.error === 'Repeat Order') {
      alert(language[this.props.currentLanguage].RepeatOrder,);
    } else if (OrderReceiptData.error === 'User Negative Commission') {
      alert(
        language[this.props.currentLanguage].UserNegativeCommission,
      );
    } else if (OrderReceiptData.error === 'Cannot bet game desired') {
      alert(
        language[this.props.currentLanguage].GameDesired,
      );
    } else if (OrderReceiptData.error === 'Not enough cash wallet') {
      alert(
        'Balance:' +
          OrderReceiptData.balance +
          '\n' +
        language[this.props.currentLanguage].noEnufCashWall,
      );
    } else if (OrderReceiptData.error) {
      alert(
        OrderReceiptData.error,
      );
    } else if (OrderReceiptData === 'TimeOut') {
      this.LogoutFunction();
    } else {
      this.RenderWord(OrderReceiptData.receipt);
      this.props.setReceiptData(OrderReceiptData, '');
      this.setState({
        loading: false,
        BTData: OrderReceiptData.receipt,
        TicketStatus: true,
      });
    }
  }
  RenderWord(UpdatedWord) {
    let Word = UpdatedWord || this.props.ReceiptData.receipt;
    let returnWord = [];
    if (Word.includes('<red>')) {
      const WordArray = Word.split('<red>').join('</red>').split('</red>');
      for (let i = 0; i < WordArray.length; i += 1) {
        if (i === 0) {
          returnWord.push(<span key={i}>{WordArray[i]}</span>);
        } else if (i % 2 === 1) {
          returnWord.push(
            <span key={i} style={{color: 'red'}}>
              {WordArray[i]}
            </span>,
          );
        } else if (i % 2 === 0) {
          returnWord.push(<span key={i}>{WordArray[i]}</span>);
        }
      }
      this.setState({ProcessData: returnWord});
    } else {
      this.setState({ProcessData: Word});
    }
  }
  async CopyReceiptFunction() {
    try {
      await navigator.clipboard.writeText(this.state.BTData.toString()
        .replace(/<red>/g, '')
        .replace(/<\/red>/g, ''));
      alert(
        language[this.props.currentLanguage].DoneCopy,
      ); } catch{
      alert(
        language[this.props.currentLanguage].FailedCopy,
      );
    }
  }

  async setSelectModeModalVisible() {
    const CheckVisible = this.state.SelectModeModalVisible;
    if (CheckVisible === true) {
      this.setState({SelectModeModalVisible: false});
    } else {
      this.setState({SelectModeModalVisible: true});
    }
  }

  async onpressNewOrder() {
    if (this.props.BuyMode === 'ReBuy') {
      this.setState({SelectModeModalVisible: true});
    } else {
      this.props.history.goBack()
    }
  }

  async SelectModeRebuy(Mode) {
    if (this.props.BuyMode === 'ReBuy') {
      //ignore
    } else {
      const OriText =
        this.props.ReceiptData.OriginalText;
      let ToprocessOriData = OriText.split('\n');
      let toProcessLineData = this.state.lineData;
      let AmountCopy = '';
      let CopyOrNot = false;
      const CheckNan = ToprocessOriData.filter(
        item => item.startsWith('#') && item.split('#').length === 2,
      );
      if (CheckNan.length > 0) {
        //JinNan Edit Rebuy
        for (let i = 0; i < ToprocessOriData.length; i++) {
          const CheckNull = toProcessLineData[i] || '';
          if (CheckNull !== '') {
            toProcessLineData[0].originalWord = 'D#';
          } else {
            let OriWord = ToprocessOriData[i].replace(/([.])/g, '*');
            let JingNanOri = ToprocessOriData[i];
            if (OriWord.startsWith('#') && OriWord.split('#').length === 2) {
              CopyOrNot = true;
            }
            if (OriWord.startsWith('##')) {
              OriWord = OriWord.replace('##', '');
            } else if (OriWord.startsWith('#')) {
              OriWord = OriWord.replace('#', '');
            }
            const OriWordArray = OriWord.split('#');
            if (
              CopyOrNot === true &&
              OriWordArray.length >= 2 &&
              OriWordArray[0] !== ''
            ) {
              AmountCopy = ToprocessOriData[i].substring(
                ToprocessOriData[i].indexOf('#'),
              );
              OriWord = ToprocessOriData[i].replace(/([.])/g, '*');
            } else if (
              JingNanOri.startsWith('#') &&
              JingNanOri.split('#').length === 2
            ) {
              OriWord = ToprocessOriData[i].replace(/([.])/g, '*');
            } else if (
              OriWordArray[0].length >= 3 &&
              OriWordArray[0].length <= 6
            ) {
              OriWord = ToprocessOriData[i].replace(/([.])/g, '*') + AmountCopy;
            } else {
              if (
                Mode === language[this.props.currentLanguage].SimpleBT &&
                ToprocessOriData[i].startsWith('#') &&
                ToprocessOriData[i].split('#').length === 2
              ) {
                OriWord = ToprocessOriData[i].replace('#', '');
              } else if (
                Mode === language[this.props.currentLanguage].SimpleBT &&
                !isNaN(ToprocessOriData[i]) === true &&
                ToprocessOriData[i].length > 9
              ) {
                OriWord = '';
              } else {
                OriWord = ToprocessOriData[i].replace(/([.])/g, '*');
              }
            }
            toProcessLineData.push({
              originalWord: OriWord,
              translatedWord: '',
              Mode: '',
            });
          }
        }
      } else {
        for (let i = 0; i < ToprocessOriData.length; i++) {
          const CheckNull = toProcessLineData[i] || '';
          if (CheckNull !== '') {
            toProcessLineData[0].originalWord = 'D#';
          } else {
            let OriWord = ToprocessOriData[i].replace(/([.])/g, '*');
            if (OriWord.startsWith('##')) {
              OriWord = OriWord.replace('##', '');
            } else if (OriWord.startsWith('#')) {
              OriWord = OriWord.replace('#', '');
            }
            const OriWordArray = OriWord.split('#');
            const CheckOnlyTag = OriWord.substring(OriWord.indexOf('#'));
            if (OriWordArray.length >= 2 && CheckOnlyTag !== '#') {
              AmountCopy = ToprocessOriData[i].substring(
                ToprocessOriData[i].indexOf('#') + 1,
              );
              OriWord = ToprocessOriData[i].replace(/([.])/g, '*');
            } else if (OriWordArray.length === 2 && CheckOnlyTag === '#') {
              OriWord = ToprocessOriData[i].replace(/([.])/g, '*') + AmountCopy;
            } else if (
              Mode === language[this.props.currentLanguage].SimpleBT &&
              !isNaN(ToprocessOriData[i]) === true &&
              ToprocessOriData[i].length > 9
            ) {
              OriWord = '';
            } else {
              OriWord = ToprocessOriData[i].replace(/([.])/g, '*');
            }
            toProcessLineData.push({
              originalWord: OriWord,
              translatedWord: '',
              Mode: '',
            });
          }
        }
      }
      this.props.setReceiptData('','');
      this.props.SetRebuyData(
        toProcessLineData,
        this.props.UserIDForSearch,
        this.props.UserForSearch,
      );
    }
    if (Mode === language[this.props.currentLanguage].Ticket) {
      this.props.history.push('/Betting');
    } else if (Mode === language[this.props.currentLanguage].RedTicket) {
      this.props.history.push('/bettingRedbill');
    } else if (Mode === language[this.props.currentLanguage].SimpleBT) {
      this.props.history.push('/SimpleBT');
    } else if (
      Mode ===
      language[this.props.currentLanguage].Ticket + '(SGD)'
    ) {
      this.props.history.push('/bettingSGD');
    } else if (
      Mode ===
      language[this.props.currentLanguage].Ticket + '(THB)'
    ) {
      this.props.history.push('/bettingTHB');
    }
  }
  

  render() {
    if (this.state.loading) {
      return (
        <div className="LoginPageContainer" style={{ justifyContent: 'center'}}>
          <img class = "loadingSize" src={LoadingLogo} alt="BigCo Inc. logo"/>
        </div>
      );
    } else {
      return (
        <body>
          {/* <div class="BT-container">  */}
          <div className='PageHeaderMainContainer'>
            <div className='PageHeaderLeftContainer'>
              <div className='PageHeaderLeftBackButton'
                onClick={() => this.props.history.goBack()}>
                {language[this.props.currentLanguage].Back}
              </div>
            </div>
            <div className='PageHeaderMiddleContainer'>
              {language[this.props.currentLanguage].ReceiptRespond}
            </div>
            <div className='PageHeaderRightContainer'>
              <button className='mainRightFunctionButton' style={{width: '80%'}} onClick={() =>this.onpressNewOrder()}>
                {language[this.props.currentLanguage].newOrder}
              </button>
            </div>
          </div>
          <div class="Receipt-body">
            <div className='ReceiptSubmitText' style={{ width: '95%', whiteSpace: 'pre-wrap'}}>
              {this.state.ProcessData}
            </div>
          </div> 
          <div className='Receipt-foot'>
            <button className='ReceiptSubmit'
              style={{backgroundColor:  this.state.TicketStatus ? 'red': 'white',
                color: this.state.TicketStatus ? 'white': 'black'}}
              onClick={() =>this.onpressCancel(!this.state.FunctionModalVisible)}
            >{this.state.TicketStatus
                ? language[this.props.currentLanguage].Delete
                : language[this.props.currentLanguage].ReBuy}</button>
            <button className='ReceiptSubmit' onClick={() =>this.CopyReceiptFunction()}>
              {language[this.props.currentLanguage].Copy}
            </button>
          </div>
          <Modal transparent={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.SelectModeModalVisible}
            contentClassName="br23">
            <Modal.Body style={{ padding: 10}}>
              <div className='DateModalHeader'>
                <div className='DateModalHeaderText'>
                  {language[this.props.currentLanguage].SelectModeHint}
                </div>
                <div className='DateModalHeaderText'>
                  <button className='DateModalCloseButton' onClick={() => this.setSelectModeModalVisible(!this.state.SelectModeModalVisible)}> 
                    {language[this.props.currentLanguage].Close}
                  </button>
                </div>
              </div>
              {this.state.reBuyOption.map((item, idx) => {
                return (
                  <button className='dateListButton' 
                    key={idx}
                    value={item}
                    onClick={() => this.SelectModeRebuy(item)}>
                    {item}
                  </button>
                )
              })
              }
            </Modal.Body>
          </Modal>
          {/* </div>  */}
        </body> 
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    id: state.id,
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
    ReceiptData: state.ReceiptData,
    printerIP: state.printerIP,
    BuyMode: state.BuyMode,
    AbleRed: state.AbleRed,
    AbleSGD: state.AbleSGD,
    AbleTHB: state.AbleTHB,
    UserAndDownlineData: state.UserAndDownlineData,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setReceiptData: (ReceiptData, BuyMode) => {
      dispatch({
        type: 'RECEIPTDATA_FOR_PASS',
        payload: {
          ReceiptData: ReceiptData,
          BuyMode: BuyMode,
        },
      });
    },
    setLoginStatus: (loginStatus, ID, Username, UserToken, role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          login: loginStatus,
          id: ID,
          username: Username,
          token: UserToken,
          role: role,
        },
      });
    },
    SetRebuyData: (SelectedData, SelectedUserID, SelectedUser) => {
      dispatch({
        type: 'REBUYDATA_FOR_PASS',
        payload: {
          ReBuyData: SelectedData,
          ReBuyUserID: SelectedUserID,
          ReBuyUser: SelectedUser,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(receiptAfterSubmit);
  