import '../App.css';
// import JSEncrypt from 'jsencrypt';
import React from 'react';
import { connect } from 'react-redux';
import {language} from '../language';
import LoadingLogo from '../img/loading.gif';
import moment from 'moment-timezone';
const { getInformation} = require('../Api');

class Information extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      information: [],
    }
  }

  async LogoutFunction() {
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('AbleRed');
    sessionStorage.removeItem('FirstTimeLogin');
    sessionStorage.removeItem('Username');
    sessionStorage.removeItem('loginStatus');
    sessionStorage.removeItem('getUserDownlineData');
    sessionStorage.removeItem('ReceiptData');
    sessionStorage.removeItem('UserIDForSearch');
    sessionStorage.removeItem('UserForSearch');
    sessionStorage.removeItem('SearchDateFrom');
    sessionStorage.removeItem('SearchDateTo');
    sessionStorage.removeItem('PatternForSearch');
    sessionStorage.removeItem('PlatformForSearch');
    sessionStorage.removeItem('ReportData');
    this.props.setLoginStatus(false, '', '', '', '');
  }

  async componentDidMount() {
    if (this.props.UserAndDownlineData === '') {
      this.props.history.push('/')
    }
    const getInfo = await getInformation(
      this.props.username,
      'normal',
      this.props.token,
    );
    if (getInfo === 'TimeOut') {
      this.LogoutFunction();
    }
    this.setState({information: getInfo, loading: false});
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="LoginPageContainer" style={{ justifyContent: 'center'}}>
          <img class = "loadingSize" src={LoadingLogo} alt="BigCo Inc. logo"/>
        </div>
      );
    } else {
      return (
        <body>
          <div className='PageHeaderMainContainer'>
            <div className='PageHeaderLeftContainer'>
              <div className='PageHeaderLeftBackButton'
                onClick={() => this.props.history.goBack()}>
                {language[this.props.currentLanguage].Back}
              </div>
            </div>
            <div className='PageHeaderMiddleContainer'>
              {language[this.props.currentLanguage].Information}
            </div>
            <div className='PageHeaderRightContainer'>
            </div>
          </div>
          <div className='FullScreenheight' style={{ marginTop: 5 }}>
            {this.state.information && this.state.information.map((items, index) => {
              return(
                <div key={index} style={{ display: 'flex', flexDirection: 'column', width: '100%', borderBottom: "1px solid black" }}>
                  <div style={{ width: '100%', textAlign: 'left', fontWeight: 'bold', color: 'gray' }}>
                🕑 {moment(items.DateTime).format('DD-MM-YYYY hh:mm')}
                  </div>
                  {(items.Image !== (null || '') && (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <img 
                        src={items.Image}
                        alt="new"
                        style={{  maxHeight: '40%', maxWidth: '40%', objectFit: 'contain'}}
                      />
                    </div>
                  ))}
                  <div style={{ width: '100%', textAlign: 'left', whiteSpace: 'pre-wrap', padding: 10, fontSize: '18px', fontWeight: 'bold' }}>
                    {items.Information}
                  </div>
                </div>
              );
            })}
          </div>
        </body> 
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    id: state.id,
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
    UserAndDownlineData: state.UserAndDownlineData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (loginStatus, ID, Username, UserToken, role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          login: loginStatus,
          id: ID,
          username: Username,
          token: UserToken,
          role: role,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Information);
  