import React, {Component} from 'react';
import Main from './MainComponent';
import Authorization from './Authorization';
import {connect} from 'react-redux';

class AuthorizationToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.login === true || this.props.login === 'true') {
      return <Main />;
    } 
    return <Authorization />;
    
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    token: state.token,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setLanguage: lg => {
      dispatch({type: 'SET_LANGUAGE', payload: lg});
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthorizationToggle);
