/* eslint-disable no-useless-concat */
/* eslint-disable no-mixed-operators */
import '../App.css';
// import JSEncrypt from 'jsencrypt';
import React from 'react';
import { connect } from 'react-redux';
import {language} from '../language';
import LoadingLogo from '../img/loading.gif';
import moment from 'moment-timezone';
import {findPermutations} from '../utility/getPermutations';
import {processCheckboxPlatformPosition} from '../utility/processCheckboxPlatformPosition';
import {processPlatformPosition} from '../utility/processPlatformPosition';
import Modal from 'react-bootstrap/Modal';
import CheckmarkIcon from '../img/Checkmark.png';
import CalendarIcon from '../img/CalendarIcon.png';
import BackSpaceIcon from '../img/BackSpaceIcon.png';
import spaceBarIcon from '../img/spaceBarIcon.png';
import deleteIcon from '../img/deleteIcon.png';
import enterIcon from '../img/enter.png';
import {processAC} from '../utility/processAC';
import Blank from '../img/Blank.png';
const { CreateBetOrder, getUserAndDownline, getCashWalletAmt} = require('../Api');

class SimpleBT extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      Formula: '',
      FourDFormula: [],
      DisplayFormula: [],
      BTWord: '',
      BTWordRange: '',
      UserModalVisible: false,
      DateModalVisible: false,
      SpecialModalVisible: false,
      Key2CheckMark: false,
      SelectedDate: '',
      SelectedSpecial: '-',
      CurrentPlatForm: '',
      todayDate: moment().format('YYYY-MM-DD'),
      ModalDate: [{Date: ''}],
      DevideOrMultiply: '',
      AddNumberModal: false,
      JingNan: '',
      InputAmount: [
        {Amount: ''},
        {Amount: ''},
        {Amount: ''},
        {Amount: ''},
        {Amount: ''},
        {Amount: ''},
        {Amount: ''},
        {Amount: ''},
      ],
      PlatFormList: [],
      Special: [
        '-',
        'IBox',
        'Box',
        '1{234}',
        '{123}4',
        '{0-9}123',
        '123{0-9}',
        '{1234}',
      ],
      TotalLine: '',
      OverallAmount: 0,
      DisplayPlatform: '',
      lineData: [
        {
          originalWord: 'D#',
          translatedWord: 'D#',
          Mode: '',
        },
      ],
      UserID: '',
      Username: '',
      PreviousAmount: '',
      FixAmount: false,
      pfPosition: '12345678',
      keyboardVisible: true,
      SelectingInput: 'Number',
      SelectedNum: 0,
      nameInput: '',
      userBalance: 0,
    }
  }

  async LogoutFunction() {
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('AbleRed');
    sessionStorage.removeItem('FirstTimeLogin');
    sessionStorage.removeItem('Username');
    sessionStorage.removeItem('loginStatus');
    sessionStorage.removeItem('getUserDownlineData');
    sessionStorage.removeItem('ReceiptData');
    sessionStorage.removeItem('UserIDForSearch');
    sessionStorage.removeItem('UserForSearch');
    sessionStorage.removeItem('SearchDateFrom');
    sessionStorage.removeItem('SearchDateTo');
    sessionStorage.removeItem('PatternForSearch');
    sessionStorage.removeItem('PlatformForSearch');
    sessionStorage.removeItem('ReportData');
    this.props.setLoginStatus(false, '', '', '', '');
  }

  async setDateModalVisible() {
    const CheckVisible = this.state.DateModalVisible;
    if (CheckVisible === true) {
      this.setState({DateModalVisible: false});
    } else {
      this.setState({DateModalVisible: true});
    }
  }
  async setAddNumModalVisible() {
    const CheckVisible = this.state.AddNumberModal;
    if (CheckVisible === true) {
      this.setState({AddNumberModal: false});
    } else {
      this.setState({AddNumberModal: true});
    }
  }
  async setUserModalVisible() {
    const CheckVisible = this.state.UserModalVisible;
    if (CheckVisible === true) {
      this.setState({UserModalVisible: false});
    } else {
      this.setState({UserModalVisible: true});
    }
  }
  async setSpecialModalVisible() {
    const CheckVisible = this.state.SpecialModalVisible;
    if (CheckVisible === true) {
      this.setState({SpecialModalVisible: false});
    } else {
      this.setState({SpecialModalVisible: true});
    }
  }
  async setFixAmount() {
    const CheckVisible = this.state.FixAmount;
    if (CheckVisible === true) {
      this.setState({FixAmount: false});
    } else {
      this.setState({FixAmount: true});
    }
  }
  async SelectUserModal(SelectedID, SelectedUsername, idx) {
    let getUserKeyInResult = [];
    // const PlatFormListData = this.state.PlatFormList;
    let PlatformPosition = processCheckboxPlatformPosition(
      this.props.UserAndDownlineData[idx].GameBetting,
      this.props.UserAndDownlineData[idx].GameDisplay,
    );
    getUserKeyInResult.push({
      KeyIn1: this.props.UserAndDownlineData[idx].KeyIn1,
      KeyIn2: this.props.UserAndDownlineData[idx].KeyIn2,
    });
    this.setState(
      {
        UserID: SelectedID,
        Username: SelectedUsername,
        UserModalVisible: false,
        Formula: getUserKeyInResult[0],
        DisplayFormula: getUserKeyInResult[0].KeyIn1,
        FourDFormula: getUserKeyInResult[0].KeyIn1,
        PlatFormList: PlatformPosition,
        DevideOrMultiply: this.props.UserAndDownlineData[idx].MultiplyDivide,
        JingNan: this.props.UserAndDownlineData[idx].JinNan,
        pfPosition: this.props.UserAndDownlineData[idx].GameBetting,
        XingJinFormula: this.props.UserAndDownlineData[idx].XingJinFormula,
      },
      () => {
        this.ProcessWords();
        this.CheckUserBalance(SelectedUsername);
      },
    );
  }
  async setKey2CheckMark() {
    const CheckMark = this.state.Key2CheckMark;
    let FormulaKey = '';
    if (
      (this.state.BTWord.length === 4 || this.state.BTWord.length <= 1) &&
      CheckMark === true
    ) {
      FormulaKey = this.state.Formula.KeyIn1;
    } else if (this.state.BTWord.length === 3 && CheckMark === false) {
      FormulaKey = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
    } else if (this.state.BTWord.length === 2 && CheckMark === false) {
      FormulaKey = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
    } else if (this.state.BTWord.length === 3 && CheckMark === true) {
      FormulaKey = processAC(this.state.Formula.KeyIn1);
    } else if (this.state.BTWord.length === 2 && CheckMark === true) {
      FormulaKey = ['PA', 'PF'];
    } else if (
      (this.state.BTWord.length === 4 || this.state.BTWord.length <= 1) &&
      CheckMark === false
    ) {
      FormulaKey = this.state.Formula.KeyIn2;
    } else {
      FormulaKey = this.state.DisplayFormula;
    }
    if (CheckMark === true) {
      this.setState({
        Key2CheckMark: false,
        DisplayFormula: FormulaKey,
        FourDFormula: this.state.Formula.KeyIn1,
      });
    } else {
      this.setState({
        Key2CheckMark: true,
        DisplayFormula: FormulaKey,
        FourDFormula: this.state.Formula.KeyIn2,
      });
    }
  }

  GetModalDate() {
    const DateLoop = [];
    const TodayDate = moment().tz('Asia/Kuala_Lumpur').format('LLL');
    const todayTime = moment().tz('Asia/Kuala_Lumpur').format('HHmm');
    if (todayTime >= '1930') {
      for (let i = 1; i <= 8; i++) {
        let Date = moment(TodayDate).add(i, 'days');
        DateLoop.push({
          Date: moment(Date).format('DDMM(ddd)'),
          DateProcess: moment(Date).format('DDMM'),
          CheckMark: false,
        });
      }
    } else {
      for (let i = 0; i <= 7; i++) {
        let Date = moment(TodayDate).add(i, 'days');
        DateLoop.push({
          Date: moment(Date).format('DDMM(ddd)'),
          DateProcess: moment(Date).format('DDMM'),
          CheckMark: false,
        });
      }
    }
    return {DateLoop};
  }
  async componentDidMount() {
    if (this.props.UserAndDownlineData === '') {
      this.props.history.push('/')
    }
    const getUserDownlineData = await getUserAndDownline(
      this.props.id,
      this.props.username,
      this.props.token,
    );
    this.CheckUserBalance(this.props.username);
    sessionStorage.setItem('getUserDownlineData', JSON.stringify(getUserDownlineData));
    this.props.setUserDownlineData(getUserDownlineData);

    const DateLoop = this.GetModalDate();
    // const PlatFormListData = this.state.PlatFormList;
    let PlatformPosition = processCheckboxPlatformPosition(
      this.props.UserAndDownlineData[0].GameBetting,
      this.props.UserAndDownlineData[0].GameDisplay,
    );
    let getUserKeyInResult = [];
    await getUserKeyInResult.push({
      KeyIn1: this.props.UserAndDownlineData[0].KeyIn1,
      KeyIn2: this.props.UserAndDownlineData[0].KeyIn2,
    });
    if (this.props.ReBuyData !== '') {
      this.setState({
        Formula: getUserKeyInResult[0],
        FourDFormula: getUserKeyInResult[0].KeyIn1,
        DisplayFormula: getUserKeyInResult[0].KeyIn1,
        ModalDate: DateLoop,
        loading: false,
        UserID: this.props.id,
        Username: this.props.username,
        PlatFormList: PlatformPosition,
        DisplayPlatform: this.props.UserAndDownlineData[0].GameDisplay,
        DevideOrMultiply: this.props.UserAndDownlineData[0].MultiplyDivide,
        JingNan: this.props.UserAndDownlineData[0].JinNan,
        pfPosition: this.props.UserAndDownlineData[0].GameBetting,
        XingJinFormula: this.props.UserAndDownlineData[0].XingJinFormula,
      });
      this.ProcessWords(this.props.ReBuyData);
      this.props.SetRebuyData('', '', '');
    } else {
      this.setState({
        Formula: getUserKeyInResult[0],
        FourDFormula: getUserKeyInResult[0].KeyIn1,
        DisplayFormula: getUserKeyInResult[0].KeyIn1,
        ModalDate: DateLoop,
        loading: false,
        UserID: this.props.id,
        Username: this.props.username,
        PlatFormList: PlatformPosition,
        DisplayPlatform: this.props.UserAndDownlineData[0].GameDisplay,
        DevideOrMultiply: this.props.UserAndDownlineData[0].MultiplyDivide,
        JingNan: this.props.UserAndDownlineData[0].JinNan,
        pfPosition: this.props.UserAndDownlineData[0].GameBetting,
        XingJinFormula: this.props.UserAndDownlineData[0].XingJinFormula,
      });
      this.ProcessWords();
    }
  }

  async SelectDateFunction(idx) {
    const toProcessDateData = this.state.ModalDate;
    if (toProcessDateData.DateLoop[idx].CheckMark === false) {
      toProcessDateData.DateLoop[idx].CheckMark = true;
    } else {
      toProcessDateData.DateLoop[idx].CheckMark = false;
    }
    this.setState({ModalDate: toProcessDateData});
  }

  async SelectSpecialFunction(item) {
    if (item === '{1234}') {
      this.setState({
        SelectedSpecial: item,
        SpecialModalVisible: false,
        BTWord: '',
        BTWordRange: '',
      });
    } else {
      this.setState({
        SelectedSpecial: item,
        SpecialModalVisible: false,
        SelectingInput: 'Number',
        SelectedNum: 0,
      });
    }
  }

  async PlatformCheckboxSelect(idx) {
    const toProcessDateData = this.state.PlatFormList;
    if (toProcessDateData[idx].CheckMark === false) {
      toProcessDateData[idx].CheckMark = true;
    } else {
      toProcessDateData[idx].CheckMark = false;
    }
    this.setState({PlatFormList: toProcessDateData});
  }

  onPressConfirmSelectDate() {
    const toProcessDateData = this.state.ModalDate;
    const lineData = this.state.lineData;
    let DateData = '';
    const CheckDate = toProcessDateData.DateLoop.filter(
      item => item.CheckMark === true,
    );
    if (CheckDate.length > 0) {
      for (let i = 0; i < CheckDate.length; i++) {
        if (i === 0) {
          DateData = 'D#' + '**' + CheckDate[i].DateProcess;
        } else {
          DateData += '*' + CheckDate[i].DateProcess;
        }
      }
      lineData[0].originalWord = DateData;
      this.NextLineButtonFunction('Nextline');
    } else {
      lineData[0].originalWord = 'D#';
      this.NextLineButtonFunction('Nextline');
    }
    this.ProcessWords(lineData);
    this.setState({DateModalVisible: false});
  }

  onpressLongTerm() {
    const lineData = this.state.lineData;
    lineData[0].originalWord = 'D#*';
    this.ProcessWords(lineData);
    this.setState({
      SelectedDate: 'AUTO',
      DateModalVisible: false,
    });
  }
  onpressPeriod(Num) {
    const lineData = this.state.lineData;
    lineData[0].originalWord = 'D#' + Num;
    this.ProcessWords(lineData);
    this.setState({
      SelectedDate: Num,
      DateModalVisible: false,
    });
  }

  BTNumberInput(text) {
    let keyInFormula = [];
    text = text.replace(/\s/g, '');
    if (isNaN(text) === true || text.includes('.')) {
      alert(
        language[this.props.currentLanguage].OnlyNumber,
      );
    } else if (this.state.SelectedSpecial === '{1234}') {
      if (/^[0-9]+$/.test(text) || text === '') {
        this.setState({BTWord: text}, () => {
          if (text.length === 2) {
            keyInFormula = ['PA1', 'PA2', 'PA3', 'PD', 'PE', 'PC'];
            this.setState({
              BTWord: text,
              DisplayFormula: keyInFormula,
            });
          } else if (text.length === 3) {
            keyInFormula = ['CA1', 'CA2', 'CA3', 'CD', 'CE', 'CC'];
            this.setState({
              BTWord: text,
              DisplayFormula: keyInFormula,
            });
          } else {
            this.setState({DisplayFormula: this.state.FourDFormula});
          }
        });
      }
    } else {
      if (text.length === 2) {
        keyInFormula = ['PA1', 'PA2', 'PA3', 'PD', 'PE', 'PC'];
        this.setState({
          BTWord: text,
          DisplayFormula: keyInFormula,
        });
      } else if (text.length === 3) {
        keyInFormula = ['CA1', 'CA2', 'CA3', 'CD', 'CE', 'CC'];
        this.setState({
          BTWord: text,
          DisplayFormula: keyInFormula,
        });
      } else if (text.length === 5) {
        this.setState({
          BTWord: text,
          DisplayFormula: ['5D'],
        });
      } else if (text.length === 4) {
        this.setState({
          BTWord: text,
          DisplayFormula: this.state.FourDFormula,
        });
      } else if (text.length === 6) {
        this.setState({
          BTWord: text,
          DisplayFormula: ['6D'],
        });
      } else if (text.length > 6) {
        alert(
          language[this.props.currentLanguage].MaxSixNum,
        );
      } else if (/^[0-9]+$/.test(text) || text === '') {
        this.setState({BTWord: text});
      } else {
        alert(
          language[this.props.currentLanguage].OnlyNumber,
        );
      }
    }
  }

  BTNumberInputRange(text) {
    if (isNaN(text) === true || text.includes('.')) {
      alert(
        language[this.props.currentLanguage].OnlyNumber,
      );
    } else {
      if (text.length === 4) {
        this.setState({
          BTWordRange: text,
        });
      } else if (text.length > 4) {
        alert(
          language[this.props.currentLanguage].MaxSixNum,
        );
      } else if (/^[0-9]+$/.test(text) || text === '') {
        this.setState({BTWordRange: text});
      } else {
        alert(
          language[this.props.currentLanguage].OnlyNumber,
        );
      }
    }
  }

  BTAmountInput(text, idx) {
    const amountData = this.state.InputAmount;
    amountData[idx].Amount = text.replace(/\s/g, '');
    this.setState({InputAmount: amountData});
    if (isNaN(amountData[idx].Amount)) {
      alert(
        language[this.props.currentLanguage].OnlyNumber,
      );
      amountData[idx].Amount = text.replace(/[^\d]/g, '');
      this.setState({InputAmount: amountData});
    } else if (/^[0-9.]+$/.test(text) || text === '') {
      this.setState({InputAmount: amountData});
    }
  }

  AddNumberFunction() {
    const toProcessAmountData = this.state.InputAmount;
    const toProcessPlatForm = this.state.PlatFormList;
    let ProcesslineData = this.state.lineData;
    let AmountData = '';
    let Platform = '';
    let SelectedSpecial = this.state.SelectedSpecial;
    let Key2Sign = '';
    let ProcessOriWord = '';
    // Get Platform
    if (
      this.state.Key2CheckMark === true &&
      (this.state.BTWord.length === 2 ||
        this.state.BTWord.length === 3 ||
        this.state.BTWord.length === 4)
    ) {
      Key2Sign = '**';
    }
    for (let x = 0; x < toProcessPlatForm.length; x++) {
      if (toProcessPlatForm[x].CheckMark === true) {
        Platform += x + 1;
      }
    }
    // Get All Amount
    for (let i = 0; i < this.state.DisplayFormula.length; i++) {
      if (toProcessAmountData[i].Amount === '') {
        AmountData += '#';
      } else {
        AmountData += toProcessAmountData[i].Amount + '#';
      }
    }
    if (
      SelectedSpecial === '{1234}' &&
      this.state.BTWord.length === 4 &&
      this.state.BTWordRange.length === 4
    ) {
      ProcessOriWord = this.state.BTWord + '-' + this.state.BTWordRange;
    } else if (
      SelectedSpecial === '{1234}' &&
      this.state.BTWord.length === 3 &&
      this.state.BTWordRange.length === 3
    ) {
      ProcessOriWord = this.state.BTWord + '-' + this.state.BTWordRange;
    } else if (SelectedSpecial === '-' && this.state.BTWord.length === 4) {
      ProcessOriWord = this.state.BTWord;
    } else if (SelectedSpecial === 'Box' && this.state.BTWord.length === 2) {
      if (this.props.OneStarBao === 0) {
        ProcessOriWord = '**' + this.state.BTWord;
      } else {
        ProcessOriWord = '*' + this.state.BTWord;
      }
    } else if (SelectedSpecial === 'Box' && this.state.BTWord.length === 3) {
      if (this.props.OneStarBao === 0) {
        ProcessOriWord = '**' + this.state.BTWord;
      } else {
        ProcessOriWord = '*' + this.state.BTWord;
      }
    } else if (SelectedSpecial === 'Box' && this.state.BTWord.length === 4) {
      if (this.props.OneStarBao === 0) {
        ProcessOriWord = '**' + this.state.BTWord;
      } else {
        ProcessOriWord = '*' + this.state.BTWord;
      }
    } else if (SelectedSpecial === 'Box' && this.state.BTWord.length === 5) {
      if (this.props.OneStarBao === 0) {
        ProcessOriWord = '**' + this.state.BTWord;
      } else {
        ProcessOriWord = '*' + this.state.BTWord;
      }
    } else if (SelectedSpecial === 'Box' && this.state.BTWord.length === 6) {
      if (this.props.OneStarBao === 0) {
        ProcessOriWord = '**' + this.state.BTWord;
      } else {
        ProcessOriWord = '*' + this.state.BTWord;
      }
    } else if (SelectedSpecial === 'IBox' && this.state.BTWord.length === 2) {
      if (this.props.OneStarBao === 0) {
        ProcessOriWord = '*' + this.state.BTWord;
      } else {
        ProcessOriWord = '**' + this.state.BTWord;
      }
    } else if (SelectedSpecial === 'IBox' && this.state.BTWord.length === 3) {
      if (this.props.OneStarBao === 0) {
        ProcessOriWord = '*' + this.state.BTWord;
      } else {
        ProcessOriWord = '**' + this.state.BTWord;
      }
    } else if (SelectedSpecial === 'IBox' && this.state.BTWord.length === 4) {
      if (this.props.OneStarBao === 0) {
        ProcessOriWord = '*' + this.state.BTWord;
      } else {
        ProcessOriWord = '**' + this.state.BTWord;
      }
    } else if (
      SelectedSpecial === '{0-9}123' &&
      this.state.BTWord.length === 2
    ) {
      ProcessOriWord = '#' + this.state.BTWord;
    } else if (
      SelectedSpecial === '{0-9}123' &&
      this.state.BTWord.length === 3
    ) {
      ProcessOriWord = '#' + this.state.BTWord;
    } else if (
      SelectedSpecial === '{0-9}123' &&
      this.state.BTWord.length === 4
    ) {
      ProcessOriWord = '#' + this.state.BTWord;
    } else if (
      SelectedSpecial === '123{0-9}' &&
      this.state.BTWord.length === 2
    ) {
      ProcessOriWord = '##' + this.state.BTWord;
    } else if (
      SelectedSpecial === '123{0-9}' &&
      this.state.BTWord.length === 3
    ) {
      ProcessOriWord = '##' + this.state.BTWord;
    } else if (
      SelectedSpecial === '123{0-9}' &&
      this.state.BTWord.length === 4
    ) {
      ProcessOriWord = '##' + this.state.BTWord;
    } else if (SelectedSpecial === '{123}4' && this.state.BTWord.length === 3) {
      ProcessOriWord =
        this.state.BTWord.substring(0, 2) + '*' + this.state.BTWord[2];
    } else if (SelectedSpecial === '{123}4' && this.state.BTWord.length === 4) {
      ProcessOriWord =
        this.state.BTWord.substring(0, 3) + '*' + this.state.BTWord[3];
    } else if (SelectedSpecial === '1{234}' && this.state.BTWord.length === 3) {
      ProcessOriWord =
        this.state.BTWord[0] + '*' + this.state.BTWord.substring(1, 3);
    } else if (SelectedSpecial === '1{234}' && this.state.BTWord.length === 4) {
      ProcessOriWord =
        this.state.BTWord[0] + '*' + this.state.BTWord.substring(1, 4);
    } else {
      ProcessOriWord = this.state.BTWord;
    }
    if (Platform === '') {
      alert(
        language[this.props.currentLanguage].NoSelectPlatformAlert,
      );
    } else if (this.state.BTWord === '' || this.state.BTWord.length <= 1) {
      alert(
        language[this.props.currentLanguage].NumInvalidOrEmpty,
      );
    } else if (
      SelectedSpecial === '{1234}' &&
      this.state.BTWordRange.length < 2
    ) {
      alert(
        language[this.props.currentLanguage].NumInvalidOrEmpty,
      );
    } else if (
      SelectedSpecial === '{1234}' &&
      this.state.BTWordRange <= this.state.BTWord
    ) {
      alert(
        language[this.props.currentLanguage].RangeBiggerNumber,
      );
    } else if (
      SelectedSpecial === '{1234}' &&
      this.state.BTWordRange.length !== this.state.BTWord.length
    ) {
      alert(
        language[this.props.currentLanguage].NumInvalidOrEmpty,
      );
    } else if (
      (AmountData.replace(/([#])/g, '') === '' ||
        AmountData.replace(/([#])/g, '') <= 0) &&
      this.state.PreviousAmount === ''
    ) {
      alert(
        language[this.props.currentLanguage].AmountEmpty,
      );
    } else if (
      this.state.PreviousAmount !== '' &&
      (this.state.BTWord.length === 5 || this.state.BTWord.length === 6) &&
      this.state.PreviousAmount.split('#')[0] === '' &&
      (AmountData.replace(/([#])/g, '') === '' ||
        AmountData.replace(/([#])/g, '') <= 0)
    ) {
      // if first amount is empty
      alert(
        language[this.props.currentLanguage].AmountEmpty,
      );
    } else if (
      this.state.PreviousAmount !== '' &&
      this.state.BTWord.length === 3 &&
      this.state.PreviousAmount.split('#')[0] === '' &&
      this.state.PreviousAmount.split('#')[1] === '' &&
      (AmountData.replace(/([#])/g, '') === '' ||
        AmountData.replace(/([#])/g, '') <= 0)
    ) {
      // if first amount is empty
      alert(
        language[this.props.currentLanguage].AmountEmpty,
      );
    } else {
      if (
        AmountData.replace(/([#])/g, '') === '' ||
        AmountData.replace(/([#])/g, '') <= 0
      ) {
        if (this.state.BTWord.length === 5 || this.state.BTWord.length === 6) {
          AmountData = this.state.PreviousAmount.split('#');
          AmountData = AmountData[0] + '#';
        } else if (
          this.state.BTWord.length === 3 ||
          this.state.BTWord.length === 2
        ) {
          AmountData = this.state.PreviousAmount.split('#');
          AmountData = AmountData[0] + '#' + AmountData[1] + '#';
        } else {
          AmountData = this.state.PreviousAmount;
        }
      }
      if (Platform === this.state.CurrentPlatForm) {
        ProcesslineData.push({
          originalWord:
            ProcessOriWord + Key2Sign + '#' + AmountData.slice(0, -1).replace(/([.])/g, '*'),
          translatedWord: '',
          CalculateLineAmount: '',
        });
      } else {
        if (this.state.DisplayPlatform === 'MPTSWBK') {
          ProcesslineData.push({
            originalWord: Platform,
            // originalWord: Platform.replace(/M/g, '1')
            //   .replace(/P/g, '2')
            //   .replace(/T/g, '3')
            //   .replace(/S/g, '4')
            //   .replace(/W/g, '5')
            //   .replace(/B/g, '6')
            //   .replace(/K/g, '7'),
          });
        } else {
          ProcesslineData.push({
            originalWord: Platform,
          });
        }

        ProcesslineData.push({
          originalWord:
            ProcessOriWord + Key2Sign + '#' + AmountData.slice(0, -1).replace(/([.])/g, '*'),
          translatedWord: '',
          CalculateLineAmount: '',
        });
      }
      this.ProcessWords(ProcesslineData);
      if (this.state.FixAmount === false) {
        this.setState({
          // AddNumberModal: false,
          lineData: ProcesslineData,
          CurrentPlatForm: Platform,
          BTWord: '',
          BTWordRange: '',
          SelectedSpecial: '-',
          InputAmount: [
            {Amount: ''},
            {Amount: ''},
            {Amount: ''},
            {Amount: ''},
            {Amount: ''},
            {Amount: ''},
            {Amount: ''},
            {Amount: ''},
          ],
          PreviousAmount: AmountData,
          SelectingInput: 'Number',
          SelectedNum: 0,
          DisplayFormula: this.state.FourDFormula,
        });
      } else {
        this.setState({
          // AddNumberModal: false,
          lineData: ProcesslineData,
          CurrentPlatForm: Platform,
          BTWord: '',
          BTWordRange: '',
          SelectedSpecial: '-',
          PreviousAmount: AmountData,
          SelectingInput: 'Number',
          SelectedNum: 0,
          DisplayFormula: this.state.FourDFormula,
        });
      }
    }
    this.flatListRef.scrollToOffset({animated: true, offset: 0});
  }
  async ProcessWords(lineData) {
    const toProcessLineData = lineData || this.state.lineData;
    let numberOfPlatform = 0;
    let numberofLine = 0;
    let OverallAmount = 0;
    let CurrentPlatformtoDisplay = '';
    for (let i = 0; i < toProcessLineData.length; i++) {
      const thisLineWord = toProcessLineData[i].originalWord;
      // process date data
      if (i === 0) {
        toProcessLineData[i].translatedWord = toProcessLineData[i].originalWord;

        const {newLineWord} = this.processDate(
          toProcessLineData[i].originalWord,
        );
        toProcessLineData[i].translatedWord = newLineWord;
      } else {
        if (
          thisLineWord.startsWith('#') &&
          thisLineWord.split('#').length === 2 &&
          this.state.JingNan === 1
        ) {
          toProcessLineData[i].Mode = '';
          numberOfPlatform = toProcessLineData[i].originalWord.length - 1;
          let ProcessPlatForm = processPlatformPosition(
            this.state.pfPosition,
            this.state.DisplayPlatform,
            toProcessLineData[i].originalWord,
          );
          ProcessPlatForm = ProcessPlatForm.join().replace(/([,])/g, '');
          toProcessLineData[i].translatedWord = ProcessPlatForm;
          CurrentPlatformtoDisplay = ProcessPlatForm;
          toProcessLineData[i].DisplayPF = '';
        } else if (thisLineWord.includes('#')) {
          const {newLineWord, BLineAmount, CalculateLineAmount, Mode} =
            this.processFormula(
              toProcessLineData[i].originalWord,
              numberOfPlatform,
            );
          numberofLine += 1;
          toProcessLineData[i].translatedWord = newLineWord;
          toProcessLineData[i].BLineAmount = BLineAmount;
          toProcessLineData[i].CalculateLineAmount = CalculateLineAmount;
          toProcessLineData[i].Mode = Mode;
          toProcessLineData[i].DisplayPF = CurrentPlatformtoDisplay;
          OverallAmount += CalculateLineAmount;
        } else if (thisLineWord !== '') {
          toProcessLineData[i].Mode = '';
          toProcessLineData[i].CalculateLineAmount = '';
          numberOfPlatform = toProcessLineData[i].originalWord.length;
          let ProcessPlatForm = processPlatformPosition(
            this.state.pfPosition,
            this.state.DisplayPlatform,
            toProcessLineData[i].originalWord,
          );
          ProcessPlatForm = ProcessPlatForm.join().replace(/([,])/g, '');
          toProcessLineData[i].translatedWord = ProcessPlatForm;
          CurrentPlatformtoDisplay = ProcessPlatForm;
          toProcessLineData[i].DisplayPF = '';
        }
      }
      const {newLineWordColor} = this.renderWord(
        toProcessLineData[i].translatedWord,
      );
      const CheckPF = toProcessLineData[i].DisplayPF || '';
      const {newLinePFColor} = this.renderPF(CheckPF);
      toProcessLineData[i].translatedWord = newLineWordColor;
      toProcessLineData[i].DisplayPF = newLinePFColor;
    }
    this.setState({
      lineData: toProcessLineData,
      OverallAmount,
      TotalLine: numberofLine,
    });
  }

  processDate(originalWord) {
    let toProcessArray = '';
    let newLineWord = '';
    let numberOfday = 1;
    let ProcessDate = '';
    const TodayDate = moment().format('YYMMDD');
    const dateMode = originalWord.substring(2, originalWord.length);
    // toProcessArray = dateMode.split('*');
    if (dateMode === '*') {
      newLineWord = 'D#AUTO';
    } else if (dateMode.startsWith('**')) {
      toProcessArray = dateMode.replace('**', '');
      toProcessArray = toProcessArray.split('*');
      for (let j = 0; j < toProcessArray.length; j += 1) {
        numberOfday = toProcessArray.length;
        if (TodayDate > moment(toProcessArray[j], 'DDMMYY').format('YYMMDD')) {
          ProcessDate += moment(toProcessArray[j], 'DDMMYY').add(1, 'Y').format('DDMM(ddd)');
        } else {
          ProcessDate += moment(toProcessArray[j], 'DDMMYY').format('DDMM(ddd)');
        }
      }
      newLineWord = `D#${numberOfday}Days <Dgreen>${ProcessDate}</Dgreen>`;
      // specific date
    } else if (dateMode.startsWith('*')) {
      let buyDate = [];
      toProcessArray = dateMode.replace('*', '');
      toProcessArray = toProcessArray.split('');
      numberOfday = toProcessArray.length;
      toProcessArray.forEach(item => {
        let toCompareTodayDate = moment().format('YYYYMMDD');
        let toCompareDate = moment().day(item).format('YYYYMMDD');
        let convertDate = moment().day(item).format('DDMM(ddd)');
        if (toCompareDate < toCompareTodayDate) {
          convertDate = moment()
            .day(Number(item) + 7)
            .format('DDMM(ddd)');
        }
        buyDate.push(convertDate);
      });
      // newLineWord = 'D#' + numberOfday + 'Days ' + buyDate;
      newLineWord = `D#${numberOfday}Days <Dgreen>${buyDate}</Dgreen>`;
      // specific day of week
    } else {
      newLineWord = originalWord;
      // next few order
    }
    return {newLineWord};
  }

  processFormula(originalWord, numberOfPlatform) {
    let toProcessArray = '';
    toProcessArray = originalWord.split('#');
    let onlyWord = originalWord.split('#')[0];
    let RemoveDot = onlyWord.replace(/([*])/g, '');
    let newLineWord = '';
    let BLineAmount = 0;
    let CalculateLineAmount = 0;
    let keyInFormula = '';
    let Mode = '';
    let WordQuantity = '';
    let getPermutations = '';
    let subStringWord = '';
    if (
      RemoveDot.length === 4 &&
      onlyWord.length === 4 &&
      toProcessArray[1] &&
      toProcessArray[1].includes('**')
    ) {
      toProcessArray[0] = RemoveDot;
      toProcessArray[1] = toProcessArray[1].replace('**', '');
      if (toProcessArray[1]) {
        const XingJinObject = Object.values(
          this.state.XingJinFormula || [],
        ).find(item => item.Num === toProcessArray[1]);
        if (XingJinObject) {
          toProcessArray = [
            onlyWord,
            XingJinObject.B.replace(/([.])/g, '*'),
            XingJinObject.S.replace(/([.])/g, '*'),
            XingJinObject.CA.replace(/([.])/g, '*'),
            XingJinObject.CF.replace(/([.])/g, '*'),
          ];
          keyInFormula = ['B', 'S', 'A', 'C'];
          WordQuantity = 1;
        } else {
          keyInFormula = ['error'];
          WordQuantity = 1;
        }
      } else {
        keyInFormula = ['formula'];
        WordQuantity = 1;
      }
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.length === 5 &&
      onlyWord.startsWith('*') &&
      toProcessArray[1] &&
      toProcessArray[1].includes('**')
    ) {
      toProcessArray[0] = RemoveDot;
      toProcessArray[1] = toProcessArray[1].replace('**', '');
      if (toProcessArray[1]) {
        const XingJinObject = Object.values(
          this.state.XingJinFormula || [],
        ).find(item => item.Num === toProcessArray[1]);
        if (XingJinObject) {
          toProcessArray = [
            onlyWord,
            XingJinObject.B.replace(/([.])/g, '*'),
            XingJinObject.S.replace(/([.])/g, '*'),
            XingJinObject.CA.replace(/([.])/g, '*'),
            XingJinObject.CF.replace(/([.])/g, '*'),
          ];
          if (this.props.OneStarBao === 0) {
            WordQuantity = 1;
            Mode = 'IB';
          } else {
            getPermutations = findPermutations(RemoveDot);
            WordQuantity = getPermutations.length;
            Mode = 'B';
          }
          keyInFormula = ['B', 'S', 'A', 'C'];
          // WordQuantity = 1;
        } else {
          keyInFormula = ['error'];
          WordQuantity = 1;
        }
      } else {
        keyInFormula = ['formula'];
        WordQuantity = 1;
      }
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.length === 6 &&
      onlyWord.startsWith('**') &&
      toProcessArray[1] &&
      toProcessArray[1].includes('**')
    ) {
      toProcessArray[0] = RemoveDot;
      toProcessArray[1] = toProcessArray[1].replace('**', '');
      if (toProcessArray[1]) {
        const XingJinObject = Object.values(
          this.state.XingJinFormula || [],
        ).find(item => item.Num === toProcessArray[1]);
        if (XingJinObject) {
          toProcessArray = [
            onlyWord,
            XingJinObject.B.replace(/([.])/g, '*'),
            XingJinObject.S.replace(/([.])/g, '*'),
            XingJinObject.CA.replace(/([.])/g, '*'),
            XingJinObject.CF.replace(/([.])/g, '*'),
          ];
          if (this.props.OneStarBao === 1) {
            WordQuantity = 1;
            Mode = 'IB';
          } else {
            getPermutations = findPermutations(RemoveDot);
            WordQuantity = getPermutations.length;
            Mode = 'B';
          }
          keyInFormula = ['B', 'S', 'A', 'C'];
          // WordQuantity = 1;
        } else {
          keyInFormula = ['error'];
          WordQuantity = 1;
        }
      } else {
        keyInFormula = ['formula'];
        WordQuantity = 1;
      }
    } else if (RemoveDot.length === 2 && onlyWord.length === 2) {
      keyInFormula = ['PA', 'PF'];
      WordQuantity = 1;
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.endsWith('***') &&
      onlyWord.length === 5
    ) {
      keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
      WordQuantity = 1;
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.endsWith('**') &&
      onlyWord.length === 4
    ) {
      keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
      WordQuantity = 1;
    } else if (RemoveDot.length === 3 && onlyWord.length === 3) {
      keyInFormula = processAC(this.state.Formula.KeyIn1);
      WordQuantity = 1;
    } else if (
      RemoveDot.length === 3 &&
      onlyWord.endsWith('***') &&
      onlyWord.length === 6
    ) {
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      WordQuantity = 23;
    } else if (
      RemoveDot.length === 3 &&
      onlyWord.endsWith('**') &&
      onlyWord.length === 5
    ) {
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      WordQuantity = 1;
    } else if (RemoveDot.length === 4 && onlyWord.length === 4) {
      keyInFormula = this.state.Formula.KeyIn1;

      WordQuantity = 1;
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.endsWith('***') &&
      onlyWord.length === 7
    ) {
      keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE'];
      WordQuantity = 23;
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.endsWith('**') &&
      onlyWord.length === 6
    ) {
      keyInFormula = this.state.Formula.KeyIn2;
      WordQuantity = 1;
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 3
    ) {
      if (this.props.OneStarBao === 0) {
        WordQuantity = 1;
        Mode = 'IB';
      } else {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      }
      keyInFormula = ['PA', 'PF'];
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 6 &&
      onlyWord.endsWith('***')
    ) {
      if (this.props.OneStarBao === 0) {
        WordQuantity = 23;
        Mode = 'IB';
      } else {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length * 23;
        Mode = 'B';
      }
      keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 5 &&
      onlyWord.endsWith('**')
    ) {
      if (this.props.OneStarBao === 0) {
        WordQuantity = 1;
        Mode = 'IB';
      } else {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      }
      keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
    } else if (
      RemoveDot.length === 3 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 4
    ) {
      if (this.props.OneStarBao === 0) {
        WordQuantity = 1;
        Mode = 'IB';
      } else {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      }
      keyInFormula = processAC(this.state.Formula.KeyIn1);
    } else if (
      RemoveDot.length === 3 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 7 &&
      onlyWord.endsWith('***')
    ) {
      if (this.props.OneStarBao === 0) {
        WordQuantity = 23;
        Mode = 'IB';
      } else {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length * 23;
        Mode = 'B';
      }
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
    } else if (
      RemoveDot.length === 3 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 6 &&
      onlyWord.endsWith('**')
    ) {
      if (this.props.OneStarBao === 0) {
        WordQuantity = 1;
        Mode = 'IB';
      } else {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      }
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 4
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      } else {
        WordQuantity = 1;
        Mode = 'IB';
      }
      keyInFormula = ['PA', 'PF'];
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 7 &&
      onlyWord.endsWith('***')
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length * 23;
        Mode = 'B';
      } else {
        WordQuantity = 23;
        Mode = 'IB';
      }
      keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 6 &&
      onlyWord.endsWith('**')
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      } else {
        WordQuantity = 1;
        Mode = 'IB';
      }
      keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
    } else if (
      RemoveDot.length === 3 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 5
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      } else {
        WordQuantity = 1;
        Mode = 'IB';
      }
      keyInFormula = processAC(this.state.Formula.KeyIn1);
    } else if (
      RemoveDot.length === 3 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 8 &&
      onlyWord.endsWith('***')
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length * 23;
        Mode = 'B';
      } else {
        WordQuantity = 23;
        Mode = 'IB';
      }
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
    } else if (
      RemoveDot.length === 3 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 7 &&
      onlyWord.endsWith('**')
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      } else {
        WordQuantity = 1;
        Mode = 'IB';
      }
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
    } else if (
      onlyWord[1] === '*' &&
      onlyWord.length === 4 &&
      RemoveDot.length === 3
    ) {
      subStringWord = RemoveDot.substring(1, 3);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = processAC(this.state.Formula.KeyIn1);
      Mode = '1{234}';
    } else if (
      onlyWord[1] === '*' &&
      onlyWord.length === 7 &&
      RemoveDot.length === 3 &&
      onlyWord.endsWith('***')
    ) {
      subStringWord = RemoveDot.substring(1, 3);
      getPermutations = findPermutations(subStringWord) * 23;
      WordQuantity = getPermutations.length;
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      Mode = '1{234}';
    } else if (
      onlyWord[1] === '*' &&
      onlyWord.length === 6 &&
      RemoveDot.length === 3 &&
      onlyWord.endsWith('**')
    ) {
      subStringWord = RemoveDot.substring(1, 3);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      Mode = '1{234}';
    } else if (
      onlyWord[2] === '*' &&
      onlyWord.length === 4 &&
      RemoveDot.length === 3
    ) {
      subStringWord = RemoveDot.substring(0, 2);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = processAC(this.state.Formula.KeyIn1);
      Mode = '{123}4';
    } else if (
      onlyWord[2] === '*' &&
      onlyWord.length === 7 &&
      RemoveDot.length === 3
    ) {
      subStringWord = RemoveDot.substring(0, 2);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length * 23;
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      Mode = '{123}4';
    } else if (
      onlyWord[2] === '*' &&
      onlyWord.length === 6 &&
      RemoveDot.length === 3
    ) {
      subStringWord = RemoveDot.substring(0, 2);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      Mode = '{123}4';
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 5
    ) {
      if (this.props.OneStarBao === 0) {
        WordQuantity = 1;
        Mode = 'IB';
      } else {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      }
      keyInFormula = this.state.Formula.KeyIn1;
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 8 &&
      onlyWord.endsWith('***')
    ) {
      if (this.props.OneStarBao === 0) {
        WordQuantity = 23;
        Mode = 'IB';
      } else {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length * 23;
        Mode = 'B';
      }
      keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE'];
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 7 &&
      onlyWord.endsWith('**')
    ) {
      if (this.props.OneStarBao === 0) {
        WordQuantity = 1;
        Mode = 'IB';
      } else {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      }
      keyInFormula = this.state.Formula.KeyIn2;
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 6
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      } else {
        WordQuantity = 1;
        Mode = 'IB';
      }
      keyInFormula = this.state.Formula.KeyIn1;
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 9 &&
      onlyWord.endsWith('***')
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      } else {
        WordQuantity = 23;
        Mode = 'IB';
      }
      keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE'];
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 8 &&
      onlyWord.endsWith('**')
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      } else {
        WordQuantity = 1;
        Mode = 'IB';
      }
      keyInFormula = this.state.Formula.KeyIn2;
    } else if (
      onlyWord[1] === '*' &&
      onlyWord.length === 5 &&
      RemoveDot.length === 4
    ) {
      subStringWord = RemoveDot.substring(1, 4);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = this.state.Formula.KeyIn1;
      Mode = '1{234}';
    } else if (
      onlyWord[1] === '*' &&
      onlyWord.length === 8 &&
      onlyWord.endsWith('***') &&
      RemoveDot.length === 4
    ) {
      subStringWord = RemoveDot.substring(1, 4);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length * 23;
      keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE'];
      Mode = '1{234}';
    } else if (
      onlyWord[1] === '*' &&
      onlyWord.length === 7 &&
      onlyWord.endsWith('**') &&
      RemoveDot.length === 4
    ) {
      subStringWord = RemoveDot.substring(1, 4);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = this.state.Formula.KeyIn2;
      Mode = '1{234}';
    } else if (
      onlyWord[3] === '*' &&
      onlyWord.length === 5 &&
      RemoveDot.length === 4
    ) {
      subStringWord = RemoveDot.substring(0, 3);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = this.state.Formula.KeyIn1;
      Mode = '{123}4';
    } else if (
      onlyWord[3] === '*' &&
      onlyWord.length === 8 &&
      onlyWord.endsWith('**') &&
      RemoveDot.length === 4
    ) {
      subStringWord = RemoveDot.substring(0, 3);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length * 23;
      keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE'];
      Mode = '{123}4';
    } else if (
      onlyWord[3] === '*' &&
      onlyWord.length === 7 &&
      onlyWord.endsWith('**') &&
      RemoveDot.length === 4
    ) {
      subStringWord = RemoveDot.substring(0, 3);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = this.state.Formula.KeyIn2;
      Mode = '{123}4';
    } else if (RemoveDot.includes('-')) {
      // RUN RANGE
      let StartNum = RemoveDot.split('-')[0];
      let ToNum = RemoveDot.substring(RemoveDot.indexOf('-') + 1);
      if (
        StartNum.length === 3 &&
        ToNum.length === 3 &&
        onlyWord.length === 7
      ) {
        WordQuantity = ToNum - StartNum + 1;
        if (WordQuantity < 2) {
          alert(
            language[this.props.currentLanguage].RangeBiggerNumber,
          );
        } else {
          Mode = '{1234}*(' + WordQuantity + ')';
          keyInFormula = processAC(this.state.Formula.KeyIn1);
        }
      } else if (
        StartNum.length === 3 &&
        ToNum.length === 3 &&
        onlyWord.endsWith('***') &&
        onlyWord.length === 10
      ) {
        WordQuantity = (ToNum - StartNum + 1) * 23;
        if (WordQuantity < 2) {
          alert(
            language[this.props.currentLanguage].RangeBiggerNumber,
          );
        } else {
          Mode = '{1234}*(' + WordQuantity + ')';
          keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
        }
      } else if (
        StartNum.length === 3 &&
        ToNum.length === 3 &&
        onlyWord.endsWith('**') &&
        onlyWord.length === 9
      ) {
        WordQuantity = ToNum - StartNum + 1;
        if (WordQuantity < 2) {
          alert(
            language[this.props.currentLanguage].RangeBiggerNumber,
          );
        } else {
          Mode = '{1234}*(' + WordQuantity + ')';
          keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
        }
      } else if (
        StartNum.length === 4 &&
        ToNum.length === 4 &&
        onlyWord.endsWith('***') &&
        onlyWord.length === 12
      ) {
        //WordQuantity = Math.abs(StartNum - ToNum) + 1;
        WordQuantity = (ToNum - StartNum + 1) * 23;
        if (WordQuantity < 2) {
          alert(
            language[this.props.currentLanguage].RangeBiggerNumber,
          );
        } else {
          Mode = '{1234}*(' + WordQuantity + ')';
          keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE'];
        }
      } else if (
        StartNum.length === 4 &&
        ToNum.length === 4 &&
        onlyWord.endsWith('**') &&
        onlyWord.length === 11
      ) {
        //WordQuantity = Math.abs(StartNum - ToNum) + 1;
        WordQuantity = ToNum - StartNum + 1;
        if (WordQuantity < 2) {
          alert(
            language[this.props.currentLanguage].RangeBiggerNumber,
          );
        } else {
          Mode = '{1234}*(' + WordQuantity + ')';
          keyInFormula = this.state.Formula.KeyIn2;
        }
      } else if (
        StartNum.length === 4 &&
        ToNum.length === 4 &&
        !onlyWord.endsWith('**') &&
        onlyWord.length === 9
      ) {
        //WordQuantity = Math.abs(StartNum - ToNum) + 1;
        WordQuantity = ToNum - StartNum + 1;
        if (WordQuantity < 2) {
          alert(
            language[this.props.currentLanguage].RangeBiggerNumber,
          );
        } else {
          Mode = '{1234}*(' + WordQuantity + ')';
          keyInFormula = this.state.Formula.KeyIn1;
        }
      }
    } else if (RemoveDot.length === 5 && onlyWord.length === 5) {
      keyInFormula = ['5D'];
      WordQuantity = 1;
    } else if (
      RemoveDot.length === 5 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 6
    ) {
      if (this.props.OneStarBao === 1) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
        keyInFormula = ['5D'];
      }
    } else if (
      RemoveDot.length === 5 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 7
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
        keyInFormula = ['5D'];
      }
    } else if (RemoveDot.length === 6 && onlyWord.length === 6) {
      keyInFormula = ['6D'];
      WordQuantity = 1;
    } else if (
      RemoveDot.length === 6 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 7
    ) {
      if (this.props.OneStarBao === 1) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
        keyInFormula = ['6D'];
      }
    } else if (
      RemoveDot.length === 6 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 8
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
        keyInFormula = ['6D'];
      }
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.endsWith('*') &&
      onlyWord.length === 5
    ) {
      if (toProcessArray[1]) {
        const XingJinObject = Object.values(
          this.state.XingJinFormula || [],
        ).find(item => item.Num === toProcessArray[1]);
        if (XingJinObject) {
          toProcessArray = [
            onlyWord,
            XingJinObject.B.replace(/([.])/g, '*'),
            XingJinObject.S.replace(/([.])/g, '*'),
            XingJinObject.CA.replace(/([.])/g, '*'),
            XingJinObject.CF.replace(/([.])/g, '*'),
          ];
          keyInFormula = ['B', 'S', 'A', 'C'];
          WordQuantity = 1;
        } else {
          keyInFormula = ['error'];
          WordQuantity = 1;
        }
      } else {
        keyInFormula = ['formula'];
        WordQuantity = 1;
      }
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.endsWith('*') &&
      onlyWord.length === 6 &&
      onlyWord.startsWith('*')
    ) {
      if (toProcessArray[1]) {
        const XingJinObject = Object.values(
          this.state.XingJinFormula || [],
        ).find(item => item.Num === toProcessArray[1]);
        if (XingJinObject) {
          toProcessArray = [
            onlyWord,
            XingJinObject.B.replace(/([.])/g, '*'),
            XingJinObject.S.replace(/([.])/g, '*'),
            XingJinObject.CA.replace(/([.])/g, '*'),
            XingJinObject.CF.replace(/([.])/g, '*'),
          ];
          if (this.props.OneStarBao === 0) {
            WordQuantity = 1;
            Mode = 'IB';
          } else {
            getPermutations = findPermutations(RemoveDot);
            WordQuantity = getPermutations.length;
            Mode = 'B';
          }
          keyInFormula = ['B', 'S', 'A', 'C'];
        } else {
          keyInFormula = ['error'];
          WordQuantity = 1;
        }
      } else {
        keyInFormula = ['formula'];
        WordQuantity = 1;
      }
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.endsWith('*') &&
      onlyWord.length === 7 &&
      onlyWord.startsWith('**')
    ) {
      if (toProcessArray[1]) {
        const XingJinObject = Object.values(
          this.state.XingJinFormula || [],
        ).find(item => item.Num === toProcessArray[1]);
        if (XingJinObject) {
          toProcessArray = [
            onlyWord,
            XingJinObject.B.replace(/([.])/g, '*'),
            XingJinObject.S.replace(/([.])/g, '*'),
            XingJinObject.CA.replace(/([.])/g, '*'),
            XingJinObject.CF.replace(/([.])/g, '*'),
          ];
          if (this.props.OneStarBao === 1) {
            WordQuantity = 1;
            Mode = 'IB';
          } else {
            getPermutations = findPermutations(RemoveDot);
            WordQuantity = getPermutations.length;
            Mode = 'B';
          }
          keyInFormula = ['B', 'S', 'A', 'C'];
        } else {
          keyInFormula = ['error'];
          WordQuantity = 1;
        }
      } else {
        keyInFormula = ['formula'];
        WordQuantity = 1;
      }
    }
    if (originalWord.startsWith('##')) {
      originalWord = originalWord.substring(2, originalWord.length);
      onlyWord = originalWord.split('#')[0];
      RemoveDot = onlyWord.replace(/([*])/g, '');
      toProcessArray = originalWord.split('#');
      newLineWord = '##' + newLineWord;
      WordQuantity = 10;
      if (onlyWord.length === 4 && RemoveDot.length === 4) {
        Mode = '123{0-9}';
        keyInFormula = this.state.Formula.KeyIn1;
      } else if (onlyWord.length === 3 && RemoveDot.length === 3) {
        Mode = '123{0-9}';
        keyInFormula = processAC(this.state.Formula.KeyIn1);
      } else if (onlyWord.length === 2 && RemoveDot.length === 2) {
        Mode = '123{0-9}';
        keyInFormula = ['PA', 'PF'];
      } else if (
        onlyWord.length === 6 &&
        RemoveDot.length === 3 &&
        onlyWord.endsWith('***')
      ) {
        WordQuantity = 230;
        Mode = '123{0-9}';
        keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      } else if (
        onlyWord.length === 5 &&
        RemoveDot.length === 3 &&
        onlyWord.endsWith('**')
      ) {
        Mode = '123{0-9}';
        keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      } else if (
        onlyWord.length === 4 &&
        RemoveDot.length === 2 &&
        onlyWord.endsWith('**')
      ) {
        Mode = '123{0-9}';
        keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
      } else if (
        onlyWord.endsWith('***') &&
        onlyWord.length === 7 &&
        RemoveDot.length === 4
      ) {
        Mode = '123{0-9}';
        keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE'];
      } else if (
        onlyWord.endsWith('**') &&
        onlyWord.length === 6 &&
        RemoveDot.length === 4
      ) {
        Mode = '123{0-9}';
        keyInFormula = this.state.Formula.KeyIn2;
      }
    } else if (originalWord.startsWith('#')) {
      originalWord = originalWord.substring(1, originalWord.length);
      onlyWord = originalWord.split('#')[0];
      RemoveDot = onlyWord.replace(/([*])/g, '');
      toProcessArray = originalWord.split('#');
      newLineWord = '#' + newLineWord;
      WordQuantity = 10;
      if (onlyWord.length === 4 && RemoveDot.length === 4) {
        keyInFormula = this.state.Formula.KeyIn1;
        Mode = '{0-9}123';
      } else if (onlyWord.length === 3 && RemoveDot.length === 3) {
        keyInFormula = processAC(this.state.Formula.KeyIn1);
        Mode = '{0-9}123';
      } else if (onlyWord.length === 2 && RemoveDot.length === 2) {
        keyInFormula = ['PA', 'PF'];
        Mode = '{0-9}123';
      } else if (onlyWord.length === 6 && RemoveDot.length === 3) {
        WordQuantity = 230;
        keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
        Mode = '{0-9}123';
      } else if (onlyWord.length === 5 && RemoveDot.length === 3) {
        keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
        Mode = '{0-9}123';
      } else if (
        onlyWord.endsWith('**') &&
        onlyWord.length === 4 &&
        RemoveDot.length === 2
      ) {
        keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
        Mode = '{0-9}123';
      } else if (
        onlyWord.endsWith('***') &&
        onlyWord.length === 7 &&
        RemoveDot.length === 4
      ) {
        WordQuantity = 10 * 23;
        Mode = '{0-9}123';
        keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE'];
      } else if (
        onlyWord.endsWith('**') &&
        onlyWord.length === 6 &&
        RemoveDot.length === 4
      ) {
        Mode = '{0-9}123';
        keyInFormula = this.state.Formula.KeyIn2;
      }
    }
    if (
      originalWord.includes('***#') &&
      !onlyWord.endsWith('**') &&
      RemoveDot.length === 4
    ) {
      let FilterFormula1 = [];
      for (let q = 0; q < toProcessArray.length; q += 1) {
        q === 0
          ? toProcessArray[q]
          : FilterFormula1.push(this.state.Formula.KeyIn1[q - 1]);
        if (toProcessArray[q].includes('***')) {
          break;
        }
      }
      let concatFormula = FilterFormula1.concat(this.state.Formula.KeyIn2);
      concatFormula = [...new Set(concatFormula)];
      toProcessArray = originalWord.replace('***', '').split('#');
      keyInFormula = concatFormula;
    }
    if (Mode === 'IB') {
      for (let j = 0; j < toProcessArray.length; j += 1) {
        newLineWord +=
          j === 0
            ? toProcessArray[j]
            : ` <blue>(IB)${keyInFormula[j - 1]}</blue> ${toProcessArray[j]}`;
        if (j !== 0) {
          BLineAmount += Number(toProcessArray[j].replace(/([*])/g, '.'));
          if (this.state.DevideOrMultiply === 'D') {
            if (numberOfPlatform > 0) {
              CalculateLineAmount += toProcessArray[j].replace(/([*])/g, '.') * WordQuantity;
            }
          } else {
            CalculateLineAmount +=
              toProcessArray[j].replace(/([*])/g, '.') * numberOfPlatform * WordQuantity;
          }
        }
      }
    } else {
      for (let j = 0; j < toProcessArray.length; j += 1) {
        newLineWord +=
          j === 0
            ? toProcessArray[j]
            : ` <blue>${keyInFormula[j - 1]}</blue> ${toProcessArray[j]}`;
        if (j !== 0) {
          BLineAmount += Number(toProcessArray[j].replace(/([*])/g, '.'));
          if (this.state.DevideOrMultiply === 'D') {
            if (numberOfPlatform > 0) {
              CalculateLineAmount += toProcessArray[j].replace(/([*])/g, '.') * WordQuantity;
            }
          } else {
            CalculateLineAmount +=
              toProcessArray[j].replace(/([*])/g, '.') * numberOfPlatform * WordQuantity;
          }
        }
      }
    }
    return {newLineWord, BLineAmount, CalculateLineAmount, Mode, keyInFormula};
  }

  renderWord(newLineWordColor) {
    if (newLineWordColor.includes('<green>')) {
      let returnWord = [];
      const WordArray = newLineWordColor
        .split('<green>')
        .join('</green>')
        .split('</green>');
      for (let x = 0; x < WordArray.length; x += 1) {
        if (x === 0) {
          returnWord.push(<div key={x}>{WordArray[x]}</div>);
        } else if (x % 2 === 1) {
          returnWord.push(
            <div
              key={x}
              style={{
                color: '#0d5922',
                // fontSize: 19,
                fontWeight: 'bold',
                letterSpacing: 5,
              }}>
              {WordArray[x]}
            </div>,
          );
        } else if (x % 2 === 0) {
          returnWord.push(<div key={x}>{WordArray[x]}</div>);
        }
      }
      newLineWordColor = returnWord;
    } else if (newLineWordColor.includes('<blue>')) {
      let returnWord = [];
      const WordArray = newLineWordColor
        .split('<blue>')
        .join('</blue>')
        .split('</blue>');
      for (let x = 0; x < WordArray.length; x += 1) {
        if (x === 0) {
          returnWord.push(<div key={x}>{WordArray[x]}</div>);
        } else if (x % 2 === 1) {
          returnWord.push(
            <div
              key={x}
              style={{
                color: 'blue',
                // fontSize: 17,
                fontWeight: 'bold',
                marginRight: 2,
                marginLeft: 2,
              }}>
              {WordArray[x]}
            </div>,
          );
        } else if (x % 2 === 0) {
          returnWord.push(<div key={x}>{WordArray[x]}</div>);
        }
      }
      newLineWordColor = returnWord;
    } else if (newLineWordColor.includes('<Dgreen>')) {
      let returnWord = [];
      const WordArray = newLineWordColor
        .split('<Dgreen>')
        .join('</Dgreen>')
        .split('</Dgreen>');
      for (let x = 0; x < WordArray.length; x += 1) {
        if (x === 0) {
          returnWord.push(<div key={x}>{WordArray[x]}</div>);
        } else if (x % 2 === 1) {
          returnWord.push(
            <div
              key={x}
              style={{
                color: '#005c08',
                // fontSize: 19,
                fontWeight: 'bold',
              }}>
              {WordArray[x]}
            </div>,
          );
        } else if (x % 2 === 0) {
          returnWord.push(<div key={x}>{WordArray[x]}</div>);
        }
      }
      newLineWordColor = returnWord;
    }
    return {newLineWordColor};
  }

  renderPF(newLinePFColor) {
    if (newLinePFColor.includes('<green>')) {
      let returnWord = [];
      const WordArray = newLinePFColor
        .split('<green>')
        .join('</green>')
        .split('</green>');
      for (let x = 0; x < WordArray.length; x += 1) {
        if (x === 0) {
          returnWord.push(<div key={x}>{WordArray[x]}</div>);
        } else if (x % 2 === 1) {
          returnWord.push(
            <div
              key={x}
              style={{
                color: '#0d5922',
                fontSize: 14,
                fontWeight: 'bold',
                letterSpacing: 4,
              }}>
              {WordArray[x]}
            </div>,
          );
        } else if (x % 2 === 0) {
          returnWord.push(<div key={x}>{WordArray[x]}</div>);
        }
      }
      newLinePFColor = returnWord;
    }
    return {newLinePFColor};
  }

  async onpressClearAll() {
    if (window.confirm(language[this.props.currentLanguage].ClearAllConfirmation)) {
      this.setState({
        lineData: [
          {
            originalWord: 'D#',
            translatedWord: 'D#',
            Mode: '',
          },
        ],
        SelectedDate: '',
        OverallAmount: 0,
        TotalLine: 0,
        CurrentPlatForm: '',
        SelectedSpecial: '-',
        InputAmount: [
          {Amount: ''},
          {Amount: ''},
          {Amount: ''},
          {Amount: ''},
          {Amount: ''},
          {Amount: ''},
          {Amount: ''},
          {Amount: ''},
        ],
        SelectingInput: 'Number',
        SelectedNum: 0,
        DisplayFormula: this.state.FourDFormula,
        BTWord: '',
        BTWordRange: '',
      }, () => {
        this.ProcessWords();
      },
      );
    }
  }

  async RemoveSelectedNumber(idx) {
    if (window.confirm(language[this.props.currentLanguage].RemoveConfirmation)) {
      let lineData = this.state.lineData.reverse();
      lineData[idx].originalWord = '';
      lineData[idx].CalculateLineAmount = '';
      lineData[idx].translatedWord = '';
      lineData[idx].Mode = '';
      lineData = this.state.lineData.reverse();
      this.ProcessWords(lineData);
    }
  }


  async SendButtonFunction() {
    this.setState({loading: true});
    const lineData = this.state.lineData;
    const OriwordArray = [];
    let ErrorLine = [];
    let Platformvalidate = false;
    let BetWordValidate = false;
    for (var i = 0; i < lineData.length; i++) {
      for (var u = 0; u < lineData[i].translatedWord.length; u++) {
        if (
          JSON.stringify(lineData[i].translatedWord[u]).includes('undefined')
        ) {
          ErrorLine.push(i);
        }
      }
      let ConvertDot = lineData[i].originalWord;
      if (lineData[i].originalWord.includes('D#')) {
        OriwordArray.push(ConvertDot + '\n');
      } else if (
        lineData[i].originalWord !== '' &&
        !lineData[i].originalWord.includes('#')
      ) {
        if (this.state.JingNan === 1) {
          OriwordArray.push('#' + ConvertDot + '\n');
        } else {
          OriwordArray.push(ConvertDot + '\n');
        }
      } else if (
        lineData[i].originalWord !== '' &&
        lineData[i].originalWord.includes('#') &&
        lineData[i].CalculateLineAmount > 0
      ) {
        OriwordArray.push(ConvertDot + '\n');
      }
    }
    for (var a = 0; a < OriwordArray.length; a++) {
      if (a !== 0) {
        //if No Platform At All
        if (
          this.state.JingNan === 1 &&
          OriwordArray[1].split('#').length === 2
        ) {
          Platformvalidate = true;
        } else if (!OriwordArray[1].includes('#')) {
          Platformvalidate = true;
        }
        //if No Amount At All
        if (this.state.JingNan === 1) {
          BetWordValidate = true;
        } else if (
          this.state.JingNan === 0 &&
          OriwordArray[a].includes('#') === true
        ) {
          BetWordValidate = true;
        }
      }
    }
    if (ErrorLine.length > 0) {
      alert(
        language[this.props.currentLanguage].LineErr + ErrorLine,
      );
    } else if (Platformvalidate === false) {
      alert(
        language[this.props.currentLanguage].BTNoPlatForm,
      );
    } else if (BetWordValidate === false) {
      alert(
        language[this.props.currentLanguage].BTNoAmount,
      );
    } else {
      const OrderReceiptData = await CreateBetOrder(
        this.state.UserID,
        OriwordArray.join(''),
        this.props.token,
        this.props.username,
        '',
        '',
        this.state.nameInput,
        'mobile',
      );
      if (OrderReceiptData.error === 'User No Package') {
        alert(
          language[this.props.currentLanguage].NoBetPackage,
        );
      } else if (OrderReceiptData.error === 'No Date to Bet') {
        alert(
          language[this.props.currentLanguage].NoDateBet,
        );
      } else if (OrderReceiptData.error === '5D6D in wrong game') {
        alert(
          language[this.props.currentLanguage].Wrong5D6D,
        );
      } else if (OrderReceiptData.error === 'Date error') {
        alert(
          language[this.props.currentLanguage].BuyDateWarn,
        );
      } else if (OrderReceiptData.error === 'Not enough date') {
        alert(
          language[this.props.currentLanguage].NoEnufDate,
        );
      } else if (OrderReceiptData.error === 'Game ShutDown') {
        alert(
          language[this.props.currentLanguage].GameShutDown,
        );
      } else if (OrderReceiptData.error === 'S Game ShutDown') {
        alert(
          language[this.props.currentLanguage].SGameShutDown,
        );
      } else if (OrderReceiptData.error === 'No Game on that day') {
        alert(
          language[this.props.currentLanguage].DateNoAvailable,
        );
      } else if (OrderReceiptData.error === 'Platform error') {
        alert(
          language[this.props.currentLanguage].PlatFormErr,
        );
      } else if (OrderReceiptData.error === 'Account Inactive') {
        alert(
          language[this.props.currentLanguage].AccountInactive,
        );
      } else if (OrderReceiptData.error === 'Exceed Personal Daily Limit') {
        alert(
          language[this.props.currentLanguage].ExceedPerLimit,
        );
      } else if (OrderReceiptData.error === 'User Red Lower Than Commission') {
        alert(
          'User Red Lower Than Commission',
        );
      } else if (OrderReceiptData.error === 'MKTSWBD ShutDown') {
        alert(
          language[this.props.currentLanguage].MKTSWBDoff,
        );
      } else if (OrderReceiptData.error === 'All Game ShutDown') {
        alert(
          language[this.props.currentLanguage].AllGameoff,
        );
      } else if (OrderReceiptData.error === 'Repeat Order') {
        alert(language[this.props.currentLanguage].RepeatOrder,);
      } else if (OrderReceiptData.error === 'Over Number Limit') {
        alert(
          language[this.props.currentLanguage].OverNumberLimit,
        );
      } else if (OrderReceiptData.error === 'Format Error') {
        alert(language[this.props.currentLanguage].FormatError);
      } else if (OrderReceiptData.error === 'User Negative Commission') {
        alert(
          language[this.props.currentLanguage].UserNegativeCommission,
        );
      } else if (OrderReceiptData.error === 'Cannot bet game desired') {
        alert(
          language[this.props.currentLanguage].GameDesired,
        );
      } else if (OrderReceiptData.error === 'Not enough cash wallet') {
        alert(
          'Balance:' +
            OrderReceiptData.balance +
            '\n' +
          language[this.props.currentLanguage].noEnufCashWall,
        );
      } else if (OrderReceiptData.error) {
        alert(
          OrderReceiptData.error,
        );
      } else if (OrderReceiptData === 'TimeOut') {
        this.LogoutFunction();
      } else {
        this.props.setReceiptData(OrderReceiptData, '');
        this.props.history.push('/receiptAfterSubmit');
      }
    }
    this.setState({loading: false});
  }

  FindReverNum() {
    const lineData = this.state.lineData;
    const Line = this.state.TotalLine || 0;
    if (Line !== 0) {
      for (var i = this.state.lineData.length - 1; i >= 0; i--) {
        const bAMount = lineData[i].CalculateLineAmount || '';
        if (bAMount > 0) {
          this.ReverseNumFunction(i);
          break;
        } else {
          //ignore
        }
      }
    } else {
      alert(
        language[this.props.currentLanguage].NtgRever,
      );
    }
  }

  ReverseNumFunction(NumI) {
    const lineData = this.state.lineData;
    let OriginalWord = lineData[NumI].originalWord;
    const Mode = lineData[NumI].Mode;
    let onlyWord = '';
    let GetFormulaAmount = '';
    let RemoveDot = '';
    let Key2Sign = '';
    let ReverseWord = '';
    if (NumI !== 0) {
      if (OriginalWord.startsWith('##')) {
        OriginalWord = OriginalWord.substring(2, OriginalWord.length);
      } else if (OriginalWord.startsWith('#')) {
        OriginalWord = OriginalWord.substring(1, OriginalWord.length);
      }
      onlyWord = OriginalWord.split('#')[0];
      RemoveDot = onlyWord.replace(/([*])/g, '');
      GetFormulaAmount = OriginalWord.substring(OriginalWord.indexOf('#'));
      if (RemoveDot.length === 4 && GetFormulaAmount.includes('#')) {
        if (onlyWord.endsWith('**')) {
          Key2Sign = '**';
        }
        ReverseWord = RemoveDot.split('');
        ReverseWord = ReverseWord.slice()
          .reverse()
          .join()
          .replace(/([,])/g, '');
        this.NextLineButtonFunction();
        if (this.props.OneStarBao === 0) {
          if (Mode === 'IB') {
            ReverseWord = '*' + ReverseWord;
          } else if (Mode === 'B') {
            ReverseWord = '**' + ReverseWord;
          } else if (Mode === '{0-9}123') {
            ReverseWord = '#' + ReverseWord;
          } else if (Mode === '123{0-9}') {
            ReverseWord = '##' + ReverseWord;
          } else if (Mode === '{123}4') {
            ReverseWord = ReverseWord.substring(0, 3) + '*' + ReverseWord[3];
          } else if (Mode === '1{234}') {
            ReverseWord = ReverseWord[0] + '*' + ReverseWord.substring(1, 4);
          }
        } else {
          if (Mode === 'IB') {
            ReverseWord = '**' + ReverseWord;
          } else if (Mode === 'B') {
            ReverseWord = '*' + ReverseWord;
          } else if (Mode === '{0-9}123') {
            ReverseWord = '#' + ReverseWord;
          } else if (Mode === '123{0-9}') {
            ReverseWord = '##' + ReverseWord;
          } else if (Mode === '{123}4') {
            ReverseWord = ReverseWord.substring(0, 3) + '*' + ReverseWord[3];
          } else if (Mode === '1{234}') {
            ReverseWord = ReverseWord[0] + '*' + ReverseWord.substring(1, 4);
          }
        }
        lineData[NumI + 1].originalWord =
          ReverseWord + Key2Sign + GetFormulaAmount;
      } else {
        alert(
          language[this.props.currentLanguage].OnlyFourDAlert,
        );
      }
      this.ProcessWords(lineData);
    } else {
      alert(
        language[this.props.currentLanguage].OnlyFourDAlert,
      );
    }
  }

  NextLineButtonFunction() {
    let addOneNewLine = this.state.lineData;
    addOneNewLine.push({
      originalWord: '',
      translatedWord: '',
      CalculateLineAmount: '',
      Mode: '',
    });
  }

  PressNumberFunction(PressNumber) {
    if (this.state.SelectingInput === 'Number') {
      const toProcessBTWord = this.state.BTWord + PressNumber;
      let keyInFormula = [];
      if (this.state.SelectedSpecial === '{1234}') {
        if (toProcessBTWord.length <= 4) {
          this.setState({
            BTWord: toProcessBTWord,
            DisplayFormula: this.state.FourDFormula,
          });
        } else {
          alert(
            language[this.props.currentLanguage].SpecialSupportHint,
          );
        }
      } else if (
        toProcessBTWord.length === 2 &&
        this.state.Key2CheckMark === false
      ) {
        keyInFormula = ['PA', 'PF'];
        this.setState({
          BTWord: toProcessBTWord,
          DisplayFormula: keyInFormula,
        });
      } else if (
        toProcessBTWord.length === 2 &&
        this.state.Key2CheckMark === true
      ) {
        keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
        this.setState({
          BTWord: toProcessBTWord,
          DisplayFormula: keyInFormula,
        });
      } else if (
        toProcessBTWord.length === 3 &&
        this.state.Key2CheckMark === false
      ) {
        keyInFormula = processAC(this.state.Formula.KeyIn1);
        this.setState({
          BTWord: toProcessBTWord,
          DisplayFormula: keyInFormula,
        });
      } else if (
        toProcessBTWord.length === 3 &&
        this.state.Key2CheckMark === true
      ) {
        keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
        this.setState({
          BTWord: toProcessBTWord,
          DisplayFormula: keyInFormula,
        });
      } else if (toProcessBTWord.length === 4) {
        this.setState({
          BTWord: toProcessBTWord,
          DisplayFormula: this.state.FourDFormula,
        });
      } else if (toProcessBTWord.length === 5) {
        this.setState({
          BTWord: toProcessBTWord,
          DisplayFormula: ['5D'],
        });
      } else if (toProcessBTWord.length === 6) {
        this.setState({
          BTWord: toProcessBTWord,
          DisplayFormula: ['6D'],
        });
      } else if (toProcessBTWord.length > 6) {
        alert(
          language[this.props.currentLanguage].MaxSixNum,
        );
      } else {
        this.setState({
          BTWord: toProcessBTWord,
          DisplayFormula: this.state.FourDFormula,
        });
      }
    } else if (this.state.SelectingInput === 'NumberRange') {
      const toProcessBTWord = this.state.BTWordRange + PressNumber;
      if (this.state.SelectedSpecial === '{1234}') {
        if (toProcessBTWord.length <= 4) {
          this.setState({
            BTWordRange: toProcessBTWord,
            DisplayFormula: this.state.FourDFormula,
          });
        } else {
          alert(
            language[this.props.currentLanguage].SpecialSupportHint,
          );
        }
      } else if (toProcessBTWord.length === 3) {
        this.setState({
          BTWordRange: toProcessBTWord,
        });
      } else if (toProcessBTWord.length === 4) {
        this.setState({
          BTWordRange: toProcessBTWord,
        });
      } else if (toProcessBTWord.length === 5) {
        this.setState({
          BTWordRange: toProcessBTWord,
        });
      } else if (toProcessBTWord.length === 6) {
        this.setState({
          BTWordRange: toProcessBTWord,
        });
      } else if (toProcessBTWord.length > 6) {
        alert(
          language[this.props.currentLanguage].MaxSixNum,
        );
      } else {
        this.setState({
          BTWordRange: toProcessBTWord,
        });
      }
    } else {
      const amountData = this.state.InputAmount;
      amountData[this.state.SelectedNum].Amount =
        amountData[this.state.SelectedNum].Amount + PressNumber;
      this.setState({InputAmount: amountData});
    }
  }

  SelectingInputFunction(Type, idx) {
    if (Type === 'Number' || Type === 'NumberRange') {
      this.setState({
        SelectingInput: Type,
        SelectedNum: 0,
        keyboardVisible: true,
      });
    } else {
      this.setState({
        SelectingInput: Type,
        SelectedNum: idx,
        keyboardVisible: true,
      });
    }
  }

  leftArrowFunction() {
    const Type = this.state.SelectingInput;
    if (Type === 'Number') {
      //Ignore
    } else if (Type === 'NumberRange') {
      this.setState({SelectingInput: 'Number', SelectedNum: 0});
    } else if (
      Type === 'Amount' &&
      this.state.SelectedNum === 0 &&
      this.state.SelectedSpecial === '{1234}'
    ) {
      this.setState({SelectingInput: 'NumberRange', SelectedNum: 0});
    } else if (
      Type === 'Amount' &&
      this.state.SelectedNum === 0 &&
      this.state.SelectedSpecial !== '{1234}'
    ) {
      this.setState({SelectingInput: 'Number', SelectedNum: 0});
    } else if (Type === 'Amount' && this.state.SelectedNum > 0) {
      this.setState({
        SelectedNum: this.state.SelectedNum - 1,
      });
    }
  }
  rightArrowFunction() {
    const Type = this.state.SelectingInput;
    if (Type === 'Number' && this.state.SelectedSpecial === '{1234}') {
      this.setState({SelectingInput: 'NumberRange', SelectedNum: 0});
    } else if (Type === 'Number' && this.state.SelectedSpecial !== '{1234}') {
      this.setState({SelectingInput: 'Amount', SelectedNum: 0});
    } else if (Type === 'NumberRange') {
      this.setState({SelectingInput: 'Amount', SelectedNum: 0});
    } else if (Type === 'Amount' && this.state.BTWord.length === 3) {
      if (Type === 'Amount' && this.state.SelectedNum < 1) {
        this.setState({
          SelectedNum: this.state.SelectedNum + 1,
        });
      }
    } else if (Type === 'Amount' && this.state.BTWord.length === 5) {
      //ignore
    } else if (Type === 'Amount' && this.state.BTWord.length === 6) {
      //ignore
    } else if (Type === 'Amount' && this.state.SelectedNum >= 5) {
      // ignore
    } else if (Type === 'Amount' && this.state.SelectedNum <= 5) {
      this.setState({
        SelectedNum: this.state.SelectedNum + 1,
      });
    }
  }

  onPressBackspace() {
    if (this.state.SelectingInput === 'Number') {
      const toProcessBTWord = this.state.BTWord.slice(0, -1);
      let keyInFormula = [];
      if (toProcessBTWord.length === 2 && this.state.Key2CheckMark === false) {
        keyInFormula = ['PA', 'PF'];
        this.setState({
          BTWord: toProcessBTWord,
          DisplayFormula: keyInFormula,
        });
      } else if (
        toProcessBTWord.length === 2 &&
        this.state.Key2CheckMark === true
      ) {
        keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
        this.setState({
          BTWord: toProcessBTWord,
          DisplayFormula: keyInFormula,
        });
      } else if (
        toProcessBTWord.length === 3 &&
        this.state.Key2CheckMark === false
      ) {
        keyInFormula = processAC(this.state.Formula.KeyIn1);
        this.setState({
          BTWord: toProcessBTWord,
          DisplayFormula: keyInFormula,
        });
      } else if (
        toProcessBTWord.length === 3 &&
        this.state.Key2CheckMark === true
      ) {
        keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
        this.setState({
          BTWord: toProcessBTWord,
          DisplayFormula: keyInFormula,
        });
      } else if (toProcessBTWord.length === 4) {
        this.setState({
          BTWord: toProcessBTWord,
          DisplayFormula: this.state.FourDFormula,
        });
      } else if (toProcessBTWord.length === 5) {
        this.setState({
          BTWord: toProcessBTWord,
          DisplayFormula: ['5D'],
        });
      } else if (toProcessBTWord.length === 6) {
        this.setState({
          BTWord: toProcessBTWord,
          DisplayFormula: ['6D'],
        });
      } else if (toProcessBTWord.length > 6) {
        alert(
          language[this.props.currentLanguage].MaxSixNum,
        );
      } else {
        this.setState({
          BTWord: toProcessBTWord,
          DisplayFormula: this.state.FourDFormula,
        });
      }
    } else if (this.state.SelectingInput === 'NumberRange') {
      const toProcessBTWord = this.state.BTWordRange.slice(0, -1);
      if (toProcessBTWord.length === 3) {
        this.setState({
          BTWordRange: toProcessBTWord,
        });
      } else if (toProcessBTWord.length === 4) {
        this.setState({
          BTWordRange: toProcessBTWord,
        });
      } else if (toProcessBTWord.length === 5) {
        this.setState({
          BTWordRange: toProcessBTWord,
        });
      } else if (toProcessBTWord.length === 6) {
        this.setState({
          BTWordRange: toProcessBTWord,
        });
      } else if (toProcessBTWord.length > 6) {
        alert(
          language[this.props.currentLanguage].MaxSixNum,
        );
      } else {
        this.setState({
          BTWordRange: toProcessBTWord,
        });
      }
    } else {
      const amountData = this.state.InputAmount;
      amountData[this.state.SelectedNum].Amount = amountData[
        this.state.SelectedNum
      ].Amount.slice(0, -1);
      this.setState({InputAmount: amountData});
    }
  }

  async setKeyboardVisible() {
    const CheckVisible = this.state.keyboardVisible;
    if (CheckVisible === true) {
      this.setState({keyboardVisible: false});
    } else {
      this.setState({keyboardVisible: true});
    }
  }

  onpressDot() {
    if (this.state.SelectingInput === 'Amount') {
      const amountData = this.state.InputAmount;
      const amountChecking = amountData[this.state.SelectedNum].Amount + '.';
      if (amountChecking === '.') {
        amountData[this.state.SelectedNum].Amount = amountChecking;
        this.setState({InputAmount: amountData});
      } else if (isNaN(amountChecking)) {
        alert(
          language[this.props.currentLanguage].OnlyNumber,
        );
      } else {
        amountData[this.state.SelectedNum].Amount = amountChecking;
        this.setState({InputAmount: amountData});
      }
    }
  }

  NameHandleText(value, field) {
    this.setState({ [field]: value });
  }

  async CheckUserBalance(SelectedUsername) {
    this.setState({loading: true});
    const getBalance = await getCashWalletAmt(
      SelectedUsername,
      this.props.username,
      this.props.token,
    );
    if (getBalance === 'TimeOut') {
      this.LogoutFunction();
    } else if (getBalance.CashWallet === '---') {
      this.setState({
        loading: false,
        userBalance: '---',
      });
    } else {
      this.setState({
        loading: false,
        userBalance: getBalance.CashWallet.toFixed(2),
      });
    }
  }
  
  render() {
    if (this.state.loading) {
      return (
        <div className="LoginPageContainer" style={{ justifyContent: 'center'}}>
          <img className= "loadingSize" src={LoadingLogo} alt="BigCo Inc. logo"/>
        </div>
      );
    } else {
      return (
        <div className='Bbody' style={{overflow: 'hidden'}}>
          <div className='box'>
            <div className='BT-HEAD'> 
              <div className='PageHeaderMainContainer' >
                <div className='PageHeaderLeftContainer'>
                  <div className='PageHeaderLeftBackButton'
                    onClick={() => this.props.history.goBack()}>
                    {language[this.props.currentLanguage].Back}
                  </div>
                </div>
                <div className='PageHeaderMiddleContainer'>
                  {language[this.props.currentLanguage].SimpleBT}
                </div>
                <div className='PageHeaderRightContainer'>
                  <button className='mainRightFunctionButton' style={{width: '80%'}} onClick={() =>this.onpressClearAll()}>
                    {language[this.props.currentLanguage].Clear}
                  </button>
                </div>
              </div>
              <div className='PageUserSelectnSendContainer'>
                <div className='SimpleBTContainer'>
                  <button className='SimpleBTDateInputButton'
                    onClick={() => this.setDateModalVisible(true)}>
                    <img className="SimpleBTDateIcon" src={CalendarIcon} alt="CalendarIcon"/>
                  </button>
                  <button className='SimpleBTSpecialSelectButton'
                    onClick={() => this.setSpecialModalVisible(true)}>
                    <div className='SimpleBTSpecialSelectText'>
                      {this.state.SelectedSpecial}
                    </div>
                    <div className='SimpleBTSpecialSelectText'
                      style={{width: '20%'}}>
                      ▼
                    </div>
                  </button>
                  <button className='SimpleBTUserSelectButton'
                    onClick={() => this.setUserModalVisible(true)}>
                    <div className='SimpleBTSpecialSelectText'>
                      {this.state.Username}
                    </div>
                    <div className='SimpleBTSpecialSelectText'
                      style={{width: '20%'}}>
                      ▼
                    </div>
                  </button>
                </div>
              </div>
              <div className='SimpleBTPlatformListContainer' style={{whiteSpace: 'pre-wrap'}}>
                {this.state.PlatFormList.map((item, idx) => {
                  if (item.PlatForm !== '-') {
                    return (
                      <button className='SimpleBTModalPlatformButtonContainer' 
                        key={idx}
                        onClick={() => this.PlatformCheckboxSelect(idx)}>
                        {item.CheckMark && (
                          <img className="SelectedPlatformListIcon" src={item.ImageUri} alt="PlatformLogo"/>
                        ) || ( <img className="SimpleBTPlatformListIcon" src={item.GreyImageUri} alt="PlatformLogo"/>)}
                      </button>
                    )
                  }
                })}
              </div>
              <div className='SimpleBTContentContainer'>
                {(this.state.SelectedSpecial === '{1234}' && (
                  <div style={{
                    display: 'flex',
                    flexWrap: 'wrap', 
                    whiteSpace: 'pre-wrap',
                    width: '40%', 
                    wordBreak: 'break-all',
                    // backgroundColor: 'red', 
                    marginTop: 'auto',
                    marginBottom: 'auto'}}>
                    <button className='SimpleBTNumberInputContainer' 
                      style={{
                        width: '45%',
                        color: this.state.BTWord === '' ? 'grey' : 'black', textAlign: 'center',
                        borderColor: this.state.SelectingInput === 'Number' ? 'orange' : 'black',}}
                      onClick={() => this.SelectingInputFunction('Number')}>
                      {this.state.BTWord === '' ? 'From' : this.state.BTWord}
                    </button>
                    <button className='SimpleBTNumberInputContainer' 
                      style={{
                        display: 'flex',
                        width: '45%',
                        whiteSpace: 'pre-wrap',
                        flexWrap: 'wrap', 
                        wordBreak: 'break-all',
                        color: this.state.BTWordRange === '' ? 'grey' : 'black', textAlign: 'center',
                        borderColor: this.state.SelectingInput === 'NumberRange' ? 'orange' : 'black',}}
                      onClick={() => this.SelectingInputFunction('NumberRange')}>
                      {this.state.BTWordRange === '' ? 'To' : this.state.BTWordRange}
                    </button>
                  </div>
                )) || (
                  <div style={{
                    display: 'flex',
                    width: '40%', 
                    // backgroundColor: 'red', 
                    wordBreak: 'break-all',
                    marginTop: 'auto',
                    marginBottom: 'auto'}}>
                    <button className='SimpleBTNumberInputContainer' 
                      style={{
                        color: this.state.BTWord === '' ? 'grey' : 'black', textAlign: 'center',
                        borderColor: this.state.SelectingInput === 'Number' ? 'orange' : 'black',}}
                      onClick={() => this.SelectingInputFunction('Number')}>
                      {this.state.BTWord === '' ? 'Number' : this.state.BTWord}
                    </button>
                  </div>
                )}
                <div style={{
                  display: 'flex', 
                  width: '60%', 
                  // backgroundColor: 'blue', 
                  flexWrap: 'wrap', 
                  whiteSpace: 'pre-wrap'}}>
                  {this.state.DisplayFormula.map((item, idx) => {
                    return (
                      <button
                        className='SimpleAmountPfDisplay'
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'break-all',
                          marginTop: 3, 
                          marginBottom: 3,
                          color:
                            this.state.InputAmount[idx].Amount === ''
                              ? 'grey'
                              : 'black',
                          borderColor:
                            this.state.SelectingInput === 'Amount' &&
                            this.state.SelectedNum === idx
                              ? 'orange'
                              : 'black',
                        }}
                        onClick={() => this.SelectingInputFunction('Amount', idx)}>
                        <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                          {this.state.InputAmount[idx].Amount === ''
                            ? item : this.state.InputAmount[idx].Amount}
                        </div>   
                      </button>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className='SimpleBTWordListHead'>
              <div className='SimpleBTWordListHeadText' style={{ marginLeft: 5 }}>
              Number: {this.state.TotalLine || 0}
              </div>
              <div className='SimpleBTAmountRight'>
                ${this.state.OverallAmount.toFixed(2)}
              </div>
            </div>
            <div className="NameContainer">
              <input
                className="NameInputTextBox"
                type="text"
                maxLength={20}
                value={this.state.nameInput}
                onChange={(e) => this.NameHandleText(e.target.value, 'nameInput')}
                ref={(input) => { this.nameInput = input; }}
                placeholder={language[this.props.currentLanguage].Remark}
              />
              <div className='BalanceText'>$ {this.state.userBalance}</div>
            </div>
            <div className='SpecialMultilineContainer' 
              style = {{
                borderWidth: 0.7,
                wordBreak: 'break-all',
              }}>
              <div className='SimpleBTWordContainer' 
                style={{ 
                  width: '98%' ,
                  alignSelf: 'center',
                  flexWrap: 'wrap', 
                  whiteSpace: 'pre-wrap',
                  fontWeight: 'bold',
                }}>
                {this.state.lineData[0].translatedWord}
              </div>  
            </div>
            <div className="BT-body">
              {this.state.lineData.slice().reverse().map((item, idx) =>{
                return (
                  <div className='SpecialMultilineContainer' 
                    style={{
                      // wordBreak: 'break-all',
                      // display: 'flex',
                      borderWidth:
                        item.translatedWord !== '' && item.DisplayPF !== '' ? 0.7 : 0,
                    }}
                    key={idx}
                    id={idx}>
                    {item.translatedWord !== '' && item.DisplayPF !== '' && (
                      <div style={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        width: '100%',
                      }}>
                        <div style={{
                          display: 'flex', 
                          flexDirection: 'row',
                          width: '18%', 
                          // backgroundColor: 'red',
                          flexWrap: 'wrap', 
                          whiteSpace: 'pre-wrap',
                          justifyContent: 'center',
                          marginTop:'auto',
                          marginBottom: 'auto',
                        }}>
                          {item.DisplayPF}
                        </div>
                        <div style={{
                          display: 'flex', 
                          flexDirection: 'column',
                          width: '72%', 
                          marginTop: 'auto',
                          marginBottom: 'auto',
                          // backgroundColor: 'blue'
                        }}>
                          {item.Mode !== '' && (
                            <div className='spBTSpecialWordButtonText' 
                              style={{width: '100%',}}>
                              Special: {item.Mode}
                            </div>
                          )}
                          <div
                            className='spWordSize' 
                            style={{
                              display: 'flex', 
                              flexDirection: 'row',
                              width: '100%', 
                              flexWrap: 'wrap', 
                              whiteSpace: 'pre-wrap',
                              fontWeight: 'bold',
                              // fontSize: 15,
                              // backgroundColor: 'blue'
                            }}>
                            {item.translatedWord}
                          </div>
                        </div>
                        <div style={{
                          display: 'flex', 
                          flexDirection: 'row',
                          width: '10%', 
                          // backgroundColor: 'orange'
                        }}>
                          <div className='SimpleBTWordContainer'
                            style={{
                              // backgroundColor: 'red',
                              paddingTop: 3,
                              paddingBottom: 3,
                              marginLeft: 'auto',
                              marginRight: 'auto',}}>
                            {item.CalculateLineAmount > 0 && (
                              <button className='SimpleBTRemoveWordBTN'
                                onClick={() => this.RemoveSelectedNumber(idx)}>
                                  ❌
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
            {this.state.keyboardVisible === true && (
              <div className='SIMPLEBTPageKeyboardContainer'>
                <div className="SimpleBTPressAbleContainer">
                  <button className='SimpleBTRowButton'
                    onClick={() => this.onpressDot()}>
                    {' '}
                    .{' '}
                  </button>
                  <button className='SimpleBTRowButton'
                    onClick={() => this.FindReverNum()}>
                    {' '}{language[this.props.currentLanguage].Reverse}{' '}
                  </button>
                  <button className='SimpleBTRowButton'
                    style={{
                      color: this.state.FixAmount ? 'orange' : 'black',
                    }}
                    onClick={() => this.setFixAmount()}>
                   FIX
                  </button>
                  <button className='SimpleBTRowButton'
                    style={{
                      color: this.state.Key2CheckMark ? 'orange' : 'black',
                    }}
                    onClick={() => this.setKey2CheckMark()}>
                    KEY2
                  </button>
                </div>
                <div className='SimpleBTNumpadMiddleContainer'>
                  <div className="SimpleBTNumpadContainer2">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, idx) => {
                      return (
                        <div className='SimpleBTNumPadButton' key={idx}>
                          <button  className='NumPadIconLabel' onClick={() => this.PressNumberFunction(item)}>
                            {item}
                          </button>
                        </div>
                      )
                    })}
                  </div>
                  <div className="SimpleBTNumpadContainer">
                    <button className='SimpleBTSecondNumPadIconLabel' onClick={() => this.onPressBackspace(0)}><img className = "BackSpaceIcon" src={BackSpaceIcon} alt="BigCo Inc. logo"/></button>
                    <button className='SimpleBTSecondNumPadIconLabel' style={{ color: 'red'}} onClick={() => this.SendButtonFunction()}> {language[this.props.currentLanguage].Buy}</button>
                    <button className="SimpleBTSecondNumPadIconLabel" onClick={() => this.setKeyboardVisible(!this.state.keyboardVisible)}>⌨</button>
                  </div>
                </div>
                <div className="PressAbleContainer">
                  <button className="SimpleBTfirstRowButton" onClick={() => this.leftArrowFunction()}>←</button>
                  <button className="SimpleBTfirstRowButton" onClick={() => this.PressNumberFunction(0)}>0</button>
                  <button className="SimpleBTfirstRowButton" style={{wordBreak: 'break-all' }} onClick={() => this.rightArrowFunction()}>→</button>
                  <button className="SimpleBTfirstRowButton"
                    onClick={() => this.AddNumberFunction()}>
                    <img className = "enterIcon" src={enterIcon} alt="BigCo Inc. logo"/>
                  </button>
                </div>
              </div>
            )}
            <Modal transparent={true}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.DateModalVisible}
              contentClassName="br23">
              <Modal.Body className='DateModalBody' style={{ padding: 10 }}>
                <div className='DateModalHeader'>
                  <div className='DateModalHeaderText'>D#</div>
                  <div className='DateModalHeaderText'>
                    <button className='DateModalCloseButton' onClick={() => this.setDateModalVisible(!this.state.DateModalVisible)}> 
                      {language[this.props.currentLanguage].Close}
                    </button>
                  </div>
                </div>
                {this.state.ModalDate.DateLoop.map((item, idx) => {
                  return (
                    <button className='dateListButton' 
                      key={idx}
                      onClick={() => this.SelectDateFunction(idx)}>
                      {item.Date} {item.CheckMark && (
                        <img className="CheckmarkIcon" src={CheckmarkIcon} alt="CheckmarkIcon"/>
                      )}
                    </button>
                  )
                })}
                <div className='dateListFooter'>
                  <button className='dateFooterButton'
                    onClick={() => this.onpressPeriod(2)}>
                    {language[this.props.currentLanguage].TwoPeriod}
                  </button>
                  <button className='dateFooterButton'
                    onClick={() => this.onpressPeriod(3)}>
                    {language[this.props.currentLanguage].ThreePeriod}
                  </button>
                  <button className='dateFooterButton'
                    onClick={() => this.onpressLongTerm()}>
                    {language[this.props.currentLanguage].Longterm}
                  </button>
                  <button className='dateFooterButton'
                    onClick={() => this.onPressConfirmSelectDate()}>
                    {language[this.props.currentLanguage].Confirm}
                  </button>
                </div>
              </Modal.Body>
            </Modal>
            <Modal transparent={true}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.UserModalVisible}
              contentClassName="br23">
              <Modal.Body className='DateModalBody' style={{ padding: 10}}>
                <div className='DateModalHeader'>
                  <div className='DateModalHeaderText'>
                    {language[this.props.currentLanguage].User}
                  </div>
                  <div className='DateModalHeaderText'>
                    <button className='DateModalCloseButton' onClick={() =>  this.setUserModalVisible(!this.state.UserModalVisible)}> 
                      {language[this.props.currentLanguage].Close}
                    </button>
                  </div>
                </div>
                {this.props.UserAndDownlineData.map((item, idx) => {
                  return (
                    <button className='dateListButton' 
                      key={idx}
                      onClick={() => this.SelectUserModal(item.ID, item.Username, idx)}>
                      {item.Username}
                    </button>
                  )
                })}
              </Modal.Body>
            </Modal>
            <Modal transparent={true}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.SpecialModalVisible}
              contentClassName="br23">
              <Modal.Body className='DateModalBody' style={{ padding: 10 }}>
                <div className='DateModalHeader'>
                  <div className='DateModalHeaderText'>
                    {language[this.props.currentLanguage].Special}
                  </div>
                  <div className='DateModalHeaderText'>
                    <button className='DateModalCloseButton' 
                      onClick={() =>  this.setSpecialModalVisible(!this.state.SpecialModalVisible)}> 
                      {language[this.props.currentLanguage].Close}
                    </button>
                  </div>
                </div>
                {this.state.Special.map((item, idx) => {
                  return (
                    <button className='dateListButton' 
                      key={idx}
                      onClick={() => this.SelectSpecialFunction(item)}>
                      {item}
                    </button>
                  )
                })}
              </Modal.Body>
            </Modal>
            {/* </div>  */}
          </div>
        </div> 
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    id: state.id,
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    checkResultdate: state.checkResultdate,
    checkResultPlatForm: state.checkResultPlatForm,
    currentLanguage: state.currentLanguage,
    ReBuyData: state.ReBuyData,
    ReBuyUserID: state.ReBuyUserID,
    ReBuyUser: state.ReBuyUser,
    UserAndDownlineData: state.UserAndDownlineData,
    SelectedUserKeyFormula: state.SelectedUserKeyFormula,
    OneStarBao: state.OneStarBao,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setReceiptData: (ReceiptData, BuyMode) => {
      dispatch({
        type: 'RECEIPTDATA_FOR_PASS',
        payload: {
          ReceiptData: ReceiptData,
          BuyMode: BuyMode,
        },
      });
    },
    SetRebuyData: (SelectedData, SelectedUserID, SelectedUser) => {
      dispatch({
        type: 'REBUYDATA_FOR_PASS',
        payload: {
          ReBuyData: SelectedData,
          ReBuyUserID: SelectedUserID,
          ReBuyUser: SelectedUser,
        },
      });
    },
    setLoginStatus: (loginStatus, ID, Username, UserToken, role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          login: loginStatus,
          id: ID,
          username: Username,
          token: UserToken,
          role: role,
        },
      });
    },
    setUserDownlineData: UserAndDownlineData => {
      dispatch({
        type: 'USER_DOWNLINE_DATA',
        payload: {
          UserAndDownlineData: UserAndDownlineData,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SimpleBT);
  