import '../App.css';
// import JSEncrypt from 'jsencrypt';
import React from 'react';
import { connect } from 'react-redux';
import {language} from '../language';
import JSEncrypt from 'jsencrypt';
import LoadingLogo from '../img/loading.gif';
const { chgPassword, getPublicKey } = require('../Api');

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      password: '',
      newPassowrd: '',
      reNewPassword: '',
      hidePass: true,
      hideNewPass: true,
      hideReNewPass: true,
    }
  }

  async componentDidMount() {
    if (this.props.UserAndDownlineData === '') {
      this.props.history.push('/')
    }
    this.PasswordInput.focus()
  }

  async ConfirmChangePassword() {
    this.setState({loading: true});
    const password = this.state.password
    const newPassowrd = this.state.newPassowrd
    const reNewPassword = this.state.reNewPassword

    if(password === '') {
      alert(language[this.props.currentLanguage].EmptyPW);
      this.setState({loading: false});
    } else if (newPassowrd === '') {
      alert(language[this.props.currentLanguage].EmptyNewPW);
      this.setState({loading: false});
    } else if (reNewPassword === '') {
      alert(language[this.props.currentLanguage].EmptyReNewPW);
      this.setState({loading: false});
    } else if (newPassowrd !== reNewPassword) {
      alert(language[this.props.currentLanguage].newPassDif);
      this.setState({loading: false});
    } else {
      const PublicKey = await getPublicKey();
      if (PublicKey === 'publicKeyError') {
        this.setState({loading: false});
        alert('publicKeyError');
      } else if (PublicKey === 'TimeOut') {
        this.LogoutFunction();
      } else {
        const jsEncrypt = new JSEncrypt();
        jsEncrypt.setPublicKey(PublicKey.publicKey);
        const EnPassword = jsEncrypt.encrypt(password);
        const NewEnPassword = jsEncrypt.encrypt(newPassowrd);
        const chgPassResult = await chgPassword(
          this.props.id,
          EnPassword,
          NewEnPassword,
          this.props.username,
          this.props.token,
        );
        if(chgPassResult.message === 'Password Changed Successfully') {
          this.setState({
            loading: false,
            password: '',
            newPassowrd: '',
            reNewPassword: '',
          });
          alert(
            language[this.props.currentLanguage].doneChgPass,
          );
          this.props.setLoginStatus(
            true,
            this.props.id,
            this.props.username,
            this.props.token,
            this.props.role,
            1,
          );
          localStorage.setItem('CacheUsername', this.props.username);
          sessionStorage.setItem('id', this.props.id);
          sessionStorage.setItem('token',  this.props.token);
          sessionStorage.setItem('userRole',  this.props.role);
          sessionStorage.setItem('FirstTimeLogin',  1);
          sessionStorage.setItem('loginStatus',  true);
          this.props.history.push('/');
        } else if (chgPassResult.error === 'Invalid Current Password') {
          this.setState({loading: false});
          alert(
            language[this.props.currentLanguage].currentPassWrong,
          );
        } else if (chgPassResult === 'TimeOut') {
          this.LogoutFunction();
        } else if (chgPassResult.error === 'Current Password Match With New Password!') {
          this.setState({loading: false});
          alert(
            language[this.props.currentLanguage].CurrentAndNewSamePass,
          );
        } else if (chgPassResult.error === 'Mandarin Not Allow') {
          this.setState({loading: false});
          alert(
            language[this.props.currentLanguage].noMandarin,
          );
        } else if (chgPassResult.error) {
          this.setState({loading: false});
          alert(
            chgPassResult.error,
          );
        } else {
          this.setState({
            loading: false,
          });
        }
      }  
    }
  }

  handleChange(Password, Mode){
    this.setState({ [Mode]: Password })
  }

  setHidePass() {
    const hidePass = this.state.hidePass
    if(hidePass === true) {
      this.setState({ hidePass: false })
    } else {
      this.setState({ hidePass: true })
    }
  }

  setHideNewPass() {
    const hidePass = this.state.hideNewPass
    if(hidePass === true) {
      this.setState({ hideNewPass: false })
    } else {
      this.setState({ hideNewPass: true })
    }
  }

  setHideReNewPass() {
    const hidePass = this.state.hideReNewPass
    if(hidePass === true) {
      this.setState({ hideReNewPass: false })
    } else {
      this.setState({ hideReNewPass: true })
    }
  }

  async LogoutFunction() {
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('FirstTimeLogin');
    sessionStorage.removeItem('Username');
    sessionStorage.removeItem('AbleRed');
    sessionStorage.removeItem('loginStatus');
    sessionStorage.removeItem('getUserDownlineData');
    sessionStorage.removeItem('ReceiptData');
    sessionStorage.removeItem('UserIDForSearch');
    sessionStorage.removeItem('UserForSearch');
    sessionStorage.removeItem('SearchDateFrom');
    sessionStorage.removeItem('SearchDateTo');
    sessionStorage.removeItem('PatternForSearch');
    sessionStorage.removeItem('PlatformForSearch');
    sessionStorage.removeItem('ReportData');
    this.props.setLoginStatus(false, '', '', '', '');
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="LoginPageContainer" style={{ justifyContent: 'center'}}>
          <img class = "loadingSize" src={LoadingLogo} alt="BigCo Inc. logo"/>
        </div>
      );
    } else {
      return (
        <body>
          <div className='PageHeaderMainContainer'>
            <div className='PageHeaderLeftContainer'>
              {this.props.firstTimeLog === 1 && (
                <div className='PageHeaderLeftBackButton'
                  onClick={() => this.props.history.goBack()}>
                  {language[this.props.currentLanguage].Back}
                </div>
              )}
            </div>
            <div className='PageHeaderMiddleContainer'>
              {language[this.props.currentLanguage].ChangePassword}
            </div>
            <div className='PageHeaderRightContainer'>
            </div>
          </div>
          <div className='ChangePwPageContainer'>
            {this.props.firstTimeLog === 0 && (
              <div style={{
                width: '98%',
                alignSelf: 'center',
                textAlign: 'center',
                fontSize: 20,
                paddingTop: 5,
                paddingBottom: 5,
                marginTop: 15,
                fontWeight: 'bold',
              }}>
                {language[this.props.currentLanguage].firsTimeLog}
              </div>
            )}
            <div className="ChangePasswordContainer">
              <input
                className="PasswordInputTextBox"
                type={this.state.hidePass ? 'Password' : 'text'}
                value={this.state.password}
                onChange={(e) => this.handleChange(e.target.value, 'password')}
                placeholder={language[this.props.currentLanguage].currentPass}
                ref={(input) => { this.PasswordInput = input; }}
              />
              <button className='showhideButton' onClick={() => this.setHidePass(!this.state.hidePass)}>
                <div className="showHidepwText">
                  {this.state.hidePass
                    ? language[this.props.currentLanguage].Show
                    : language[this.props.currentLanguage].Hide}
                </div>
              </button>
            </div>
            <div className="ChangePasswordContainer">
              <input
                className="PasswordInputTextBox"
                type={this.state.hideNewPass ? 'Password' : 'text'}
                value={this.state.newPassowrd}
                onChange={(e) => this.handleChange(e.target.value, 'newPassowrd')}
                placeholder={language[this.props.currentLanguage].NewPass}
                ref={(input) => { this.newPassowrdInput = input; }}
              />
              <button className='showhideButton' onClick={() => this.setHideNewPass(!this.state.hideNewPass)}>
                <div className="showHidepwText">
                  {this.state.hideNewPass
                    ? language[this.props.currentLanguage].Show
                    : language[this.props.currentLanguage].Hide}
                </div>
              </button>
            </div>
            <div className="ChangePasswordContainer">
              <input
                className="PasswordInputTextBox"
                type={this.state.hideReNewPass ? 'Password' : 'text'}
                value={this.state.reNewPassword}
                onChange={(e) => this.handleChange(e.target.value, 'reNewPassword')}
                placeholder={language[this.props.currentLanguage].ReNewPass}
                ref={(input) => { this.reNewPasswordInput = input; }}
              />
              <button className='showhideButton' onClick={() => this.setHideReNewPass(!this.state.hideReNewPass)}>
                <div className="showHidepwText">
                  {this.state.hideReNewPass
                    ? language[this.props.currentLanguage].Show
                    : language[this.props.currentLanguage].Hide}
                </div>
              </button>
            </div>
            <button className="LoginButton"  onClick={()=> this.ConfirmChangePassword()}>
              <div className="LoginButtonText">
                {language[this.props.currentLanguage].Change}
              </div>
            </button>
            {this.props.firstTimeLog === 0 && (
              <button className="LoginButton"
                style={{ 
                  backgroundColor: '#f54278', 
                  borderColor: 'red',
                  marginTop: 20,}}
                onClick={()=> this.LogoutFunction()}>
                <div className="LoginButtonText">
                  {language[this.props.currentLanguage].Logout}
                </div>
              </button>
            )}
      
          </div>
        </body> 
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    id: state.id,
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
    firstTimeLog: state.firstTimeLog,
    UserAndDownlineData: state.UserAndDownlineData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserDownlineData: UserAndDownlineData => {
      dispatch({
        type: 'USER_DOWNLINE_DATA',
        payload: {
          UserAndDownlineData: UserAndDownlineData,
        },
      });
    },
    setLanguage: lg => {
      dispatch({type: 'SET_LANGUAGE', payload: lg});
    },
    setLoginStatus: (
      loginStatus,
      ID,
      Username,
      UserToken,
      role,
      firstTimeLog,
    ) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          login: loginStatus,
          id: ID,
          username: Username,
          token: UserToken,
          role: role,
          firstTimeLog: firstTimeLog,
        },
      });
    },
  
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
  