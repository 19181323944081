import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import {language} from '../language';
import LoadingLogo from '../img/loading.gif';
import moment from 'moment-timezone';
const { getBetReportMobile } = require('../Api');

class displayReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      date: new Date(),
      DisplayReportData: '',
      DisplayReportTongData: '',
      todayDate: moment().format('YYYY-MM-DD'),
      reportStatus: '',
    }
  }

  async LogoutFunction() {
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('AbleRed');
    sessionStorage.removeItem('FirstTimeLogin');
    sessionStorage.removeItem('Username');
    sessionStorage.removeItem('loginStatus');
    sessionStorage.removeItem('getUserDownlineData');
    sessionStorage.removeItem('ReceiptData');
    sessionStorage.removeItem('UserIDForSearch');
    sessionStorage.removeItem('UserForSearch');
    sessionStorage.removeItem('SearchDateFrom');
    sessionStorage.removeItem('SearchDateTo');
    sessionStorage.removeItem('PatternForSearch');
    sessionStorage.removeItem('PlatformForSearch');
    sessionStorage.removeItem('ReportData');
    this.props.setLoginStatus(false, '', '', '', '');
  }

  async componentDidMount() {
    if (this.props.UserAndDownlineData === '') {
      this.props.history.push('/')
    }
    const Downline = Object.keys(this.props.ReportData.userDetails);
    if (this.props.ReportData.message === 'No Data' || Downline.length <= 0) {
      if (this.props.redModeToSearch === 'sgd') {
        this.ProcessSGDSelfBet();
      } else if (this.props.redModeToSearch === 'thai') {
        this.ProcessThaiSelfBet();
      } else {
        this.processNormalSelfBet();
      }
    } else {
      if (this.props.redModeToSearch === 'sgd') {
        this.ProcessSGD();
      } else if (this.props.redModeToSearch === 'thai') {
        this.ProcessTHAI();
      } else {
        this.processNormal();
      }
    }
  }

  async processNormalSelfBet() {
    let MBetData = [];
    const getBetReport = await getBetReportMobile(
      this.props.username,
      this.props.SearchDateFrom,
      this.props.SearchDateTo,
      this.props.UserIDForSearch,
      this.props.redModeToSearch,
      this.props.token,
      this.props.PlatformListToSearch,
    );
    if (getBetReport === 'TimeOut') {
      this.LogoutFunction();
    }
    const getDate = Object.keys(getBetReport);
    for (let y = 0; y < getDate.length; y += 1) {
      for (let i = 0; i < getBetReport[getDate[y]].length; i += 1) {
        MBetData.push([
          getBetReport[getDate[y]][i].Username +
            moment(getDate[y]).format('(DD/MM)') +
            '\n' +
            language[this.props.currentLanguage].SelfBet +
            ': ' +
            getBetReport[getDate[y]][i].SelfBet +
            '\n' +
            language[this.props.currentLanguage].DownlinBet +
            ': ' +
            getBetReport[getDate[y]][i].DownlineBet +
            '\n' +
            language[this.props.currentLanguage].TotalBet +
            ': ' +
            getBetReport[getDate[y]][i].TotalBet +
            '\n\n',
        ]);
      }
    }
    this.setState({
      // DisplayReportData: 'NO DATA',
      DisplayReportData: MBetData,
      loading: false,
    });
  }

  async ProcessSGDSelfBet() {
    let MBetData = [];
    const getBetReport = await getBetReportMobile(
      this.props.username,
      this.props.SearchDateFrom,
      this.props.SearchDateTo,
      this.props.UserIDForSearch,
      this.props.redModeToSearch,
      this.props.token,
      this.props.PlatformListToSearch,
    );
    if (getBetReport === 'TimeOut') {
      this.LogoutFunction();
    }
    const getDate = Object.keys(getBetReport);
    for (let y = 0; y < getDate.length; y += 1) {
      for (let i = 0; i < getBetReport[getDate[y]].length; i += 1) {
        MBetData.push([
          getBetReport[getDate[y]][i].Username +
            moment(getDate[y]).format('(DD/MM)') +
            '\n' +
            language[this.props.currentLanguage].SelfBet +
            ': ' +
            getBetReport[getDate[y]][i].SelfBet +
            '(' +
            getBetReport[getDate[y]][i].sgdSelfBet +
            ')' +
            '\n' +
            language[this.props.currentLanguage].DownlinBet +
            ': ' +
            getBetReport[getDate[y]][i].DownlineBet +
            '(' +
            getBetReport[getDate[y]][i].sgdDownlineBet +
            ')' +
            '\n' +
            language[this.props.currentLanguage].TotalBet +
            ': ' +
            getBetReport[getDate[y]][i].TotalBet +
            '(' +
            getBetReport[getDate[y]][i].sgdTotalBet +
            ')' +
            '\n\n',
        ]);
      }
    }
    this.setState({
      // DisplayReportData: 'NO DATA',
      DisplayReportData: MBetData,
      loading: false,
    });
  }

  async ProcessThaiSelfBet() {
    let MBetData = [];
    const getBetReport = await getBetReportMobile(
      this.props.username,
      this.props.SearchDateFrom,
      this.props.SearchDateTo,
      this.props.UserIDForSearch,
      this.props.redModeToSearch,
      this.props.token,
      this.props.PlatformListToSearch,
    );
    if (getBetReport === 'TimeOut') {
      this.LogoutFunction();
    }
    const getDate = Object.keys(getBetReport);
    for (let y = 0; y < getDate.length; y += 1) {
      for (let i = 0; i < getBetReport[getDate[y]].length; i += 1) {
        MBetData.push([
          getBetReport[getDate[y]][i].Username +
            moment(getDate[y]).format('(DD/MM)') +
            '\n' +
            language[this.props.currentLanguage].SelfBet +
            ': ' +
            getBetReport[getDate[y]][i].SelfBet +
            '(' +
            getBetReport[getDate[y]][i].thaiSelfBet +
            ')' +
            '\n' +
            language[this.props.currentLanguage].DownlinBet +
            ': ' +
            getBetReport[getDate[y]][i].DownlineBet +
            '(' +
            getBetReport[getDate[y]][i].thaiDownlineBet +
            ')' +
            '\n' +
            language[this.props.currentLanguage].TotalBet +
            ': ' +
            getBetReport[getDate[y]][i].TotalBet +
            '(' +
            getBetReport[getDate[y]][i].thaiTotalBet +
            ')' +
            '\n\n',
        ]);
      }
    }
    this.setState({
      // DisplayReportData: 'NO DATA',
      DisplayReportData: MBetData,
      loading: false,
    });
  }

  async processNormal() {
    let status = '';
    if (this.props.ReportData.completed === true) {
      status = language[this.props.currentLanguage].CompleteReport + '\n';
    } else {
      status = language[this.props.currentLanguage].unCompleteReport + '\n';
    }
    const tongReport = this.props.ReportData.tongDetails || '';
    let displayTong = [];
    const Downline = Object.keys(this.props.ReportData.userDetails);
    let ReportData = [];
    let position = '';
    let displayPlatform = '';
    let downlineWinpostion = '';
    let dowonlineDisplayPlatform = '';
    let TotalN = 0;
    Downline.forEach(item => {
      let winWordString = [];
      let DownlineWinWordString = [];
      const userID = this.props.ReportData.userDetails[item].UserID;
      const winWordData = this.props.ReportData.winObject[userID] || '';
      const SelfWin = winWordData.self.sort((a, b) => b.UserOrderID - a.UserOrderID);
      const DownlineWin = winWordData.downline;
      const DownlinWinUserID = Object.keys(DownlineWin);
      if (SelfWin.length > 0) {
        let currentUserOrderID = '';
        let currentGamePosition = '';
        for (let j = 0; j < SelfWin.length; j += 1) {
          displayPlatform = SelfWin[j].Platform.replace(/1/g, 'M')
            .replace(
              /2/g,
              this.props.UserAndDownlineData[0].GameDisplay === 'MPTSWBK'
                ? 'P'
                : 'K',
            )
            .replace(/3/g, 'T')
            .replace(/4/g, 'S')
            .replace(/5/g, 'W')
            .replace(/6/g, 'B')
            .replace(
              /7/g,
              this.props.UserAndDownlineData[0].GameDisplay === 'MPTSWBK'
                ? 'K'
                : 'D',
            );
          if (SelfWin[j].Position === 'P1') {
            position = '1st';
          } else if (SelfWin[j].Position === 'P2') {
            position = '2nd';
          } else if (SelfWin[j].Position === 'P3') {
            position = '3rd';
          } else if (SelfWin[j].Position >= 1 && SelfWin[j].Position <= 13) {
            position = '4th';
          } else if (SelfWin[j].Position >= 14 && SelfWin[j].Position <= 23) {
            position = '5th';
          } else {
            position = SelfWin[j].Position;
          }
          const SideName = SelfWin[j].SideName || '';
          if (currentUserOrderID === SelfWin[j].UserOrderID && currentGamePosition === `${displayPlatform}/${position}`) {
            winWordString.push([
              '\n' +
              SelfWin[j].Word +
                ' ' +
                SelfWin[j].BetMode +
                '$' +
                SelfWin[j].Bet +
                '=' +
                SelfWin[j].Strike.toFixed(2),
            ]);
          } else if (currentUserOrderID === SelfWin[j].UserOrderID) {
            winWordString.push([
              '\n' +
              displayPlatform +
                '/' +
                position +
                ' ' +
                SideName +
                '\n' +
                SelfWin[j].Word +
                ' ' +
                SelfWin[j].BetMode +
                '$' +
                SelfWin[j].Bet +
                '=' +
                SelfWin[j].Strike.toFixed(2),
            ]);
            currentGamePosition = `${displayPlatform}/${position}`;
          } else {
            winWordString.push([
              '\n' +
                '#' +
                SelfWin[j].UserOrderID +
                '\n' +
                displayPlatform +
                '/' +
                position +
                ' ' +
                SideName +
                '\n' +
                SelfWin[j].Word +
                ' ' +
                SelfWin[j].BetMode +
                '$' +
                SelfWin[j].Bet +
                '=' +
                SelfWin[j].Strike.toFixed(2),
            ]);
            currentUserOrderID = SelfWin[j].UserOrderID;
            currentGamePosition = `${displayPlatform}/${position}`;
          }
        }
      }
      if (DownlinWinUserID.length > 0) {
        DownlinWinUserID.forEach(dItem => {
          DownlineWinWordString.push(['\n' + DownlineWin[dItem][0].Username]);
          const downlineWinWordArray = DownlineWin[dItem].sort((a, b) => b.UserOrderID - a.UserOrderID);
          let currentUserOrderID = '';
          let currentGamePosition = '';
          for (let j = 0; j < downlineWinWordArray.length; j += 1) {
            dowonlineDisplayPlatform = downlineWinWordArray[j].Platform.replace(
              /1/g,
              'M',
            )
              .replace(
                /2/g,
                this.props.UserAndDownlineData[0].GameDisplay === 'MPTSWBK'
                  ? 'P'
                  : 'K',
              )
              .replace(/3/g, 'T')
              .replace(/4/g, 'S')
              .replace(/5/g, 'W')
              .replace(/6/g, 'B')
              .replace(
                /7/g,
                this.props.UserAndDownlineData[0].GameDisplay === 'MPTSWBK'
                  ? 'K'
                  : 'D',
              );
            if (downlineWinWordArray[j].Position === 'P1') {
              downlineWinpostion = '1st';
            } else if (downlineWinWordArray[j].Position === 'P2') {
              downlineWinpostion = '2nd';
            } else if (downlineWinWordArray[j].Position === 'P3') {
              downlineWinpostion = '3rd';
            } else if (
              downlineWinWordArray[j].Position >= 1 &&
              downlineWinWordArray[j].Position <= 13
            ) {
              downlineWinpostion = '4th';
            } else if (
              downlineWinWordArray[j].Position >= 14 &&
              downlineWinWordArray[j].Position <= 23
            ) {
              downlineWinpostion = '5th';
            } else {
              downlineWinpostion = downlineWinWordArray[j].Position;
            }
            const downlineSideName = downlineWinWordArray[j].SideName || '';
            if (currentUserOrderID === downlineWinWordArray[j].UserOrderID && currentGamePosition === `${dowonlineDisplayPlatform}/${downlineWinpostion}`) {
              DownlineWinWordString.push([
                '\n' +
                  downlineWinWordArray[j].Word +
                  ' ' +
                  downlineWinWordArray[j].BetMode +
                  '$' +
                  downlineWinWordArray[j].Bet +
                  '=' +
                  downlineWinWordArray[j].Strike.toFixed(2),
              ]);
            } else if (currentUserOrderID === downlineWinWordArray[j].UserOrderID) {
              DownlineWinWordString.push([
                '\n' +
                  dowonlineDisplayPlatform +
                  '/' +
                  downlineWinpostion +
                  ' ' +
                  downlineSideName +
                  '\n' +
                  downlineWinWordArray[j].Word +
                  ' ' +
                  downlineWinWordArray[j].BetMode +
                  '$' +
                  downlineWinWordArray[j].Bet +
                  '=' +
                  downlineWinWordArray[j].Strike.toFixed(2),
              ]);
              currentGamePosition = `${dowonlineDisplayPlatform}/${downlineWinpostion}`;
            } else {
              DownlineWinWordString.push([
                '\n' +
                  '#' +
                  downlineWinWordArray[j].UserOrderID +
                  '\n' +
                  dowonlineDisplayPlatform +
                  '/' +
                  downlineWinpostion +
                  ' ' +
                  downlineSideName +
                  '\n' +
                  downlineWinWordArray[j].Word +
                  ' ' +
                  downlineWinWordArray[j].BetMode +
                  '$' +
                  downlineWinWordArray[j].Bet +
                  '=' +
                  downlineWinWordArray[j].Strike.toFixed(2),
              ]);
              currentUserOrderID = downlineWinWordArray[j].UserOrderID;
              currentGamePosition = `${dowonlineDisplayPlatform}/${downlineWinpostion}`;
            }
          }
        });
      }
      if (
        this.props.ReportData.userDetails[item].Username === this.props.username
      ) {
        ReportData.push([
          `${this.props.ReportData.userDetails[item].Username} (${moment(
            this.props.SearchDateFrom,
          ).format('DD/MM')}-${moment(this.props.SearchDateTo).format(
            'DD/MM',
          )})` +
            '\n=Summary=' +
            '\n' +
            language[this.props.currentLanguage].SelfBet +
            ': ' +
            this.props.ReportData.userDetails[item].SelfBet +
            '\n' +
            language[this.props.currentLanguage].SelfWater +
            ': ' +
            this.props.ReportData.userDetails[item].SelfWater +
            '\n' +
            language[this.props.currentLanguage].SelfWin +
            ': ' +
            this.props.ReportData.userDetails[item].SelfWin +
            '\n' +
            language[this.props.currentLanguage].SelfProfit +
            ': ' +
            this.props.ReportData.userDetails[item].SelfProfit +
            '\n\n' +
            language[this.props.currentLanguage].TotalBet +
            ': ' +
            this.props.ReportData.userDetails[item].TotalBet +
            '\n' +
            language[this.props.currentLanguage].TotalWater +
            ': ' +
            this.props.ReportData.userDetails[item].TotalWater +
          '\n' +
            language[this.props.currentLanguage].TotalWin +
            ': ' +
            this.props.ReportData.userDetails[item].TotalWin +
            '\n\n' +
            language[this.props.currentLanguage].SharesProfit +
            ': ' +
            this.props.ReportData.userDetails[item].SharesProfit +
            '\n\n' +
            language[this.props.currentLanguage].UplineProfit +
            ': ' +
            this.props.ReportData.userDetails[item].UplineProfit +
            '\n\n' +
            language[this.props.currentLanguage].WaterEarn +
            ': ' +
            this.props.ReportData.userDetails[item].WaterProfit +
            '\n' +
            language[this.props.currentLanguage].PrizeEarn +
            ': ' +
            this.props.ReportData.userDetails[item].WinProfit +
            winWordString.join(' ') +
            DownlineWinWordString.join('') +
            '\n\n=====================\n\n',
        ]);
      } else {
        ReportData.push([
          `${this.props.ReportData.userDetails[item].Username} (${moment(
            this.props.SearchDateFrom,
          ).format('DD/MM')}-${moment(this.props.SearchDateTo).format(
            'DD/MM',
          )})` +
            '\n=Summary=' +
            '\n' +
            language[this.props.currentLanguage].TotalBet +
            ': ' +
            this.props.ReportData.userDetails[item].TotalBet +
            '\n' +
            language[this.props.currentLanguage].TotalWater +
            ': ' +
            this.props.ReportData.userDetails[item].TotalWater +
            '\n' +
            language[this.props.currentLanguage].TotalWin +
            ': ' +
            this.props.ReportData.userDetails[item].TotalWin +
            '\n\n' +
            language[this.props.currentLanguage].SharesProfit +
            ': ' +
            this.props.ReportData.userDetails[item].SharesProfit +
            '\n\n' +
            language[this.props.currentLanguage].UplineProfit +
            ': ' +
            this.props.ReportData.userDetails[item].UplineProfit +
            '\n\n' +
            language[this.props.currentLanguage].WaterEarn +
            ': ' +
            this.props.ReportData.userDetails[item].WaterProfit +
            '\n' +
            language[this.props.currentLanguage].PrizeEarn +
            ': ' +
            this.props.ReportData.userDetails[item].WinProfit +
            winWordString.join(' ') +
            DownlineWinWordString.join('') +
            '\n\n=====================\n\n',
        ]);
      }
      // TotalN += Number(this.props.ReportData.userDetails[item].TotalProfit);
    });
    if (tongReport !== '' && tongReport.length >= 1) {
      // displayTong.push([]);
      displayTong.push(
        '====' + language[this.props.currentLanguage].TongRepot + '====\n',
      );
      for (let a = 0; a < tongReport.length; a++) {
        let tongLai = '';
        let tongNet = '';
        // displayTong.push([tongReport[a].username].toString());
        for (let b = 0; b < tongReport[a].tongArray.length; b++) {
          tongLai = Number(tongReport[a].tongArray[b].lai) + Number(tongLai);
          tongNet = Number(tongReport[a].tongArray[b].net) + Number(tongNet);
        }
        displayTong.push(
          tongReport[a].username +
            '\n' +
            language[this.props.currentLanguage].Zong +
            language[this.props.currentLanguage].LaiLanguage +
            ': ' +
            tongLai +
            '\n' +
            language[this.props.currentLanguage].Zong +
            'Net: ' +
            tongNet +
            '\n\n',
        );
      }
    }
    // ReportData.push('TOTAL: ' + TotalN.toFixed(2) + '\n=============');
    this.setState({
      reportStatus: status,
      DisplayReportData: ReportData,
      DisplayReportTongData: displayTong,
      loading: false,
    });
  }

  async ProcessSGD() {
    let status = '';
    if (this.props.ReportData.completed === true) {
      status = language[this.props.currentLanguage].CompleteReport + '\n';
    } else {
      status = language[this.props.currentLanguage].unCompleteReport + '\n';
    }
    const tongReport = this.props.ReportData.tongDetails || '';
    let displayTong = [];
    const Downline = Object.keys(this.props.ReportData.userDetails);
    let ReportData = [];
    let position = '';
    let displayPlatform = '';
    let downlineWinpostion = '';
    let dowonlineDisplayPlatform = '';
    let TotalN = 0;
    Downline.forEach(item => {
      let winWordString = [];
      let DownlineWinWordString = [];
      const userID = this.props.ReportData.userDetails[item].UserID;
      const winWordData = this.props.ReportData.winObject[userID] || '';
      const SelfWin = winWordData.self.sort((a, b) => b.UserOrderID - a.UserOrderID);
      const DownlineWin = winWordData.downline;
      const DownlinWinUserID = Object.keys(DownlineWin);
      if (SelfWin.length > 0) {
        let currentUserOrderID = '';
        let currentGamePosition = '';
        for (let j = 0; j < SelfWin.length; j += 1) {
          displayPlatform = SelfWin[j].Platform.replace(/1/g, 'M')
            .replace(
              /2/g,
              this.props.UserAndDownlineData[0].GameDisplay === 'MPTSWBK'
                ? 'P'
                : 'K',
            )
            .replace(/3/g, 'T')
            .replace(/4/g, 'S')
            .replace(/5/g, 'W')
            .replace(/6/g, 'B')
            .replace(
              /7/g,
              this.props.UserAndDownlineData[0].GameDisplay === 'MPTSWBK'
                ? 'K'
                : 'D',
            );
          if (SelfWin[j].Position === 'P1') {
            position = '1st';
          } else if (SelfWin[j].Position === 'P2') {
            position = '2nd';
          } else if (SelfWin[j].Position === 'P3') {
            position = '3rd';
          } else if (SelfWin[j].Position >= 1 && SelfWin[j].Position <= 13) {
            position = '4th';
          } else if (SelfWin[j].Position >= 14 && SelfWin[j].Position <= 23) {
            position = '5th';
          } else {
            position = SelfWin[j].Position;
          }
          const SideName = SelfWin[j].SideName || '';
          if (currentUserOrderID === SelfWin[j].UserOrderID && currentGamePosition === `${displayPlatform}/${position}`) {
            winWordString.push([
              '\n' +
              SelfWin[j].Word +
                ' ' +
                SelfWin[j].BetMode +
                '$' +
                SelfWin[j].Bet +
                '=' +
                SelfWin[j].Strike.toFixed(2),
            ]);
          } else if (currentUserOrderID === SelfWin[j].UserOrderID) {
            winWordString.push([
              '\n' +
              displayPlatform +
                '/' +
                position +
                ' ' +
                SideName +
                '\n' +
                SelfWin[j].Word +
                ' ' +
                SelfWin[j].BetMode +
                '$' +
                SelfWin[j].Bet +
                '=' +
                SelfWin[j].Strike.toFixed(2),
            ]);
            currentGamePosition = `${displayPlatform}/${position}`;
          } else {
            winWordString.push([
              '\n' +
                '#' +
                SelfWin[j].UserOrderID +
                '\n' +
                displayPlatform +
                '/' +
                position +
                ' ' +
                SideName +
                '\n' +
                SelfWin[j].Word +
                ' ' +
                SelfWin[j].BetMode +
                '$' +
                SelfWin[j].Bet +
                '=' +
                SelfWin[j].Strike.toFixed(2),
            ]);
            currentUserOrderID = SelfWin[j].UserOrderID;
            currentGamePosition = `${displayPlatform}/${position}`;
          }
        }
      }
      if (DownlinWinUserID.length > 0) {
        DownlinWinUserID.forEach(dItem => {
          DownlineWinWordString.push(['\n' + DownlineWin[dItem][0].Username]);
          const downlineWinWordArray = DownlineWin[dItem].sort((a, b) => b.UserOrderID - a.UserOrderID);
          let currentUserOrderID = '';
          let currentGamePosition = '';
          for (let j = 0; j < downlineWinWordArray.length; j += 1) {
            dowonlineDisplayPlatform = downlineWinWordArray[j].Platform.replace(
              /1/g,
              'M',
            )
              .replace(
                /2/g,
                this.props.UserAndDownlineData[0].GameDisplay === 'MPTSWBK'
                  ? 'P'
                  : 'K',
              )
              .replace(/3/g, 'T')
              .replace(/4/g, 'S')
              .replace(/5/g, 'W')
              .replace(/6/g, 'B')
              .replace(
                /7/g,
                this.props.UserAndDownlineData[0].GameDisplay === 'MPTSWBK'
                  ? 'K'
                  : 'D',
              );
            if (downlineWinWordArray[j].Position === 'P1') {
              downlineWinpostion = '1st';
            } else if (downlineWinWordArray[j].Position === 'P2') {
              downlineWinpostion = '2nd';
            } else if (downlineWinWordArray[j].Position === 'P3') {
              downlineWinpostion = '3rd';
            } else if (
              downlineWinWordArray[j].Position >= 1 &&
                downlineWinWordArray[j].Position <= 13
            ) {
              downlineWinpostion = '4th';
            } else if (
              downlineWinWordArray[j].Position >= 14 &&
                downlineWinWordArray[j].Position <= 23
            ) {
              downlineWinpostion = '5th';
            } else {
              downlineWinpostion = downlineWinWordArray[j].Position;
            }
            const downlineSideName = downlineWinWordArray[j].SideName || '';
            if (currentUserOrderID === downlineWinWordArray[j].UserOrderID && currentGamePosition === `${dowonlineDisplayPlatform}/${downlineWinpostion}`) {
              DownlineWinWordString.push([
                '\n' +
                  downlineWinWordArray[j].Word +
                  ' ' +
                  downlineWinWordArray[j].BetMode +
                  '$' +
                  downlineWinWordArray[j].Bet +
                  '=' +
                  downlineWinWordArray[j].Strike.toFixed(2),
              ]);
            } else if (currentUserOrderID === downlineWinWordArray[j].UserOrderID) {
              DownlineWinWordString.push([
                '\n' +
                  dowonlineDisplayPlatform +
                  '/' +
                  downlineWinpostion +
                  ' ' +
                  downlineSideName +
                  '\n' +
                  downlineWinWordArray[j].Word +
                  ' ' +
                  downlineWinWordArray[j].BetMode +
                  '$' +
                  downlineWinWordArray[j].Bet +
                  '=' +
                  downlineWinWordArray[j].Strike.toFixed(2),
              ]);
              currentGamePosition = `${dowonlineDisplayPlatform}/${downlineWinpostion}`;
            } else {
              DownlineWinWordString.push([
                '\n' +
                  '#' +
                  downlineWinWordArray[j].UserOrderID +
                  '\n' +
                  dowonlineDisplayPlatform +
                  '/' +
                  downlineWinpostion +
                  ' ' +
                  downlineSideName +
                  '\n' +
                  downlineWinWordArray[j].Word +
                  ' ' +
                  downlineWinWordArray[j].BetMode +
                  '$' +
                  downlineWinWordArray[j].Bet +
                  '=' +
                  downlineWinWordArray[j].Strike.toFixed(2),
              ]);
              currentUserOrderID = downlineWinWordArray[j].UserOrderID;
              currentGamePosition = `${dowonlineDisplayPlatform}/${downlineWinpostion}`;
            }
          }
        });
      }
      if (
        this.props.ReportData.userDetails[item].Username === this.props.username
      ) {
        ReportData.push([
          `${this.props.ReportData.userDetails[item].Username} (${moment(
            this.props.SearchDateFrom,
          ).format('DD/MM')}-${moment(this.props.SearchDateTo).format(
            'DD/MM',
          )})` +
            '\n=Summary=' +
            '\n' +
            language[this.props.currentLanguage].SelfBet +
            ': ' +
            this.props.ReportData.userDetails[item].SelfBet +
            '(' +
            this.props.ReportData.userDetails[item].sgdSelfBet +
            ')' +
            '\n' +
            language[this.props.currentLanguage].SelfWater +
            ': ' +
            this.props.ReportData.userDetails[item].SelfWater +
            '(' +
            this.props.ReportData.userDetails[item].sgdSelfWater +
            ')' +
            '\n' +
            language[this.props.currentLanguage].SelfWin +
            ': ' +
            this.props.ReportData.userDetails[item].SelfWin +
            '(' +
            this.props.ReportData.userDetails[item].sgdSelfWin +
            ')' +
            '\n' +
            language[this.props.currentLanguage].SelfProfit +
            ': ' +
            this.props.ReportData.userDetails[item].SelfProfit +
            '(' +
            this.props.ReportData.userDetails[item].sgdSelfProfit +
            ')' +
            '\n\n' +
            language[this.props.currentLanguage].TotalBet +
            ': ' +
            this.props.ReportData.userDetails[item].TotalBet +
            '(' +
            this.props.ReportData.userDetails[item].sgdTotalBet +
            ')' +
            '\n' +
            language[this.props.currentLanguage].TotalWater +
            ': ' +
            this.props.ReportData.userDetails[item].TotalWater +
            '(' +
            this.props.ReportData.userDetails[item].sgdTotalWater +
            ')' +
            '\n' +
            language[this.props.currentLanguage].TotalWin +
            ': ' +
            this.props.ReportData.userDetails[item].TotalWin +
            '(' +
            this.props.ReportData.userDetails[item].sgdTotalWin +
            ')' +
            '\n\n' +
            language[this.props.currentLanguage].SharesProfit +
            ': ' +
            this.props.ReportData.userDetails[item].SharesProfit +
            '(' +
            this.props.ReportData.userDetails[item].sgdSharesProfit +
            ')' +
            '\n\n' +
            language[this.props.currentLanguage].UplineProfit +
            ': ' +
            this.props.ReportData.userDetails[item].UplineProfit +
            '(' +
            this.props.ReportData.userDetails[item].sgdUplineProfit +
            ')' +
            '\n\n' +
            language[this.props.currentLanguage].WaterEarn +
            ': ' +
            this.props.ReportData.userDetails[item].WaterProfit +
            '(' +
            this.props.ReportData.userDetails[item].sgdWaterProfit +
            ')' +
            '\n' +
            language[this.props.currentLanguage].PrizeEarn +
            ': ' +
            this.props.ReportData.userDetails[item].WinProfit +
            '(' +
            this.props.ReportData.userDetails[item].sgdWinProfit +
            ')' +
            winWordString.join(' ') +
            DownlineWinWordString.join('') +
            '\n\n=====================\n\n',
        ]);
      } else {
        ReportData.push([
          `${this.props.ReportData.userDetails[item].Username} (${moment(
            this.props.SearchDateFrom,
          ).format('DD/MM')}-${moment(this.props.SearchDateTo).format(
            'DD/MM',
          )})` +
            '\n=Summary=' +
            '\n' +
            language[this.props.currentLanguage].TotalBet +
            ': ' +
            this.props.ReportData.userDetails[item].TotalBet +
            '(' +
            this.props.ReportData.userDetails[item].sgdTotalBet +
            ')' +
            '\n' +
            language[this.props.currentLanguage].TotalWater +
            ': ' +
            this.props.ReportData.userDetails[item].TotalWater +
            '(' +
            this.props.ReportData.userDetails[item].sgdTotalWater +
            ')' +
            '\n' +
            language[this.props.currentLanguage].TotalWin +
            ': ' +
            this.props.ReportData.userDetails[item].TotalWin +
            '(' +
            this.props.ReportData.userDetails[item].sgdTotalWin +
            ')' +
            '\n\n' +
            language[this.props.currentLanguage].SharesProfit +
            ': ' +
            this.props.ReportData.userDetails[item].SharesProfit +
            '(' +
            this.props.ReportData.userDetails[item].sgdSharesProfit +
            ')' +
            '\n\n' +
            language[this.props.currentLanguage].UplineProfit +
            ': ' +
            this.props.ReportData.userDetails[item].UplineProfit +
            '(' +
            this.props.ReportData.userDetails[item].sgdUplineProfit +
            ')' +
            '\n\n' +
            language[this.props.currentLanguage].WaterEarn +
            ': ' +
            this.props.ReportData.userDetails[item].WaterProfit +
            '(' +
            this.props.ReportData.userDetails[item].sgdWaterProfit +
            ')' +
            '\n' +
            language[this.props.currentLanguage].PrizeEarn +
            ': ' +
            this.props.ReportData.userDetails[item].WinProfit +
            '(' +
            this.props.ReportData.userDetails[item].sgdWinProfit +
            ')' +
            winWordString.join(' ') +
            DownlineWinWordString.join('') +
            '\n\n=====================\n\n',
        ]);
      }
      // TotalN += Number(this.props.ReportData.userDetails[item].TotalProfit);
    });
    if (tongReport !== '' && tongReport.length >= 1) {
      // displayTong.push([]);
      displayTong.push(
        '====' + language[this.props.currentLanguage].TongRepot + '====\n',
      );
      for (let a = 0; a < tongReport.length; a++) {
        let tongLai = '';
        let tongNet = '';
        // displayTong.push([tongReport[a].username].toString());
        for (let b = 0; b < tongReport[a].tongArray.length; b++) {
          tongLai = Number(tongReport[a].tongArray[b].lai) + Number(tongLai);
          tongNet = Number(tongReport[a].tongArray[b].net) + Number(tongNet);
        }
        displayTong.push(
          tongReport[a].username +
            '\n' +
            language[this.props.currentLanguage].Zong +
            language[this.props.currentLanguage].LaiLanguage +
            ': ' +
            tongLai +
            '\n' +
            language[this.props.currentLanguage].Zong +
            'Net: ' +
            tongNet +
            '\n\n',
        );
      }
    }
    // ReportData.push('TOTAL: ' + TotalN.toFixed(2) + '\n=============');
    this.setState({
      reportStatus: status,
      DisplayReportData: ReportData,
      DisplayReportTongData: displayTong,
      loading: false,
    });
  }

  async ProcessTHAI() {
    let status = '';
    if (this.props.ReportData.completed === true) {
      status = language[this.props.currentLanguage].CompleteReport + '\n';
    } else {
      status = language[this.props.currentLanguage].unCompleteReport + '\n';
    }
    const tongReport = this.props.ReportData.tongDetails || '';
    let displayTong = [];
    const Downline = Object.keys(this.props.ReportData.userDetails);
    let ReportData = [];
    let position = '';
    let displayPlatform = '';
    let downlineWinpostion = '';
    let dowonlineDisplayPlatform = '';
    let TotalN = 0;
    Downline.forEach(item => {
      let winWordString = [];
      let DownlineWinWordString = [];
      const userID = this.props.ReportData.userDetails[item].UserID;
      const winWordData = this.props.ReportData.winObject[userID] || '';
      const SelfWin = winWordData.self;
      const DownlineWin = winWordData.downline;
      const DownlinWinUserID = Object.keys(DownlineWin);
      if (SelfWin.length > 0) {
        let currentUserOrderID = '';
        let currentGamePosition = '';
        for (let j = 0; j < SelfWin.length; j += 1) {
          displayPlatform = SelfWin[j].Platform.replace(/1/g, 'M')
            .replace(
              /2/g,
              this.props.UserAndDownlineData[0].GameDisplay === 'MPTSWBK'
                ? 'P'
                : 'K',
            )
            .replace(/3/g, 'T')
            .replace(/4/g, 'S')
            .replace(/5/g, 'W')
            .replace(/6/g, 'B')
            .replace(
              /7/g,
              this.props.UserAndDownlineData[0].GameDisplay === 'MPTSWBK'
                ? 'K'
                : 'D',
            );
          if (SelfWin[j].Position === 'P1') {
            position = '1st';
          } else if (SelfWin[j].Position === 'P2') {
            position = '2nd';
          } else if (SelfWin[j].Position === 'P3') {
            position = '3rd';
          } else if (SelfWin[j].Position >= 1 && SelfWin[j].Position <= 13) {
            position = '4th';
          } else if (SelfWin[j].Position >= 14 && SelfWin[j].Position <= 23) {
            position = '5th';
          } else {
            position = SelfWin[j].Position;
          }
          const SideName = SelfWin[j].SideName || '';
          if (currentUserOrderID === SelfWin[j].UserOrderID && currentGamePosition === `${displayPlatform}/${position}`) {
            winWordString.push([
              '\n' +
              SelfWin[j].Word +
                ' ' +
                SelfWin[j].BetMode +
                '$' +
                SelfWin[j].Bet +
                '=' +
                SelfWin[j].Strike.toFixed(2),
            ]);
          } else if (currentUserOrderID === SelfWin[j].UserOrderID) {
            winWordString.push([
              '\n' +
              displayPlatform +
                '/' +
                position +
                ' ' +
                SideName +
                '\n' +
                SelfWin[j].Word +
                ' ' +
                SelfWin[j].BetMode +
                '$' +
                SelfWin[j].Bet +
                '=' +
                SelfWin[j].Strike.toFixed(2),
            ]);
            currentGamePosition = `${displayPlatform}/${position}`;
          } else {
            winWordString.push([
              '\n' +
                '#' +
                SelfWin[j].UserOrderID +
                '\n' +
                displayPlatform +
                '/' +
                position +
                ' ' +
                SideName +
                '\n' +
                SelfWin[j].Word +
                ' ' +
                SelfWin[j].BetMode +
                '$' +
                SelfWin[j].Bet +
                '=' +
                SelfWin[j].Strike.toFixed(2),
            ]);
            currentUserOrderID = SelfWin[j].UserOrderID;
            currentGamePosition = `${displayPlatform}/${position}`;
          }
        }
      }
      if (DownlinWinUserID.length > 0) {
        DownlinWinUserID.forEach(dItem => {
          DownlineWinWordString.push(['\n' + DownlineWin[dItem][0].Username]);
          const downlineWinWordArray = DownlineWin[dItem].sort((a, b) => b.UserOrderID - a.UserOrderID);
          let currentUserOrderID = '';
          let currentGamePosition = '';
          for (let j = 0; j < downlineWinWordArray.length; j += 1) {
            dowonlineDisplayPlatform = downlineWinWordArray[j].Platform.replace(
              /1/g,
              'M',
            )
              .replace(
                /2/g,
                this.props.UserAndDownlineData[0].GameDisplay === 'MPTSWBK'
                  ? 'P'
                  : 'K',
              )
              .replace(/3/g, 'T')
              .replace(/4/g, 'S')
              .replace(/5/g, 'W')
              .replace(/6/g, 'B')
              .replace(
                /7/g,
                this.props.UserAndDownlineData[0].GameDisplay === 'MPTSWBK'
                  ? 'K'
                  : 'D',
              );
            if (downlineWinWordArray[j].Position === 'P1') {
              downlineWinpostion = '1st';
            } else if (downlineWinWordArray[j].Position === 'P2') {
              downlineWinpostion = '2nd';
            } else if (downlineWinWordArray[j].Position === 'P3') {
              downlineWinpostion = '3rd';
            } else if (
              downlineWinWordArray[j].Position >= 1 &&
              downlineWinWordArray[j].Position <= 13
            ) {
              downlineWinpostion = '4th';
            } else if (
              downlineWinWordArray[j].Position >= 14 &&
              downlineWinWordArray[j].Position <= 23
            ) {
              downlineWinpostion = '5th';
            } else {
              downlineWinpostion = downlineWinWordArray[j].Position;
            }
            const downlineSideName = downlineWinWordArray[j].SideName || '';
            if (currentUserOrderID === downlineWinWordArray[j].UserOrderID && currentGamePosition === `${dowonlineDisplayPlatform}/${downlineWinpostion}`) {
              DownlineWinWordString.push([
                '\n' +
                  downlineWinWordArray[j].Word +
                  ' ' +
                  downlineWinWordArray[j].BetMode +
                  '$' +
                  downlineWinWordArray[j].Bet +
                  '=' +
                  downlineWinWordArray[j].Strike.toFixed(2),
              ]);
            } else if (currentUserOrderID === downlineWinWordArray[j].UserOrderID) {
              DownlineWinWordString.push([
                '\n' +
                  dowonlineDisplayPlatform +
                  '/' +
                  downlineWinpostion +
                  ' ' +
                  downlineSideName +
                  '\n' +
                  downlineWinWordArray[j].Word +
                  ' ' +
                  downlineWinWordArray[j].BetMode +
                  '$' +
                  downlineWinWordArray[j].Bet +
                  '=' +
                  downlineWinWordArray[j].Strike.toFixed(2),
              ]);
              currentGamePosition = `${dowonlineDisplayPlatform}/${downlineWinpostion}`;
            } else {
              DownlineWinWordString.push([
                '\n' +
                  '#' +
                  downlineWinWordArray[j].UserOrderID +
                  '\n' +
                  dowonlineDisplayPlatform +
                  '/' +
                  downlineWinpostion +
                  ' ' +
                  downlineSideName +
                  '\n' +
                  downlineWinWordArray[j].Word +
                  ' ' +
                  downlineWinWordArray[j].BetMode +
                  '$' +
                  downlineWinWordArray[j].Bet +
                  '=' +
                  downlineWinWordArray[j].Strike.toFixed(2),
              ]);
              currentUserOrderID = downlineWinWordArray[j].UserOrderID;
              currentGamePosition = `${dowonlineDisplayPlatform}/${downlineWinpostion}`;
            }
          }
        });
      }
      if (
        this.props.ReportData.userDetails[item].Username === this.props.username
      ) {
        ReportData.push([
          `${this.props.ReportData.userDetails[item].Username} (${moment(
            this.props.SearchDateFrom,
          ).format('DD/MM')}-${moment(this.props.SearchDateTo).format(
            'DD/MM',
          )})` +
            '\n=Summary=' +
            '\n' +
            language[this.props.currentLanguage].SelfBet +
            ': ' +
            this.props.ReportData.userDetails[item].SelfBet +
            '(' +
            this.props.ReportData.userDetails[item].thaiSelfBet +
            ')' +
            '\n' +
            language[this.props.currentLanguage].SelfWater +
            ': ' +
            this.props.ReportData.userDetails[item].SelfWater +
            '(' +
            this.props.ReportData.userDetails[item].thaiSelfWater +
            ')' +
            '\n' +
            language[this.props.currentLanguage].SelfWin +
            ': ' +
            this.props.ReportData.userDetails[item].SelfWin +
            '(' +
            this.props.ReportData.userDetails[item].thaiSelfWin +
            ')' +
            '\n' +
            language[this.props.currentLanguage].SelfProfit +
            ': ' +
            this.props.ReportData.userDetails[item].SelfProfit +
            '(' +
            this.props.ReportData.userDetails[item].thaiSelfProfit +
            ')' +
            '\n\n' +
            language[this.props.currentLanguage].TotalBet +
            ': ' +
            this.props.ReportData.userDetails[item].TotalBet +
            '(' +
            this.props.ReportData.userDetails[item].thaiTotalBet +
            ')' +
            '\n' +
            language[this.props.currentLanguage].TotalWater +
            ': ' +
            this.props.ReportData.userDetails[item].TotalWater +
            '(' +
            this.props.ReportData.userDetails[item].thaiTotalWater +
            ')' +
            '\n' +
            language[this.props.currentLanguage].TotalWin +
            ': ' +
            this.props.ReportData.userDetails[item].TotalWin +
            '(' +
            this.props.ReportData.userDetails[item].thaiTotalWin +
            ')' +
            '\n\n' +
            language[this.props.currentLanguage].SharesProfit +
            ': ' +
            this.props.ReportData.userDetails[item].SharesProfit +
            '(' +
            this.props.ReportData.userDetails[item].thaiSharesProfit +
            ')' +
            '\n\n' +
            language[this.props.currentLanguage].UplineProfit +
            ': ' +
            this.props.ReportData.userDetails[item].UplineProfit +
            '(' +
            this.props.ReportData.userDetails[item].thaiUplineProfit +
            ')' +
            '\n\n' +
            language[this.props.currentLanguage].WaterEarn +
            ': ' +
            this.props.ReportData.userDetails[item].WaterProfit +
            '(' +
            this.props.ReportData.userDetails[item].thaiWaterProfit +
            ')' +
            '\n' +
            language[this.props.currentLanguage].PrizeEarn +
            ': ' +
            this.props.ReportData.userDetails[item].WinProfit +
            '(' +
            this.props.ReportData.userDetails[item].thaiWinProfit +
            ')' +
            winWordString.join(' ').replace(/,/g, '') +
            DownlineWinWordString.join('').replace(/,/g, '') +
            '\n\n=======================\n\n',
        ]);
      } else {
        ReportData.push([
          `${this.props.ReportData.userDetails[item].Username} (${moment(
            this.props.SearchDateFrom,
          ).format('DD/MM')}-${moment(this.props.SearchDateTo).format(
            'DD/MM',
          )})` +
            '\n=Summary=' +
            '\n' +
            language[this.props.currentLanguage].TotalBet +
            ': ' +
            this.props.ReportData.userDetails[item].TotalBet +
            '(' +
            this.props.ReportData.userDetails[item].thaiTotalBet +
            ')' +
            '\n' +
            language[this.props.currentLanguage].TotalWater +
            ': ' +
            this.props.ReportData.userDetails[item].TotalWater +
            '(' +
            this.props.ReportData.userDetails[item].thaiTotalWater +
            ')' +
            '\n' +
            language[this.props.currentLanguage].TotalWin +
            ': ' +
            this.props.ReportData.userDetails[item].TotalWin +
            '(' +
            this.props.ReportData.userDetails[item].thaiTotalWin +
            ')' +
            '\n\n' +
            language[this.props.currentLanguage].SharesProfit +
            ': ' +
            this.props.ReportData.userDetails[item].SharesProfit +
            '(' +
            this.props.ReportData.userDetails[item].thaiSharesProfit +
            ')' +
            '\n\n' +
            language[this.props.currentLanguage].UplineProfit +
            ': ' +
            this.props.ReportData.userDetails[item].UplineProfit +
            '(' +
            this.props.ReportData.userDetails[item].thaiUplineProfit +
            ')' +
            '\n\n' +
            language[this.props.currentLanguage].WaterEarn +
            ': ' +
            this.props.ReportData.userDetails[item].WaterProfit +
            '(' +
            this.props.ReportData.userDetails[item].thaiWaterProfit +
            ')' +
            '\n' +
            language[this.props.currentLanguage].PrizeEarn +
            ': ' +
            this.props.ReportData.userDetails[item].WinProfit +
            '(' +
            this.props.ReportData.userDetails[item].thaiWinProfit +
            ')' +
            winWordString.join(' ').replace(/,/g, '') +
            DownlineWinWordString.join('').replace(/,/g, '') +
            '\n\n=======================\n\n',
        ]);
      }
      // TotalN += Number(this.props.ReportData.userDetails[item].TotalProfit);
    });
    if (tongReport !== '' && tongReport.length >= 1) {
      // displayTong.push([]);
      displayTong.push(
        '====' + language[this.props.currentLanguage].TongRepot + '====\n',
      );
      for (let a = 0; a < tongReport.length; a++) {
        let tongLai = '';
        let tongNet = '';
        // displayTong.push([tongReport[a].username].toString());
        for (let b = 0; b < tongReport[a].tongArray.length; b++) {
          tongLai = Number(tongReport[a].tongArray[b].lai) + Number(tongLai);
          tongNet = Number(tongReport[a].tongArray[b].net) + Number(tongNet);
        }
        displayTong.push(
          tongReport[a].username +
            '\n' +
            language[this.props.currentLanguage].Zong +
            language[this.props.currentLanguage].LaiLanguage +
            ': ' +
            tongLai +
            '\n' +
            language[this.props.currentLanguage].Zong +
            'Net: ' +
            tongNet +
            '\n\n',
        );
      }
    }
    // ReportData.push('TOTAL: ' + TotalN.toFixed(2) + '\n=============');
    this.setState({
      reportStatus: status,
      DisplayReportData: ReportData,
      DisplayReportTongData: displayTong,
      loading: false,
    });
  }


  async OnpressReportDetails() {
    const Downline = Object.keys(this.props.ReportData.userDetails);
    if (this.props.ReportData.message === 'No Data' || Downline.length <= 0) {
      alert(language[this.props.currentLanguage].NoData);
    } else {
      this.props.history.push('/displayReportDetails');
    }
  }

  CopyResultFunction() {
    try {
      const Result = `${this.state.reportStatus}${this.state.DisplayReportData}`
      navigator.clipboard.writeText(Result.toString()
        .replace(/,/g, ''));
      alert(
        language[this.props.currentLanguage].DoneCopy,
      ); }  catch{
      alert(
        language[this.props.currentLanguage].FailedCopy,
      );
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="LoginPageContainer" style={{ justifyContent: 'center'}}>
          <img class = "loadingSize" src={LoadingLogo} alt="BigCo Inc. logo"/>
        </div>
      );
    } else {
      return (
        <body>
          <div className='box'>
            <div className='Report-HEAD'>
              <div className='PageHeaderMainContainer'>
                <div className='PageHeaderLeftContainer'>
                  <div className='PageHeaderLeftBackButton'
                    onClick={() => this.props.history.goBack()}>
                    {language[this.props.currentLanguage].Back}
                  </div>
                </div>
                <div className='PageHeaderMiddleContainer'>
                  {language[this.props.currentLanguage].ReportRespond}
                </div>
                <div className='PageHeaderRightContainer'>
                </div>
              </div>
            </div>
            <div className='Report-body' style={{ overflowX: 'hidden' }}>
              <div className='reportDisplayText'>
                {this.state.reportStatus}
                {this.state.DisplayReportData}
                {this.state.DisplayReportTongData}
              </div>
            </div>
            <div className='resultFooter'>
              <div className='reportFooterButtonContainer'>
                <div className='reportFooterButton' 
                  onClick={() =>this.CopyResultFunction()}>
                  {language[this.props.currentLanguage].Copy}
                </div>
                <div className='reportFooterButton'
                  onClick={() =>this.OnpressReportDetails()}>
                  {language[this.props.currentLanguage].Details}
                </div>
              </div>
            </div>
          </div>
        </body> 
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    id: state.id,
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
    ReportData: state.ReportData,
    UserIDForSearch: state.UserIDForSearch,
    UserForSearch: state.UserForSearch,
    SearchDateFrom: state.SearchDateFrom,
    SearchDateTo: state.SearchDateTo,
    UserAndDownlineData: state.UserAndDownlineData,
    PlatformListToSearch: state.PlatformListToSearch,
    redModeToSearch: state.redModeToSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (loginStatus, ID, Username, UserToken, role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          login: loginStatus,
          id: ID,
          username: Username,
          token: UserToken,
          role: role,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(displayReport);
  