export function processCopyText(ToprocessOriData) {
  let ArraytoReturn = [];
  let replaceWord = '';
  let SpitWordCheck = '';
  for (let i = 0; i < ToprocessOriData.length; i++) {
    replaceWord = ToprocessOriData[i].replace(/ /g, '#');
    replaceWord = replaceWord
      .replace(/([/])/g, '#')
      .replace(/([.])/g, '*')
      .replace(/-/g, '#')
      .replace(/o/g, '0')
      .replace(/O/g, '0');
    replaceWord = replaceWord.replace(/[^A-Za-z0-9-#.*D]/g, '');
    SpitWordCheck = replaceWord.split('#');
    if (replaceWord.startsWith('##')) {
      SpitWordCheck = replaceWord.replace('##', '').split('#');
    } else if (replaceWord.startsWith('#')) {
      SpitWordCheck = replaceWord.replace('#', '').split('#');
    }
    if (i !== 0 && replaceWord.includes('D#')) {
      //ignore
    } else if (replaceWord.includes('D') && !replaceWord.startsWith('D#')) {
      //ignore
    } else if (
      replaceWord.includes('D') &&
      (replaceWord.replace(/[^D]/g, '').length > 1 ||
        replaceWord.replace(/[^#]/g, '').length > 1 ||
        replaceWord.replace(/[^.]/g, '').length > 0)
    ) {
      //ignore
    } else if (SpitWordCheck[0].includes('.') || SpitWordCheck[0] === '') {
      // ignore
    } else if (SpitWordCheck[0].includes('-')) {
      //If Word With '-'
      if (replaceWord.split('-').length - 1 === 1) {
        let FirstWord = replaceWord.split('-')[0] || '';
        let SecondWord = replaceWord.split('-')[1] || '';
        if (!isNaN(parseInt(FirstWord)) === false) {
          //Check First Number is Number
          FirstWord = '';
        }
        SecondWord = SecondWord.split('#');
        if (SecondWord[0].endsWith('**')) {
          SecondWord = SecondWord[0].replace('**', '');
        } else {
          SecondWord = SecondWord[0];
        }
        if (!isNaN(parseInt(SecondWord)) === false) {
          //Check Second Number is Number
          SecondWord = '';
        }
        if (
          (FirstWord.length === 3 || FirstWord.length === 4) &&
          (SecondWord.length === 3 || SecondWord.length === 4)
        ) {
          if (FirstWord >= SecondWord) {
            //Ignore
          } else {
            ArraytoReturn.push(replaceWord);
          }
        }
      }
    } else if (SpitWordCheck[0].endsWith('**')) {
      // if endwith **
      const RemoveDot = SpitWordCheck[0].replace(/[*]/g, '');
      if (RemoveDot.length < 2 || RemoveDot.length > 4) {
        //ignore
      } else {
        ArraytoReturn.push(replaceWord);
      }
    } else if (
      !SpitWordCheck[0].includes('D') &&
      SpitWordCheck.length >= 2 &&
      (SpitWordCheck[0].length < 2 || SpitWordCheck[0].length > 6)
    ) {
      //ignore
    } else if (!SpitWordCheck[0].includes('-') && replaceWord.includes('-')) {
      //ignore
    } else if (
      SpitWordCheck[0].includes('***') ||
      SpitWordCheck[0].includes('...')
    ) {
      //ignore
    } else if (SpitWordCheck[0].replace('-', '').includes('-')) {
      //ignore
    } else {
      ArraytoReturn.push(replaceWord);
    }
  }
  let toProcessLineData = ArraytoReturn;
  let processArraytoReturn = [];
  let AmountCopy = '';
  let CopyOrNot = false;
  let toCheckEndStart = '';
  let Key2Sign = '';
  let onlyWord = '';
  const CheckNan = toProcessLineData.filter(
    item => item.startsWith('#') && item.split('#').length === 2,
  );
  if (CheckNan.length > 0) {
    //JinNan Copy Amount
    //------------
    for (let i = 0; i < toProcessLineData.length; i++) {
      const CheckNull = toProcessLineData[i] || '';
      let OriWord = toProcessLineData[i];
      let JingNanOri = toProcessLineData[i];
      if (OriWord.startsWith('#') && OriWord.split('#').length === 2) {
        CopyOrNot = true;
      }
      if (OriWord.startsWith('##')) {
        OriWord = OriWord.replace('##', '');
      } else if (OriWord.startsWith('#')) {
        OriWord = OriWord.replace('#', '');
      }
      onlyWord = OriWord;
      if (
        toProcessLineData[i].endsWith('**') ||
        toProcessLineData[i].endsWith('***')
      ) {
        Key2Sign = '';
      } else if (onlyWord.endsWith('***')) {
        Key2Sign = '***';
      } else if (onlyWord.endsWith('**')) {
        Key2Sign = '**';
      } else {
        Key2Sign = '';
      }
      const OriWordArray = OriWord.split('#');
      if (
        CopyOrNot === true &&
        OriWordArray.length >= 2 &&
        OriWordArray[0] !== ''
      ) {
        toCheckEndStart = toProcessLineData[i].split('#')[0];
        AmountCopy = toProcessLineData[i].substring(
          toProcessLineData[i].indexOf('#'),
        );
        OriWord = toProcessLineData[i];
      } else if (
        JingNanOri.startsWith('#') &&
        JingNanOri.split('#').length === 2
      ) {
        OriWord = toProcessLineData[i];
      } else if (OriWordArray[0].length >= 3 && OriWordArray[0].length <= 6) {
        if (toCheckEndStart.endsWith('*') && !toCheckEndStart.endsWith('**')) {
          OriWord = toProcessLineData[i] + Key2Sign + '*' + AmountCopy;
        } else {
          OriWord = toProcessLineData[i] + Key2Sign + AmountCopy;
        }
        // OriWord = toProcessLineData[i] + AmountCopy;
      } else {
        if (
          toProcessLineData[i].startsWith('#') &&
          toProcessLineData[i].split('#').length === 2
        ) {
          OriWord = toProcessLineData[i].replace('#', '');
        } else {
          OriWord = toProcessLineData[i];
        }
      }
      processArraytoReturn.push(OriWord);
    }
    //------------
  } else {
    //Normal Copy Amount
    for (let i = 0; i < toProcessLineData.length; i++) {
      const CheckNull = toProcessLineData[i] || '';
      let OriWord = toProcessLineData[i];
      if (OriWord.includes('D#')) {
        if (i === 0) {
          processArraytoReturn.push(OriWord);
        }
      } else {
        const CheckOnlyTag = OriWord.substring(OriWord.indexOf('#'));
        const OriWordArray = OriWord.split('#');
        if (OriWordArray.length >= 2 && CheckOnlyTag !== '#') {
          onlyWord = toProcessLineData[i].split('#')[0];
          toCheckEndStart = toProcessLineData[i].split('#')[0];
          AmountCopy = toProcessLineData[i].substring(
            toProcessLineData[i].indexOf('#') + 1,
          );
          OriWord = toProcessLineData[i];
        } else if (OriWordArray.length === 2 && CheckOnlyTag === '#') {
          if (
            toProcessLineData[i].endsWith('**#') ||
            toProcessLineData[i].endsWith('***')
          ) {
            Key2Sign = '';
          } else if (onlyWord.endsWith('***')) {
            Key2Sign = '***';
          } else if (onlyWord.endsWith('**')) {
            Key2Sign = '**';
          } else {
            Key2Sign = '';
          }
          if (
            toCheckEndStart.endsWith('*') &&
            !toCheckEndStart.endsWith('**')
          ) {
            OriWord =
              toProcessLineData[i].slice(0, -1) + Key2Sign + '*#' + AmountCopy;
          } else {
            OriWord =
              toProcessLineData[i].slice(0, -1) + Key2Sign + '#' + AmountCopy;
          }
          // OriWord = toProcessLineData[i] + AmountCopy;
        } else {
          OriWord = toProcessLineData[i];
        }
        processArraytoReturn.push(OriWord);
      }
    }
  }

  return processArraytoReturn;
}
