import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import login from './Page/login';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';

class Authorization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return(
      <Router style={{ position: 'relative'}}>
        <div className="App">
          <Switch>
            <Route exact path="/" component={login} />
            <Route component={login} />
          </Switch>
        </div>
      </Router>
    )
  };
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    userID: state.userID,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
  };
}

export default connect(mapStateToProps, null)(Authorization);
