import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import {language} from '../language';
import LoadingLogo from '../img/loading.gif';
import moment from 'moment-timezone';
const { getBetReportMobile } = require('../Api');

class reportBetChecking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      date: new Date(),
      DisplayReportData: '',
      DisplayReportTongData: '',
      todayDate: moment().format('YYYY-MM-DD'),
      reportStatus: '',
    }
  }

  async LogoutFunction() {
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('AbleRed');
    sessionStorage.removeItem('FirstTimeLogin');
    sessionStorage.removeItem('Username');
    sessionStorage.removeItem('loginStatus');
    sessionStorage.removeItem('getUserDownlineData');
    sessionStorage.removeItem('ReceiptData');
    sessionStorage.removeItem('UserIDForSearch');
    sessionStorage.removeItem('UserForSearch');
    sessionStorage.removeItem('SearchDateFrom');
    sessionStorage.removeItem('SearchDateTo');
    sessionStorage.removeItem('PatternForSearch');
    sessionStorage.removeItem('PlatformForSearch');
    sessionStorage.removeItem('ReportData');
    this.props.setLoginStatus(false, '', '', '', '');
  }

  async componentDidMount() {
    if (this.props.redModeToSearch === 'sgd') {
      this.ProcessSGDSelfBet();
    }  else if (this.props.redModeToSearch === 'thai') {
      this.ProcessThaiSelfBet();
    } else {
      this.processNormalSelfBet();
    }
  }

  async processNormalSelfBet() {
    let MBetData = [];
    const getBetReport = await getBetReportMobile(
      this.props.username,
      this.props.SearchDateFrom,
      this.props.SearchDateTo,
      this.props.UserIDForSearch,
      this.props.redModeToSearch,
      this.props.token,
      this.props.PlatformListToSearch,
    );
    if (getBetReport === 'TimeOut') {
      this.LogoutFunction();
    }
    const getDate = Object.keys(getBetReport);
    for (let y = 0; y < getDate.length; y += 1) {
      for (let i = 0; i < getBetReport[getDate[y]].length; i += 1) {
        MBetData.push([
          getBetReport[getDate[y]][i].Username +
            moment(getDate[y]).format('(DD/MM)') +
            '\n' +
            language[this.props.currentLanguage].SelfBet +
            ': ' +
            getBetReport[getDate[y]][i].SelfBet +
            '\n' +
            language[this.props.currentLanguage].DownlinBet +
            ': ' +
            getBetReport[getDate[y]][i].DownlineBet +
            '\n' +
            language[this.props.currentLanguage].TotalBet +
            ': ' +
            getBetReport[getDate[y]][i].TotalBet +
            '\n\n',
        ]);
      }
    }
    this.setState({
      // DisplayReportData: 'NO DATA',
      DisplayReportData: MBetData,
      loading: false,
    });
  }

  async ProcessSGDSelfBet() {
    let MBetData = [];
    const getBetReport = await getBetReportMobile(
      this.props.username,
      this.props.SearchDateFrom,
      this.props.SearchDateTo,
      this.props.UserIDForSearch,
      this.props.redModeToSearch,
      this.props.token,
      this.props.PlatformListToSearch,
    );
    if (getBetReport === 'TimeOut') {
      this.LogoutFunction();
    }
    const getDate = Object.keys(getBetReport);
    for (let y = 0; y < getDate.length; y += 1) {
      for (let i = 0; i < getBetReport[getDate[y]].length; i += 1) {
        MBetData.push([
          getBetReport[getDate[y]][i].Username +
            moment(getDate[y]).format('(DD/MM)') +
            '\n' +
            language[this.props.currentLanguage].SelfBet +
            ': ' +
            getBetReport[getDate[y]][i].SelfBet +
            '(' +
            getBetReport[getDate[y]][i].sgdSelfBet +
            ')' +
            '\n' +
            language[this.props.currentLanguage].DownlinBet +
            ': ' +
            getBetReport[getDate[y]][i].DownlineBet +
            '(' +
            getBetReport[getDate[y]][i].sgdDownlineBet +
            ')' +
            '\n' +
            language[this.props.currentLanguage].TotalBet +
            ': ' +
            getBetReport[getDate[y]][i].TotalBet +
            '(' +
            getBetReport[getDate[y]][i].sgdTotalBet +
            ')' +
            '\n\n',
        ]);
      }
    }
    this.setState({
      // DisplayReportData: 'NO DATA',
      DisplayReportData: MBetData,
      loading: false,
    });
  }

  async ProcessThaiSelfBet() {
    let MBetData = [];
    const getBetReport = await getBetReportMobile(
      this.props.username,
      this.props.SearchDateFrom,
      this.props.SearchDateTo,
      this.props.UserIDForSearch,
      this.props.redModeToSearch,
      this.props.token,
      this.props.PlatformListToSearch,
    );
    if (getBetReport === 'TimeOut') {
      this.LogoutFunction();
    }
    const getDate = Object.keys(getBetReport);
    for (let y = 0; y < getDate.length; y += 1) {
      for (let i = 0; i < getBetReport[getDate[y]].length; i += 1) {
        MBetData.push([
          getBetReport[getDate[y]][i].Username +
            moment(getDate[y]).format('(DD/MM)') +
            '\n' +
            language[this.props.currentLanguage].SelfBet +
            ': ' +
            getBetReport[getDate[y]][i].SelfBet +
            '(' +
            getBetReport[getDate[y]][i].thaiSelfBet +
            ')' +
            '\n' +
            language[this.props.currentLanguage].DownlinBet +
            ': ' +
            getBetReport[getDate[y]][i].DownlineBet +
            '(' +
            getBetReport[getDate[y]][i].thaiDownlineBet +
            ')' +
            '\n' +
            language[this.props.currentLanguage].TotalBet +
            ': ' +
            getBetReport[getDate[y]][i].TotalBet +
            '(' +
            getBetReport[getDate[y]][i].thaiTotalBet +
            ')' +
            '\n\n',
        ]);
      }
    }
    this.setState({
      // DisplayReportData: 'NO DATA',
      DisplayReportData: MBetData,
      loading: false,
    });
  }

  CopyResultFunction() {
    try {
      const Result = `${this.state.reportStatus}${this.state.DisplayReportData}`
      navigator.clipboard.writeText(Result.toString()
        .replace(/,/g, ''));
      alert(
        language[this.props.currentLanguage].DoneCopy,
      ); }  catch{
      alert(
        language[this.props.currentLanguage].FailedCopy,
      );
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="LoginPageContainer" style={{ justifyContent: 'center'}}>
          <img class = "loadingSize" src={LoadingLogo} alt="BigCo Inc. logo"/>
        </div>
      );
    } else {
      return (
        <body>
          <div className='box'>
            <div className='Report-HEAD'>
              <div className='PageHeaderMainContainer'>
                <div className='PageHeaderLeftContainer'>
                  <div className='PageHeaderLeftBackButton'
                    onClick={() => this.props.history.goBack()}>
                    {language[this.props.currentLanguage].Back}
                  </div>
                </div>
                <div className='PageHeaderMiddleContainer'>
                  {language[this.props.currentLanguage].betReport}
                </div>
                <div className='PageHeaderRightContainer'>
                </div>
              </div>
            </div>
            <div className='Report-body' style={{ overflowX: 'hidden' }}>
              <div className='reportDisplayText'>
                {this.state.reportStatus}
                {this.state.DisplayReportData}
                {this.state.DisplayReportTongData}
              </div>
            </div>
            <div className='resultFooter'>
              <div className='reportFooterButtonContainer'>
                <div className='reportFooterButton' style={{width: '100%'}} 
                  onClick={() =>this.CopyResultFunction()}>
                  {language[this.props.currentLanguage].Copy}
                </div>
              </div>
            </div>
          </div>
        </body> 
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    id: state.id,
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
    ReportData: state.ReportData,
    UserIDForSearch: state.UserIDForSearch,
    UserForSearch: state.UserForSearch,
    SearchDateFrom: state.SearchDateFrom,
    SearchDateTo: state.SearchDateTo,
    UserAndDownlineData: state.UserAndDownlineData,
    PlatformListToSearch: state.PlatformListToSearch,
    redModeToSearch: state.redModeToSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (loginStatus, ID, Username, UserToken, role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          login: loginStatus,
          id: ID,
          username: Username,
          token: UserToken,
          role: role,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(reportBetChecking);
  