import '../App.css';
// import JSEncrypt from 'jsencrypt';
import React from 'react';
import { connect } from 'react-redux';
import {language} from '../language';
import CheckmarkIcon from '../img/Checkmark.png';
const { getVersion } = require('../Api');

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      LanguageList: [
        {Language: '中文', CheckMark: false, Hint: 'cn'},
        {Language: 'English', CheckMark: false, Hint: 'en'},
      ],
      KeyboardSelect: [
        {Keyboard: 'KeyboardA', CheckMark: false},
        {Keyboard: 'KeyboardB', CheckMark: false},
        {Keyboard: 'KeyboardC', CheckMark: false},
      ],
      KeyList: [
        {Language: 'KeyA', CheckMark: false, Hint: 'A'},
        {Language: 'KeyB', CheckMark: false, Hint: 'B'},
        {Language: 'KeyC', CheckMark: false, Hint: 'C'},
        {Language: 'KeyD', CheckMark: false, Hint: 'D'},
      ],
      Version: '',
    }
    this.ChangeLangauge = this.ChangeLangauge.bind(this);
  }

  async componentDidMount() {
    if (this.props.UserAndDownlineData === '') {
      this.props.history.push('/')
    }
    const getKeyType = (await localStorage.getItem('CacheKeyBoard')) || '';
    this.ChangeKey(getKeyType);
    this.CurrentSelectedLanguage();
    this.getLastesVersion();
  }

  async CurrentSelectedLanguage() {
    const SetCurrentLanguage = this.state.LanguageList
    if(this.props.currentLanguage === 'cn') {
      SetCurrentLanguage[0].CheckMark = true
      SetCurrentLanguage[1].CheckMark = false
    } else if (this.props.currentLanguage === 'en') {
      SetCurrentLanguage[0].CheckMark = false
      SetCurrentLanguage[1].CheckMark = true
    }
    this.setState({ LanguageList: SetCurrentLanguage })
  }
  async getLastesVersion() {
    const getversion = await getVersion(this.props.username, this.props.token)
    if (getversion === 'TimeOut') {
      this.LogoutFunction();
    }
    if(getversion) {
      this.setState({ Version: getversion })
    }
  }

  async LogoutFunction() {
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('AbleRed');
    sessionStorage.removeItem('FirstTimeLogin');
    sessionStorage.removeItem('Username');
    sessionStorage.removeItem('loginStatus');
    sessionStorage.removeItem('getUserDownlineData');
    sessionStorage.removeItem('ReceiptData');
    sessionStorage.removeItem('UserIDForSearch');
    sessionStorage.removeItem('UserForSearch');
    sessionStorage.removeItem('SearchDateFrom');
    sessionStorage.removeItem('SearchDateTo');
    sessionStorage.removeItem('PatternForSearch');
    sessionStorage.removeItem('PlatformForSearch');
    sessionStorage.removeItem('ReportData');
    this.props.setLoginStatus(false, '', '', '', '');
  }

  async ChangeLangauge(Hint) {
    const SetCurrentLanguage = this.state.LanguageList
    if (Hint === 'cn') {
      this.props.setLanguage('cn')
      await localStorage.setItem('CacheLanguage', 'cn');
      SetCurrentLanguage[0].CheckMark = true
      SetCurrentLanguage[1].CheckMark = false
    } else if (Hint === 'en') {
      this.props.setLanguage('en')
      await localStorage.setItem('CacheLanguage', 'en');
      SetCurrentLanguage[0].CheckMark = false
      SetCurrentLanguage[1].CheckMark = true
    }
    this.setState({ LanguageList: SetCurrentLanguage })
  }

  async ChangeKey(Hint) {
    const toProcessKeyData = this.state.KeyList;
    if (Hint !== '') {
      for (var i = 0; i < toProcessKeyData.length; i++) {
        if (toProcessKeyData[i].Hint === Hint) {
          toProcessKeyData[i].CheckMark = true;
          await localStorage.setItem('CacheKeyBoard', Hint);
        } else {
          toProcessKeyData[i].CheckMark = false;
        }
      }
    } else {
      toProcessKeyData[0].CheckMark = true;
      await localStorage.setItem('CacheKeyBoard', 'A');
    }

    this.setState({KeyList: toProcessKeyData});
  }

  async ChangeKeyBoard(SelectedKeyboard) {
    let KeyboardSelect = this.state.KeyboardSelect
    if(SelectedKeyboard === 'KeyboardA') {
      this.props.setKeyBoard('KeyboardA')
      KeyboardSelect[0].CheckMark = true
      KeyboardSelect[1].CheckMark = false
      KeyboardSelect[2].CheckMark = false
    } else if(SelectedKeyboard === 'KeyboardB') {
      this.props.setKeyBoard('KeyboardB')
      KeyboardSelect[0].CheckMark = false
      KeyboardSelect[1].CheckMark = true
      KeyboardSelect[2].CheckMark = false
    } else {
      this.props.setKeyBoard('KeyboardC')
      KeyboardSelect[0].CheckMark = false
      KeyboardSelect[1].CheckMark = false
      KeyboardSelect[2].CheckMark = true
    }
    this.setState({ KeyboardSelect })
  }

  render() {
    return (
      <body>
        <div className='PageHeaderMainContainer'>
          <div className='PageHeaderLeftContainer'>
            <div className='PageHeaderLeftBackButton'
              onClick={() => this.props.history.goBack()}>
              {language[this.props.currentLanguage].Back}
            </div>
          </div>
          <div className='PageHeaderMiddleContainer'>
            {language[this.props.currentLanguage].setting}
          </div>
          <div className='PageHeaderRightContainer'>
          </div>
        </div>
        <div className='BT-body' style={{ height: '90%' }}>
          <div className='SettingTitle' style={{ borderBottom: "0.5px solid black" }}>
              语言/Language
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', }}>
            {this.state.LanguageList.map((items, index) => {
              return(
                <div className='SettingLanguageButtons' key={index}
                  style={{ fontSize: '18px', borderBottom: "0.5px solid black"  }} onClick={() => this.ChangeLangauge(items.Hint)}>
                  <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>{items.Language}</div>
                  {items.CheckMark === true && 
                  <div style={{ marginLeft: 'auto' }}>
                    <img className="CheckmarkIcon" src={CheckmarkIcon} alt="CheckmarkIcon"/>
                  </div>
                  }
                  {items.CheckMark === false && 
                  <div style={{ marginLeft: 'auto', opacity: 0}}>
                    <img className="CheckmarkIcon" src={CheckmarkIcon} alt="CheckmarkIcon"/>
                  </div>
                  }
                </div>
              );
            })}
          </div>
          <div className='SettingTitle' style={{ borderBottom: "0.5px solid black" }}>
            {language[this.props.currentLanguage].keyChange}
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', }}>
            {this.state.KeyList.map((items, index) => {
              return(
                <div className='SettingLanguageButtons' key={index}
                  style={{ fontSize: '18px', borderBottom: "0.5px solid black"  }} onClick={() => this.ChangeKey(items.Hint)}>
                  <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    {language[this.props.currentLanguage].KeyPad}
                    {' '}
                    {items.Hint}</div>
                  {items.CheckMark === true && 
                  <div style={{ marginLeft: 'auto' }}>
                    <img className="CheckmarkIcon" src={CheckmarkIcon} alt="CheckmarkIcon"/>
                  </div>
                  }
                  {items.CheckMark === false && 
                  <div style={{ marginLeft: 'auto', opacity: 0}}>
                    <img className="CheckmarkIcon" src={CheckmarkIcon} alt="CheckmarkIcon"/>
                  </div>
                  }
                </div>
              );
            })}
          </div>
          <div className='SettingTitle' style={{ borderBottom: "0.5px solid black" }}>
            {language[this.props.currentLanguage].AccountSetting}
          </div>
          <div className='SettingButtons'
            style={{ fontSize: '18px', borderBottom: "0.5px solid black" }} onClick={()=> this.props.history.push('/ChangePassword')}>
            {language[this.props.currentLanguage].ChangePassword}
          </div>
          <div className='SettingTitle' style={{ borderBottom: "0.5px solid black" }}>
            {/* {language[this.props.currentLanguage].VerSetting} */}
            {language[this.props.currentLanguage].Download} APK {language[this.props.currentLanguage].Version}
          </div>
          <div className='SettingButtons'
            style={{ fontSize: '18px', borderBottom: "0.5px solid black", flexDirection: 'column', whiteSpace: 'pre-wrap' }} onClick={()=> window.open("http://118.107.232.198/mogut")}>
            <div>{`MOGU.APK`}</div>
            <div style={{ fontSize: '15px'}}>{`${language[this.props.currentLanguage].currentVer}(v${this.state.Version})`}</div>
          </div>
        </div>
      </body> 
    );
  }
}

function mapStateToProps(state) {
  return {
    id: state.id,
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
    currentKeyboard: state.currentKeyboard,
    UserAndDownlineData: state.UserAndDownlineData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLanguage: lg => {
      dispatch({type: 'SET_LANGUAGE', payload: lg});
    },
    setKeyBoard: lg => {
      dispatch({type: 'SET_KEYBOARD', payload: lg});
    },
    setLoginStatus: (loginStatus, ID, Username, UserToken, role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          login: loginStatus,
          id: ID,
          username: Username,
          token: UserToken,
          role: role,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
  