/* eslint-disable no-useless-concat */
/* eslint-disable no-mixed-operators */
import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import {language} from '../language';
import CheckmarkIcon from '../img/Checkmark.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import JSEncrypt from 'jsencrypt';
import LoadingLogo from '../img/loading.gif';
import moment from 'moment-timezone';
import MLogo from '../img/PlatFormIcon/MLogo.png';
import KLogo from '../img/PlatFormIcon/KLogo.png';
import TLogo from '../img/PlatFormIcon/TLogo.png';
import SLogo from '../img/PlatFormIcon/SLogo.png';
import WLogo from '../img/PlatFormIcon/WLogo.png';
import BLogo from '../img/PlatFormIcon/BLogo.png';
import DLogo from '../img/PlatFormIcon/DLogo.png';
import btLogo from '../img/PlatFormIcon/8Logo.png';
import NineLogo from '../img/PlatFormIcon/NineLogo.png';
const { getDrawResult } = require('../Api');

const arrayToRepeat = [
  'P1',
  'P2',
  'P3',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

  
class Result extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      loading: true,
      isDatePickerVisible: false,
      MagBTResult: '',
      DmcBTResult: '',
      TotoBTResult: '',
      Sg4BTResult: '',
      SwkBTResult: '',
      SbhBTResult: '',
      SdkBTResult: '',
      Bt4BTResult: '',
      NineBTResult: '',
      DisplayBTResult: '',
      SelectedDate: moment().format('YYYY-MM-DD'),
      PlatFormList: [
        {
          PlatForm: 'M',
          CheckMark: true,
          ImageUri: MLogo,
        },
        {
          PlatForm: 'K',
          CheckMark: true,
          ImageUri: KLogo,
        },
        {
          PlatForm: 'T',
          CheckMark: true,
          ImageUri: TLogo,
        },
        {
          PlatForm: 'S',
          CheckMark: true,
          ImageUri: SLogo,
        },
        {
          PlatForm: 'W',
          CheckMark: true,
          ImageUri: WLogo,
        },
        {
          PlatForm: 'B',
          CheckMark: true,
          ImageUri: BLogo,
        },
        {
          PlatForm: 'D',
          CheckMark: true,
          ImageUri: DLogo,
        },
        {
          PlatForm: '8',
          CheckMark: true,
          ImageUri: btLogo,
        },
        {
          PlatForm: '9',
          CheckMark: true,
          ImageUri: NineLogo,
        },
      ],
  
    }
  }

  async LogoutFunction() {
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('AbleRed');
    sessionStorage.removeItem('FirstTimeLogin');
    sessionStorage.removeItem('Username');
    sessionStorage.removeItem('loginStatus');
    sessionStorage.removeItem('getUserDownlineData');
    sessionStorage.removeItem('ReceiptData');
    sessionStorage.removeItem('UserIDForSearch');
    sessionStorage.removeItem('UserForSearch');
    sessionStorage.removeItem('SearchDateFrom');
    sessionStorage.removeItem('SearchDateTo');
    sessionStorage.removeItem('PatternForSearch');
    sessionStorage.removeItem('PlatformForSearch');
    sessionStorage.removeItem('ReportData');
    this.props.setLoginStatus(false, '', '', '', '');
  }

  async componentDidMount() {
    if (this.props.UserAndDownlineData === '') {
      this.props.history.push('/')
    }
    const PlatFormListData = this.state.PlatFormList;
    this.GetResult(moment(this.state.SelectedDate).format('YYYYMMDD'));
    if (this.props.UserAndDownlineData[0].GameDisplay === 'MPTSWBK') {
      PlatFormListData[1].PlatForm = 'P';
      PlatFormListData[6].PlatForm = 'K';
    } else {
      PlatFormListData[1].PlatForm = 'K';
      PlatFormListData[6].PlatForm = 'D';
    }
    if (!this.props.UserAndDownlineData[0].GameBetting.includes('9')) {
      PlatFormListData.pop();
    }
    this.setState({PlatFormList: PlatFormListData});
    //this.GetResult(moment('20211024').format('YYYYMMDD'));
  }

  async setSelectedDate(date, mode) {
    this.setState({
      loading: true,
      [mode]: date,
    }, ()=> {
      this.GetResult(this.state[mode]);
    });
  }

  async PlatformCheckboxSelect(idx) {
    const toProcessDateData = this.state.PlatFormList;
    if (toProcessDateData[idx].CheckMark === false) {
      toProcessDateData[idx].CheckMark = true;
    } else {
      toProcessDateData[idx].CheckMark = false;
    }
    this.setState({ModalDate: toProcessDateData});
    this.FilterResult();
  }


  async GetResult(Date) {
    this.setState({loading: true});
    // const TodayDate = moment().format('YYYYMMDD');
    const BTResultData = await getDrawResult(
      this.props.username,
      moment(Date).format('YYYYMMDD'),
      this.props.token,
    );
    if (BTResultData === 'TimeOut') {
      this.LogoutFunction();
    } else if (BTResultData.error) {
      this.setState({
        MagBTResult: '',
        DmcBTResult: '',
        TotoBTResult: '',
        Sg4BTResult: '',
        SwkBTResult: '',
        SbhBTResult: '',
        SdkBTResult: '',
        Bt4BTResult: '',
        loading: false,
      });
    } else {
      const toProcessPlatformData = this.state.PlatFormList;
      let PlatformData = [];
      let DrawResultCheck = '';
      for (let i = 0; i < toProcessPlatformData.length; i++) {
        PlatformData.push(toProcessPlatformData[i].PlatForm);
      }
      PlatformData.forEach(itemPlatform => {
        const PlatFormHead = itemPlatform;
        let FirstToThirdResultData = [];
        let SpecialResultData = [];
        let ConsolationResultData = [];
        if (this.props.UserAndDownlineData[0].GameDisplay === 'MPTSWBK') {
          itemPlatform = itemPlatform
            .slice(',')
            .replace(/M/g, '1')
            .replace(/P/g, '2')
            .replace(/T/g, '3')
            .replace(/S/g, '4')
            .replace(/W/g, '5')
            .replace(/B/g, '6')
            .replace(/K/g, '7');
        } else {
          itemPlatform = itemPlatform
            .slice(',')
            .replace(/M/g, '1')
            .replace(/K/g, '2')
            .replace(/T/g, '3')
            .replace(/S/g, '4')
            .replace(/W/g, '5')
            .replace(/B/g, '6')
            .replace(/D/g, '7');
        }
        arrayToRepeat.forEach(item => {
          DrawResultCheck = BTResultData[itemPlatform][item] || 'XXXX';
          if (item === 'P1' || item === 'P2' || item === 'P3') {
            if (DrawResultCheck !== '----') {
              FirstToThirdResultData.push(DrawResultCheck);
            }
          } else if (item >= 1 && item <= 13) {
            if (DrawResultCheck !== '----') {
              SpecialResultData.push(DrawResultCheck);
            }
          } else if (item >= 14 && item <= 23) {
            if (DrawResultCheck !== '----') {
              ConsolationResultData.push(DrawResultCheck);
            }
          }
        });
        let FirstToThird = '';
        let Special1 = '';
        let Special2 = '';
        let Consolation = '';
        if (DrawResultCheck.replace(/X/g, '').length === 0) {
          FirstToThird = '=' + PlatFormHead + '=\n' + 'No Result';
        } else {
          FirstToThird =
            '=' +
            PlatFormHead +
            '=\n' +
            '1) ' +
            FirstToThirdResultData[0] +
            '\n2) ' +
            FirstToThirdResultData[1] +
            '\n3) ' +
            FirstToThirdResultData[2];
          Special1 =
            '\n4)\n' +
            SpecialResultData[0] +
            ' ' +
            SpecialResultData[1] +
            ' ' +
            SpecialResultData[2] +
            ' ' +
            SpecialResultData[3] +
            '\n' +
            SpecialResultData[4] +
            ' ' +
            SpecialResultData[5] +
            ' ' +
            SpecialResultData[6] +
            ' ' +
            SpecialResultData[7] +
            '\n' +
            SpecialResultData[8] +
            ' ' +
            SpecialResultData[9] +
            ' ' +
            [SpecialResultData[10] || ''] +
            ' ' +
            [SpecialResultData[11] || ''];
          Special2 =
            '\n4)\n' +
            SpecialResultData[0] +
            ' ' +
            SpecialResultData[1] +
            ' ' +
            SpecialResultData[2] +
            ' ' +
            SpecialResultData[3] +
            '\n' +
            SpecialResultData[4] +
            ' ' +
            SpecialResultData[5] +
            ' ' +
            SpecialResultData[6] +
            ' ' +
            SpecialResultData[7] +
            '\n' +
            SpecialResultData[8] +
            ' ' +
            SpecialResultData[9];
          Consolation =
            '\n5)\n' +
            ConsolationResultData[0] +
            ' ' +
            ConsolationResultData[1] +
            ' ' +
            ConsolationResultData[2] +
            ' ' +
            ConsolationResultData[3] +
            '\n' +
            ConsolationResultData[4] +
            ' ' +
            ConsolationResultData[5] +
            ' ' +
            ConsolationResultData[6] +
            ' ' +
            ConsolationResultData[7] +
            '\n' +
            ConsolationResultData[8] +
            ' ' +
            ConsolationResultData[9];
        }

        if (itemPlatform === '1') {
          this.setState({MagBTResult: FirstToThird + Special1 + Consolation});
        } else if (itemPlatform === '2') {
          this.setState({DmcBTResult: FirstToThird + Special2 + Consolation});
        } else if (itemPlatform === '3') {
          let tFiveDResult = '';
          // if toto is Empty
          if (DrawResultCheck.replace(/X/g, '').length === 0) {
            tFiveDResult = '';
          } else {
            tFiveDResult =
              '\n5D1) ' +
              (BTResultData[itemPlatform]['5D1'] || 'XXXXX') +
              '\n5D2) ' +
              (BTResultData[itemPlatform]['5D2'] || 'XXXXX') +
              '\n5D3) ' +
              (BTResultData[itemPlatform]['5D3'] || 'XXXXX') +
              '\n6D) ' +
              (BTResultData[itemPlatform]['6D1'] || 'XXXXXX');
          }
          this.setState({
            TotoBTResult: FirstToThird + Special1 + Consolation + tFiveDResult,
          });
        } else if (itemPlatform === '4') {
          this.setState({Sg4BTResult: FirstToThird + Special1 + Consolation});
        } else if (itemPlatform === '5') {
          this.setState({SwkBTResult: FirstToThird + Special2 + Consolation});
        } else if (itemPlatform === '6') {
          this.setState({SbhBTResult: FirstToThird + Special1 + Consolation});
        } else if (itemPlatform === '7') {
          this.setState({SdkBTResult: FirstToThird + Special1 + Consolation});
        } else if (itemPlatform === '8') {
          this.setState({
            Bt4BTResult:
              FirstToThird +
              Special1 +
              Consolation +
              '\n5D1) ' +
              (BTResultData[itemPlatform]['5D1'] || 'XXXXX') +
              '\n5D2) ' +
              (BTResultData[itemPlatform]['5D2'] || 'XXXXX') +
              '\n5D3) ' +
              (BTResultData[itemPlatform]['5D3'] || 'XXXXX') +
              '\n6D) ' +
              (BTResultData[itemPlatform]['6D1'] || 'XXXXXX') +
              '\n6D+1) ' +
              ((BTResultData[itemPlatform]['7D1'] || '').substring(0, 6) || 'XXXXXX') +
              '+' +
              ((BTResultData[itemPlatform]['7D1'] || '').substring(6, 7) || 'X'),
          });
        } else if (itemPlatform === '9') {
          this.setState({
            NineBTResult:
              FirstToThird +
              Special1 +
              Consolation +
              '\n6D) ' +
              (BTResultData[itemPlatform]['6D1'] || 'XXXXXX') +
              '\n6D+1) ' +
              ((BTResultData[itemPlatform]['7D1'] || '').substring(0, 6) || 'XXXXXX') +
              '+' +
              ((BTResultData[itemPlatform]['7D1'] || '').substring(6, 7) || 'X'),
          });
        }
      });
    }
    this.FilterResult();
  }

  FilterResult() {
    const toProcessPlatformData = this.state.PlatFormList;
    const getPlatform2 =
      this.props.UserAndDownlineData[0].GameDisplay.substring(1, 2) || '';
    const getPlatform7 =
      this.props.UserAndDownlineData[0].GameDisplay.substring(6, 7);
    let FilterResultData = [];
    for (let i = 0; i < toProcessPlatformData.length; i++) {
      if (toProcessPlatformData[i].CheckMark === true) {
        let ConvertPlatForm = toProcessPlatformData[i].PlatForm;
        if (ConvertPlatForm === 'M') {
          FilterResultData.push(this.state.MagBTResult + '\n');
        } else if (
          (ConvertPlatForm === 'K' && getPlatform2 === 'K') ||
          (ConvertPlatForm === 'P' && getPlatform2 === 'P')
        ) {
          FilterResultData.push(this.state.DmcBTResult + '\n');
        } else if (ConvertPlatForm === 'T') {
          FilterResultData.push(this.state.TotoBTResult + '\n');
        } else if (ConvertPlatForm === 'S') {
          FilterResultData.push(this.state.Sg4BTResult + '\n');
        } else if (ConvertPlatForm === 'W') {
          FilterResultData.push(this.state.SwkBTResult + '\n');
        } else if (ConvertPlatForm === 'B') {
          FilterResultData.push(this.state.SbhBTResult + '\n');
        } else if (
          (ConvertPlatForm === 'D' && getPlatform7 === 'D') ||
          (ConvertPlatForm === 'K' && getPlatform7 === 'K')
        ) {
          FilterResultData.push(this.state.SdkBTResult + '\n');
        } else if (ConvertPlatForm === '8') {
          FilterResultData.push(this.state.Bt4BTResult + '\n');
        } else if (ConvertPlatForm === '9') {
          FilterResultData.push(this.state.NineBTResult);
        }
      }
    }
    this.setState({DisplayBTResult: FilterResultData, loading: false});
  }

  async CopyResultFunction() {
    try {
      const Result = `Date: ${moment(this.state.SelectedDate).format('DD/MM')}\n${this.state.DisplayBTResult}`
      await navigator.clipboard.writeText(Result.toString()
        .replace(/,/g, ''));
      alert(
        language[this.props.currentLanguage].DoneCopy,
      );
    } catch{
      alert(
        language[this.props.currentLanguage].FailedCopy,
      );
    }
    
  }

  render() {
    return (
      <body style={{ width: '100%' }}> 
        <div className='box'>
          <div className='BT-HEAD'> 
            <div className='PageHeaderMainContainer'>
              <div className='PageHeaderLeftContainer'>
                <div className='PageHeaderLeftBackButton'
                  onClick={() => this.props.history.goBack()}>
                  {language[this.props.currentLanguage].Back}
                </div>
              </div>
              <div className='PageHeaderMiddleContainer'>
                {language[this.props.currentLanguage].DrawResult}
              </div>
              <div className='PageHeaderRightContainer'>
              </div>
            </div>
            <div className='PlatformListContainer' style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto'}}>
              <div style ={{ flexDirection: 'column', width: '100%'}}>
                <div style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '20px'}}>{language[this.props.currentLanguage].DrawDate}</div>
                <div className='resultDateText'>
                  <DatePicker
                    className='inputPicker'
                    onChange={(date) => this.setSelectedDate(date, 'SelectedDate')}
                    maxDate={new Date()}
                    placeholder="Date"
                    value={`${moment(this.state.SelectedDate).format('DD/MM/YYYY')} (${language[this.props.currentLanguage].PressToChange})`}
                    withPortal
                    onFocus={(e) => e.target.readOnly = true}
                  />
                </div>
              </div>
              {this.state.PlatFormList.map((items, index) => {
                return (
                  <button className='PlatformButtonContainer' 
                    key={index}
                    onClick={() => this.PlatformCheckboxSelect(index)}>
                    <img className="PlatformListIcon" src={items.ImageUri} alt="PlatformLogo"/>
                    {items.CheckMark && (
                      <img className="PlatformListCheckmarkIcon" src={CheckmarkIcon} alt="CheckmarkIcon"/>
                    ) || (<div className='PlatformListCheckmarkIconText'> {items.PlatForm}</div>)}
                  </button>
                )
              })}
            </div>
          </div>
          <div className='BT-body' style={{ width: '100%', marginTop: 10, alignItems: 'center', marginBottom: 5 }}>
            <div style={{ 
              border: "1px solid black", 
              height: '100%', 
              width: '98%', 
              whiteSpace: 'pre-wrap', 
              overflowY: 'auto', 
              fontSize: '18px',
              marginLeft: 'auto',
              marginRight: 'auto' }}>
              {this.state.loading ? (
                <div style={{ textAlign: 'center' }}>
                  <img class = "loadingSize" src={LoadingLogo} alt="BigCo Inc. logo"/>
                </div>
              ) : (
                <div style={{ fontWeight: 'bold', textAlign: 'left', padding: 5 }}>
                Date : {moment(this.state.SelectedDate).format('DD/MM')}
                  {'\n'}
                  {this.state.DisplayBTResult}
                </div>
              )}
            </div>
          </div>
          <div className='resultFooter'
            style={{ width: '100%', border: "1px solid black", fontWeight: 'bold', fontSize: '18px', justifyContent: 'center' }}
            onClick={() =>this.CopyResultFunction()}>
            {language[this.props.currentLanguage].Copy}
          </div>
        </div>
      </body> 
    );
  }
}

function mapStateToProps(state) {
  return {
    id: state.id,
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    Date: state.checkResultdate,
    currentLanguage: state.currentLanguage,
    UserAndDownlineData: state.UserAndDownlineData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCheckResultInfo: (SelectedDate, Platformlist) => {
      dispatch({
        type: 'CHECK_RESULT',
        payload: {
          checkResultdate: SelectedDate,
          checkResultPlatForm: Platformlist,
        },
      });
    },
    setLoginStatus: (loginStatus, ID, Username, UserToken, role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          login: loginStatus,
          id: ID,
          username: Username,
          token: UserToken,
          role: role,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Result);
  