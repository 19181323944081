/* eslint-disable no-mixed-operators */
import '../App.css';
// import JSEncrypt from 'jsencrypt';
import React from 'react';
import { connect } from 'react-redux';
import {language} from '../language';
import LoadingLogo from '../img/loading.gif';
import moment from 'moment-timezone';
import Modal from 'react-bootstrap/Modal';
import CheckmarkIcon from '../img/Checkmark.png';
import CalendarIcon from '../img/CalendarIcon.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const { CreateBetOrder, CancelBet, getAutoReceipt, CreateBetOrderSGD, CreateBetOrderTHB} = require('../Api');

class displayLongtermReceipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      loading: false,
      ReceiptNumber: '',
      ReceiptData: language[this.props.currentLanguage].NoTicket,
      ReceiptDataList: [],
      isDatePickerFromVisible: false,
      isDatePickerToVisible: false,
      SelectModeModalVisible: false,
      SelectReceiptModalVisible: false,
      SelectedDateFrom: moment().format('YYYY-MM-DD'),
      SelectedDateTo: moment().format('YYYY-MM-DD'),
      ReceiptQuantity: 0,
      SelectedNum: 0,
      TotalNum: 0,
      TicketStatus: false,
      RebuyDateBy: '',
      ProcessData: language[this.props.currentLanguage].NoTicket,
      lineData: [
        {
          originalWord: '',
          translatedWord: '',
          Mode: '',
        },
      ],
      DateBy: [
        {
          CheckMark: true,
          Display: language[this.props.currentLanguage].Bet,
          Name: 'Receipt',
        },
        {
          CheckMark: false,
          Display: language[this.props.currentLanguage].Open,
          Name: 'Bet',
        },
      ],
      SearchBy: [
        {
          CheckMark: true,
          displayName: '(#)ID',
          Name: 'OrderID',
        },
        {
          CheckMark: false,
          displayName: '(S)ID',
          Name: 'ServerID',
        },
      ],
      deviceListModal: false,
      deviceList: [],
      reBuyOption: [],
      RebuyIDType: '',
    };
  }

  async LogoutFunction() {
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('AbleRed');
    sessionStorage.removeItem('FirstTimeLogin');
    sessionStorage.removeItem('Username');
    sessionStorage.removeItem('loginStatus');
    sessionStorage.removeItem('getUserDownlineData');
    sessionStorage.removeItem('ReceiptData');
    sessionStorage.removeItem('UserIDForSearch');
    sessionStorage.removeItem('UserForSearch');
    sessionStorage.removeItem('SearchDateFrom');
    sessionStorage.removeItem('SearchDateTo');
    sessionStorage.removeItem('PatternForSearch');
    sessionStorage.removeItem('PlatformForSearch');
    sessionStorage.removeItem('ReportData');
    this.props.setLoginStatus(false, '', '', '', '');
  }

  async setSelectModeModalVisible() {
    const CheckVisible = this.state.SelectModeModalVisible;
    if (CheckVisible === true) {
      this.setState({SelectModeModalVisible: false});
    } else {
      this.setState({SelectModeModalVisible: true});
    }
  }

  async setDeviceListeModalVisible() {
    const CheckVisible = this.state.deviceListModal;
    if (CheckVisible === true) {
      this.setState({deviceListModal: false});
    } else {
      this.setState({deviceListModal: true});
    }
  }

  async setSelectReceiptModalVisible() {
    const CheckVisible = this.state.SelectReceiptModalVisible;
    if (CheckVisible === true) {
      this.setState({SelectReceiptModalVisible: false});
    } else {
      this.setState({SelectReceiptModalVisible: true});
    }
  }

  onPressShowDateFrom() {
    this.setState({isDatePickerFromVisible: true});
  }
  onPressShowDateTo() {
    this.setState({isDatePickerToVisible: true});
  }

  async ProcessRebuyOption() {
    const rebuyOption = this.state.reBuyOption;
    rebuyOption.push(language[this.props.currentLanguage].Ticket);
    if (this.props.AbleRed === 1) {
      rebuyOption.push(language[this.props.currentLanguage].RedTicket);
    }
    if (this.props.AbleSGD === 1) {
      rebuyOption.push(language[this.props.currentLanguage].Ticket + '(SGD)');
    }
    if (this.props.AbleTHB === 1) {
      rebuyOption.push(language[this.props.currentLanguage].Ticket + '(THB)');
    }
    rebuyOption.push(language[this.props.currentLanguage].SimpleBT);
    this.setState({reBuyOption: rebuyOption});
  } 

  async componentDidMount() {
    if (this.props.UserAndDownlineData === '') {
      this.props.history.push('/')
    }
    this.ProcessRebuyOption();
    if (this.props.oldTicketIndex !== '') {
      if (this.props.oldTicketIDType === 'SID') {
        this.SearhByCheckboxSelect(1);
      } else {
        this.SearhByCheckboxSelect(0);
      }
      this.setState({
        ReceiptNumber: this.props.oldTicketNumber,
      }, ()=> this.onPressCheckReceipt());
    }
  }

  async ShareWord() {
    try {
      navigator.clipboard.writeText(this.state.ReceiptData.toString()
        .replace(/<red>/g, '')
        .replace(/<\/red>/g, ''));
      alert(
        language[this.props.currentLanguage].DoneCopy,
      ); } catch{
      alert(
        language[this.props.currentLanguage].FailedCopy,
      );
    }
  }

  async onPressCheckReceipt() {
    this.setState({loading: true});
    const SearchBy = this.state.SearchBy;
    let ReceiptText = '';
    let Status = '';
    let GetReceiptData = '';
    let CheckingReceipt = '';
    let RebuyID = '';
    if (SearchBy[0].CheckMark === true) {
      RebuyID = 'JingID';
      GetReceiptData = await getAutoReceipt(
        this.props.UserIDForSearch,
        this.state.ReceiptNumber,
        '',
        this.props.token,
        this.props.username,
      );
    } else {
      RebuyID = 'SID';
      GetReceiptData = await getAutoReceipt(
        this.props.UserIDForSearch,
        '',
        this.state.ReceiptNumber,
        this.props.token,
        this.props.username,
      );
    }
    if (GetReceiptData === 'TimeOut') {
      this.LogoutFunction();
    }
    const GetReceiptList = GetReceiptData.List || '';
    if (this.props.oldTicketIndex !== 0 && this.props.oldTicketIndex !== '') {
      if (GetReceiptData.length > 0) {
        GetReceiptData = GetReceiptData.slice(0).reverse();
        let currentLength = '';
        let oldIndex = '';
        if (GetReceiptData.length > this.props.oldTicketTotal) {
          currentLength = this.props.oldTicketIndex + 2;
          oldIndex = this.props.oldTicketIndex + 1;
        } else {
          currentLength = this.props.oldTicketIndex + 1;
          oldIndex = this.props.oldTicketIndex;
        }
        CheckingReceipt =
          GetReceiptData[oldIndex].LatestReceiptText || GetReceiptData[oldIndex].ReceiptText;
        if (GetReceiptData[oldIndex].Status === 'Cancelled') {
          Status = false;
          ReceiptText = CheckingReceipt;
        } else {
          Status = true;
          ReceiptText = CheckingReceipt;
        }
        this.setState({
          RebuyIDType: RebuyID,
          SelectedNum: currentLength,
          TotalNum: GetReceiptData.length,
          ReceiptDataList: GetReceiptData,
          ReceiptData: ReceiptText,
          TicketStatus: Status,
          loading: false,
        });
        this.RenderWord(ReceiptText);
      } else if (GetReceiptList !== '') {
        CheckingReceipt =
          GetReceiptData.List.LatestReceiptText ||
          GetReceiptData.List.ReceiptText;
        if (GetReceiptData.List.Status === 'Cancelled') {
          Status = false;
          ReceiptText = CheckingReceipt;
        } else {
          Status = true;
          ReceiptText = CheckingReceipt;
        }
        this.setState({
          RebuyIDType: RebuyID,
          SelectedNum: 1,
          TotalNum: 1,
          ReceiptDataList: [GetReceiptData.List],
          ReceiptData: ReceiptText,
          TicketStatus: Status,
          loading: false,
        });
        this.RenderWord(ReceiptText);
      } else {
        this.setState({
          ReceiptData: language[this.props.currentLanguage].NoTicket,
          SelectedNum: 0,
          TotalNum: 0,
          loading: false,
          ProcessData: language[this.props.currentLanguage].NoTicket,
          ReceiptDataList: [],
        });
      }
    } else {
      if (GetReceiptData.length > 0) {
        GetReceiptData = GetReceiptData.slice(0).reverse();
        CheckingReceipt =
          GetReceiptData[0].LatestReceiptText || GetReceiptData[0].ReceiptText;
        if (GetReceiptData[0].Status === 'Cancelled') {
          Status = false;
          ReceiptText = CheckingReceipt;
        } else {
          Status = true;
          ReceiptText = CheckingReceipt;
        }
        this.setState({
          RebuyIDType: RebuyID,
          SelectedNum: 1,
          TotalNum: GetReceiptData.length,
          ReceiptDataList: GetReceiptData,
          ReceiptData: ReceiptText,
          TicketStatus: Status,
          loading: false,
        });
        this.RenderWord(ReceiptText);
      } else if (GetReceiptList !== '') {
        CheckingReceipt =
          GetReceiptData.List.LatestReceiptText ||
          GetReceiptData.List.ReceiptText;
        if (GetReceiptData.List.Status === 'Cancelled') {
          Status = false;
          ReceiptText = CheckingReceipt;
        } else {
          Status = true;
          ReceiptText = CheckingReceipt;
        }
        this.setState({
          RebuyIDType: RebuyID,
          SelectedNum: 1,
          TotalNum: 1,
          ReceiptDataList: [GetReceiptData.List],
          ReceiptData: ReceiptText,
          TicketStatus: Status,
          loading: false,
        });
        this.RenderWord(ReceiptText);
      } else {
        this.setState({
          ReceiptData: language[this.props.currentLanguage].NoTicket,
          SelectedNum: 0,
          TotalNum: 0,
          loading: false,
          ProcessData: language[this.props.currentLanguage].NoTicket,
          ReceiptDataList: [],
        });
      }
    }
    sessionStorage.setItem('oldTicketDate', '');
    sessionStorage.setItem('oldTicketDateBy',  '');
    sessionStorage.setItem('oldTicketNumber',  '');
    sessionStorage.setItem('oldTicketIDType',  '');
    sessionStorage.setItem('oldTicketIndex',  '');
    sessionStorage.setItem('oldTicketTotal',  '');
    this.props.setOldTicketInfo('', '', '', '', '', '');
  }
  
  async onpressRebuy() {
    if (this.state.TotalNum !== 0) {
      if (window.confirm(language[this.props.currentLanguage].RebuyConfirmation)) {
        this.RebuyFunction();
      }
    } else {
      alert(
        language[this.props.currentLanguage].NoTicket,
      );
    }
  }

  async RebuyFunction() {
    const {navigation} = this.props;
    const ReceiptData = this.state.ReceiptDataList[this.state.SelectedNum - 1];
    const OriwordArray = [];
    if (this.state.TotalNum !== 0) {
      this.setState({loading: true});
      sessionStorage.setItem('oldTicketDate', '');
      sessionStorage.setItem('oldTicketDateBy',  '');
      sessionStorage.setItem('oldTicketNumber',  this.state.ReceiptNumber);
      sessionStorage.setItem('oldTicketIDType',  this.state.RebuyIDType);
      sessionStorage.setItem('oldTicketIndex',  this.state.SelectedNum - 1);
      sessionStorage.setItem('oldTicketTotal',  this.state.TotalNum);
      this.props.setOldTicketInfo(
        '',
        '',
        this.state.ReceiptNumber,
        this.state.RebuyIDType,
        this.state.SelectedNum - 1,
        this.state.TotalNum,
      );
      const OriText = ReceiptData.OriginalText;
      let ToprocessOriData = OriText.split('\n');
      let toProcessLineData = this.state.lineData;
      for (let i = 0; i < ToprocessOriData.length; i++) {
        const CheckNull = toProcessLineData[i] || '';
        if (CheckNull !== '') {
          toProcessLineData[0].originalWord = 'D#*';
        } else {
          toProcessLineData.push({
            originalWord: ToprocessOriData[i],
            translatedWord: '',
            Mode: '',
          });
        }
      }
      for (var i = 0; i < toProcessLineData.length; i++) {
        OriwordArray.push(toProcessLineData[i].originalWord + '\n');
      }
      let OrderReceiptData = '';
      if (ReceiptData.Red === 1) {
        OrderReceiptData = await CreateBetOrder(
          this.props.UserIDForSearch,
          OriwordArray.join(''),
          this.props.token,
          this.props.username,
          true,
          true,
          '',
          'mobile',
        );
      } else if (ReceiptData.SGD === 1) {
        OrderReceiptData = await CreateBetOrderSGD(
          this.props.UserIDForSearch,
          OriwordArray.join(''),
          this.props.token,
          this.props.username,
          true,
          true,
          '',
          'mobile',
        );
      } else if (ReceiptData.THAI === 1) {
        OrderReceiptData = await CreateBetOrderTHB(
          this.props.UserIDForSearch,
          OriwordArray.join(''),
          this.props.token,
          this.props.username,
          true,
          true,
          '',
          'mobile',
        );
      } else {
        OrderReceiptData = await CreateBetOrder(
          this.props.UserIDForSearch,
          OriwordArray.join(''),
          this.props.token,
          this.props.username,
          false,
          true,
          '',
          'mobile',
        );
      }
      if (OrderReceiptData.error === 'User No Package') {
        alert(
          language[this.props.currentLanguage].NoBetPackage,
        );
        this.setState({loading: false});
      } else if (OrderReceiptData.error === 'No Date to Bet') {
        alert(
          language[this.props.currentLanguage].NoDateBet,
        );
        this.setState({loading: false});
      } else if (OrderReceiptData.error === '5D6D in wrong game') {
        alert(
          language[this.props.currentLanguage].Wrong5D6D,
        );
        this.setState({loading: false});
      } else if (OrderReceiptData.error === 'Date error') {
        alert(
          language[this.props.currentLanguage].BuyDateWarn,
        );
        this.setState({loading: false});
      } else if (OrderReceiptData.error === 'Not enough date') {
        alert(
          language[this.props.currentLanguage].NoEnufDate,
        );
        this.setState({loading: false});
      } else if (OrderReceiptData.error === 'Game ShutDown') {
        alert(
          language[this.props.currentLanguage].GameShutDown,
        );
        this.setState({loading: false});
      } else if (OrderReceiptData.error === 'S Game ShutDown') {
        alert(
          language[this.props.currentLanguage].SGameShutDown,
        );
        this.setState({loading: false});
      } else if (OrderReceiptData.error === 'No Game on that day') {
        alert(
          language[this.props.currentLanguage].DateNoAvailable,
        );
        this.setState({loading: false});
      } else if (OrderReceiptData.error === 'Platform error') {
        alert(
          language[this.props.currentLanguage].PlatFormErr,
        );
        this.setState({loading: false});
      } else if (OrderReceiptData.error === 'User No Able Red') {
        alert(
          language[this.props.currentLanguage].UserNoRed,
        );
        this.setState({loading: false});
      } else if (OrderReceiptData.error === 'Account Inactive') {
        alert(
          language[this.props.currentLanguage].AccountInactive,
        );
        this.setState({loading: false});
      } else if (OrderReceiptData.error === 'Exceed Personal Daily Limit') {
        alert(
          language[this.props.currentLanguage].ExceedPerLimit,
        );
        this.setState({loading: false});
      } else if (
        OrderReceiptData.error === 'User Red Lower Than Commission'
      ) {
        alert(
          'User Red Lower Than Commission',
        );
        this.setState({loading: false});
      } else if (OrderReceiptData.error === 'MKTSWBD ShutDown') {
        alert(
          language[this.props.currentLanguage].MKTSWBDoff,
        );
        this.setState({loading: false});
      } else if (OrderReceiptData.error === 'All Game ShutDown') {
        alert(
          language[this.props.currentLanguage].AllGameoff,
        );
        this.setState({loading: false});
      } else if (OrderReceiptData.error === 'Repeat Order') {
        alert(language[this.props.currentLanguage].RepeatOrder,);
        this.setState({loading: false});
      } else if (OrderReceiptData.error === 'Over Number Limit') {
        alert(
          language[this.props.currentLanguage].OverNumberLimit,
        );
        this.setState({loading: false});
      } else if (OrderReceiptData.error === 'Format Error') {
        alert(language[this.props.currentLanguage].FormatError);
        this.setState({loading: false});
      } else if (OrderReceiptData.error === 'User Negative Commission') {
        alert(
          language[this.props.currentLanguage].UserNegativeCommission,
        );
        this.setState({loading: false});
      } else if (OrderReceiptData.error === 'Cannot bet game desired') {
        alert(
          language[this.props.currentLanguage].GameDesired,
        );
        this.setState({loading: false});
      } else if (OrderReceiptData.error === 'Not enough cash wallet') {
        alert(
          'Balance:' +
            OrderReceiptData.balance +
            '\n' +
          language[this.props.currentLanguage].noEnufCashWall,
        );
        this.setState({loading: false});
      } else if (OrderReceiptData.error) {
        alert(
          OrderReceiptData.error,
        );
        this.setState({loading: false});
      } else if (OrderReceiptData === 'TimeOut') {
        this.LogoutFunction();
      } else {
        this.props.setReceiptData(OrderReceiptData, 'ReBuy');
        this.setState({loading: false});
        this.props.history.push('/receiptAfterSubmit');
      }
      // } else {
      //   this.setState({loading: false, SelectModeModalVisible: true});
      // }
    } else {
      alert(
        language[this.props.currentLanguage].NoTicket,
      );
    }
  }
  async onpressEditRebuy() {
    if (this.state.TotalNum !== 0) {
      this.setState({SelectModeModalVisible: true});
    } else {
      alert(
        language[this.props.currentLanguage].NoTicket,
      );
    }
  }
  async onpressCancel() {
    if (this.state.TotalNum !== 0) {
      if ( this.state.TicketStatus === true) {
        if (window.confirm(language[this.props.currentLanguage].CancelConfirmation)) {
          this.CancelReceiptFunction();
        }
  
      } else {
        if (window.confirm(language[this.props.currentLanguage].RebuyConfirmation)) {
          this.RecallReceiptFunction();
        }
      }
    } else {
      alert(
        language[this.props.currentLanguage].NoTicket
      );
    }
  }
  async CancelReceiptFunction() {
    const GetReceiptData = this.state.ReceiptDataList;
    const IDChecking =
      GetReceiptData[this.state.SelectedNum - 1].LatestOrderNo ||
      GetReceiptData[this.state.SelectedNum - 1].ID;
    this.setState({
      loading: true,
      ReceiptNumber: '',
    });
    const CancelResult = await CancelBet(
      IDChecking,
      this.props.token,
      this.props.username,
    );
    if (CancelResult.error === 'Exceed Date' || CancelResult.error === 'Ticket expired') {
      alert(
        language[this.props.currentLanguage].CancelExceedDate,
      );
      this.setState({loading: false});
    } else if (CancelResult.error === 'database  error') {
      alert(
        CancelResult.error,
      );
      this.setState({loading: false});
    } else if (CancelResult.error === 'OverTimeLimit') {
      alert(
        language[this.props.currentLanguage].CancelOverDayTime,
      );
      this.setState({loading: false});
    } else if (CancelResult.error === 'DontHaveAutho') {
      alert(
        language[this.props.currentLanguage].NoAuthoCancel,
      );
      this.setState({loading: false});
    } else if (CancelResult.error === 'Invalid ServerID') {
      alert(
        'Invalid ServerID',
      );
      this.setState({loading: false});
    } else if (CancelResult.error === 'AlreadyCancalled') {
      alert(
        language[this.props.currentLanguage].AlreadyCancel,
      );
      this.setState({loading: false});
    } else if (CancelResult.error === 'Order not finished yet, please wait for awhile') {
      alert(
        language[this.props.currentLanguage].WaitOrderFinish,
      );
      this.setState({loading: false});
    } else if (CancelResult.error) {
      alert(
        CancelResult.error,
      );
      this.setState({loading: false});
    } else if (CancelResult === 'TimeOut') {
      this.LogoutFunction();
    } else {
      this.onPressCheckReceipt();
    }
  }

  async SelectModeRebuy(Mode) {
    const OriText =
      this.state.ReceiptDataList[this.state.SelectedNum - 1].OriginalText;
    let ToprocessOriData = OriText.split('\n');
    let toProcessLineData = this.state.lineData;
    let AmountCopy = '';
    let CopyOrNot = false;
    const CheckNan = ToprocessOriData.filter(
      item => item.startsWith('#') && item.split('#').length === 2,
    );
    if (CheckNan.length > 0) {
      //JinNan Edit Rebuy
      for (let i = 0; i < ToprocessOriData.length; i++) {
        const CheckNull = toProcessLineData[i] || '';
        if (CheckNull !== '') {
          toProcessLineData[0].originalWord = 'D#*';
        } else {
          let OriWord = ToprocessOriData[i].replace(/([.])/g, '*');
          let JingNanOri = ToprocessOriData[i].replace(/([.])/g, '*');
          if (OriWord.startsWith('#') && OriWord.split('#').length === 2) {
            CopyOrNot = true;
          }
          if (OriWord.startsWith('##')) {
            OriWord = OriWord.replace('##', '');
          } else if (OriWord.startsWith('#')) {
            OriWord = OriWord.replace('#', '');
          }
          const OriWordArray = OriWord.split('#');
          if (
            CopyOrNot === true &&
            OriWordArray.length >= 2 &&
            OriWordArray[0] !== ''
          ) {
            AmountCopy = ToprocessOriData[i].substring(
              ToprocessOriData[i].indexOf('#'),
            );
            OriWord = ToprocessOriData[i].replace(/([.])/g, '*');
          } else if (
            JingNanOri.startsWith('#') &&
            JingNanOri.split('#').length === 2
          ) {
            OriWord = ToprocessOriData[i].replace(/([.])/g, '*');
          } else if (
            OriWordArray[0].length >= 3 &&
            OriWordArray[0].length <= 6
          ) {
            OriWord = ToprocessOriData[i].replace(/([.])/g, '*') + AmountCopy;
          } else {
            if (
              Mode === language[this.props.currentLanguage].SimpleBT &&
              ToprocessOriData[i].startsWith('#') &&
              ToprocessOriData[i].split('#').length === 2
            ) {
              OriWord = ToprocessOriData[i].replace('#', '');
            } else {
              OriWord = ToprocessOriData[i].replace(/([.])/g, '*');
            }
          }
          toProcessLineData.push({
            originalWord: OriWord,
            translatedWord: '',
            Mode: '',
          });
        }
      }
    } else {
      for (let i = 0; i < ToprocessOriData.length; i++) {
        const CheckNull = toProcessLineData[i] || '';
        if (CheckNull !== '') {
          toProcessLineData[0].originalWord = 'D#*';
        } else {
          let OriWord = ToprocessOriData[i].replace(/([.])/g, '*');
          if (OriWord.startsWith('##')) {
            OriWord = OriWord.replace('##', '');
          } else if (OriWord.startsWith('#')) {
            OriWord = OriWord.replace('#', '');
          }
          const CheckOnlyTag = OriWord.substring(OriWord.indexOf('#'));
          const OriWordArray = OriWord.split('#');
          if (OriWordArray.length >= 2 && CheckOnlyTag !== '#') {
            AmountCopy = ToprocessOriData[i].substring(
              ToprocessOriData[i].indexOf('#') + 1,
            );
            OriWord = ToprocessOriData[i].replace(/([.])/g, '*');
          } else if (OriWordArray.length === 2 && CheckOnlyTag === '#') {
            OriWord = ToprocessOriData[i].replace(/([.])/g, '*') + AmountCopy;
          } else {
            OriWord = ToprocessOriData[i].replace(/([.])/g, '*');
          }
          toProcessLineData.push({
            originalWord: OriWord,
            translatedWord: '',
            Mode: '',
          });
        }
      }
    }
    this.props.SetRebuyData(
      toProcessLineData,
      this.props.UserIDForSearch,
      this.props.UserForSearch,
    );
    sessionStorage.setItem('oldTicketDate', '');
    sessionStorage.setItem('oldTicketDateBy', '');
    sessionStorage.setItem('oldTicketNumber',  this.state.ReceiptNumber);
    sessionStorage.setItem('oldTicketIDType',  this.state.RebuyIDType);
    sessionStorage.setItem('oldTicketIndex',  this.state.SelectedNum - 1);
    sessionStorage.setItem('oldTicketTotal',  this.state.TotalNum);
    this.props.setOldTicketInfo(
      '',
      '',
      this.state.ReceiptNumber,
      this.state.RebuyIDType,
      this.state.SelectedNum - 1,
      this.state.TotalNum,
    );
    if (Mode === language[this.props.currentLanguage].Ticket) {
      this.props.history.push('/Betting');
    } else if (Mode === language[this.props.currentLanguage].RedTicket) {
      this.props.history.push('/bettingRedbill');
    } else if (Mode === language[this.props.currentLanguage].SimpleBT) {
      this.props.history.push('/SimpleBT');
    } else if (
      Mode ===
      language[this.props.currentLanguage].Ticket + '(SGD)'
    ) {
      this.props.history.push('/bettingSGD');
    } else if (
      Mode ===
      language[this.props.currentLanguage].Ticket + '(THB)'
    ) {
      this.props.history.push('/bettingTHB');
    }
  }

  ReceiptNumberHandleText = text => {
    this.setState({ReceiptNumber: text});
  };
  async NextReceiptButton() {
    const GetReceiptData = this.state.ReceiptDataList;
    let Status = '';
    let CheckingReceipt = '';
    if (this.state.TotalNum > this.state.SelectedNum) {
      if (GetReceiptData[this.state.SelectedNum].Status === 'Cancelled') {
        CheckingReceipt =
          GetReceiptData[this.state.SelectedNum].LatestReceiptText ||
          GetReceiptData[this.state.SelectedNum].ReceiptText;
        Status = false;
        this.setState({
          SelectedNum: this.state.SelectedNum + 1,
          ReceiptData: CheckingReceipt,
          TicketStatus: Status,
        });
        this.RenderWord(CheckingReceipt);
      } else {
        CheckingReceipt =
        GetReceiptData[this.state.SelectedNum].LatestReceiptText ||
        GetReceiptData[this.state.SelectedNum].ReceiptText;
        Status = true;
        this.setState({
          SelectedNum: this.state.SelectedNum + 1,
          ReceiptData: CheckingReceipt,
          TicketStatus: Status,
        });
        this.RenderWord(CheckingReceipt);
      }
    } else {
      alert(
        language[this.props.currentLanguage].NoMore
      );
    }
  }
  async PreviousReceiptButton() {
    const GetReceiptData = this.state.ReceiptDataList;
    let CheckingReceipt = '';
    if (this.state.SelectedNum === 1) {
      alert(
        language[this.props.currentLanguage].NoMore
      );
    } else if (
      this.state.SelectedNum !== 0 ||
      this.state.TotalNum < this.state.SelectedNum
    ) {
      let Status = '';
      if (GetReceiptData[this.state.SelectedNum - 2].Status === 'Cancelled') {
        CheckingReceipt =
        GetReceiptData[this.state.SelectedNum - 2].LatestReceiptText ||
        GetReceiptData[this.state.SelectedNum - 2].ReceiptText;
        Status = false;
        this.setState({
          SelectedNum: this.state.SelectedNum - 1,
          ReceiptData: CheckingReceipt,
          TicketStatus: Status,
        });
        this.RenderWord(CheckingReceipt);
      } else {
        Status = true;
        CheckingReceipt =
        GetReceiptData[this.state.SelectedNum - 2].LatestReceiptText ||
        GetReceiptData[this.state.SelectedNum - 2].ReceiptText;
        this.setState({
          SelectedNum: this.state.SelectedNum - 1,
          ReceiptData: CheckingReceipt,
          TicketStatus: Status,
        });
        this.RenderWord(CheckingReceipt);
      }
    } else {
      alert(
        language[this.props.currentLanguage].NoMore,
      );
    }
  }
  async SelectModalReceipt(idx) {
    const GetReceiptData = this.state.ReceiptDataList;
    let Status = '';
    let CheckingReceipt = '';
    if (GetReceiptData[idx].Status === 'Cancelled') {
      CheckingReceipt =
      GetReceiptData[idx].LatestReceiptText ||
      GetReceiptData[idx].ReceiptText;
      Status = false;
      this.setState({
        ReceiptData: CheckingReceipt,
        SelectedNum: idx + 1,
        SelectReceiptModalVisible: false,
        TicketStatus: Status,
      });
      this.RenderWord(CheckingReceipt);
    } else {
      CheckingReceipt =
      GetReceiptData[idx].LatestReceiptText ||
      GetReceiptData[idx].ReceiptText;
      Status = true;
      this.setState({
        ReceiptData: CheckingReceipt,
        SelectedNum: idx + 1,
        SelectReceiptModalVisible: false,
        TicketStatus: Status,
      });
      this.RenderWord(CheckingReceipt);
    }
  }
  RenderWord(UpdatedWord) {
    let Word = UpdatedWord || '';
    let returnWord = [];
    if (Word.includes('<red>')) {
      const WordArray = Word.split('<red>').join('</red>').split('</red>');
      for (let i = 0; i < WordArray.length; i += 1) {
        if (i === 0) {
          returnWord.push(<span key={i}>{WordArray[i]}</span>);
        } else if (i % 2 === 1) {
          returnWord.push(
            <span key={i} style={{color: 'red'}}>
              {WordArray[i]}
            </span>,
          );
        } else if (i % 2 === 0) {
          returnWord.push(<span key={i}>{WordArray[i]}</span>);
        }
      }
      this.setState({ProcessData: returnWord});
    } else {
      this.setState({ProcessData: Word});
    }
  }
  async SearhByCheckboxSelect(idx) {
    const toProcessDateData = this.state.SearchBy;
    if (idx === 0 && toProcessDateData[0].CheckMark === false) {
      toProcessDateData[0].CheckMark = true;
      toProcessDateData[1].CheckMark = false;
    }
    if (idx === 1 && toProcessDateData[1].CheckMark === false) {
      toProcessDateData[0].CheckMark = false;
      toProcessDateData[1].CheckMark = true;
    }
    this.setState({SearchBy: toProcessDateData});
  }
  async DateByCheckboxSelect(idx) {
    const toProcessDateData = this.state.DateBy;
    if (idx === 0 && toProcessDateData[0].CheckMark === false) {
      toProcessDateData[0].CheckMark = true;
      toProcessDateData[1].CheckMark = false;
    }
    if (idx === 1 && toProcessDateData[1].CheckMark === false) {
      toProcessDateData[0].CheckMark = false;
      toProcessDateData[1].CheckMark = true;
    }
    this.setState({DateBy: toProcessDateData});
  }
  async setSelectedDate(date) {
    const DateFormat = moment(date).format('YYYY-MM-DD');
    this.setState({SelectedDateTo: DateFormat});
  }
 

  render() {
    if (this.state.loading) {
      return (
        <div className="LoginPageContainer" style={{ justifyContent: 'center'}}>
          <img class = "loadingSize" src={LoadingLogo} alt="BigCo Inc. logo"/>
        </div>
      );
    } else {
      return (
        <body>
          <div className='box'>
            <div className='BT-HEAD'> 
              <div className='PageHeaderMainContainer'>
                <div className='PageHeaderLeftContainer'>
                  <div className='PageHeaderLeftBackButton'
                    onClick={() => this.props.history.goBack()}>
                    {language[this.props.currentLanguage].Back}
                  </div>
                </div>
                <div className='PageHeaderMiddleContainer'>
                  {language[this.props.currentLanguage].LongtermTicket}
                </div>
                <div className='PageHeaderRightContainer'>
                </div>
              </div>
              <div className='PageUserSelectnSendContainer'>
                <div className='displayIDforSearch'>
                  {this.props.UserForSearch}
                </div>
              </div>
            </div>
            <div class="BT-body" style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto'}}>
              {/* <div className='ReceiptDateButton'>
                <img className="ReceiptSelectDateIcon" src={CalendarIcon} alt="CalendarIcon"/>
                <DatePicker
                  className='DatePickContainer'
                  onChange={(date) => this.setSelectedDate(date)}
                  placeholder="Date"
                  value={this.state.SelectedDateTo}
                  withPortal
                  onFocus={(e) => e.target.readOnly = true}
                />
              </div> */}
              <div className='displayReceiptDateAndDateBy'>
                <div className='DateByCheckBoxContainer' style={{ marginTop: 10 }}>
                  <input
                    className='ReceiptSearchTextbox'
                    keyboardType="number-pad"
                    textAlign="center"
                    placeholder={
                      language[this.props.currentLanguage].TicketNumberInput
                    }
                    placeholderTextColor="#9c9c9c"
                    autoCapitalize="none"
                    maxLength={20}
                    value={this.state.ReceiptNumber}
                    onChange={(e) => this.ReceiptNumberHandleText(e.target.value, 'text')}
                  />
                </div>
                <div className='DateByCheckBoxContainer' style={{ marginTop: 5, marginBottom: 5}}>
                  {this.state.SearchBy.map((item, idx) => {
                    return (
                      <div className='dateByCheckBoxButton' 
                        key={idx}>
                        <div
                          style={{display:'flex', alignItems: 'center'}} 
                          onClick={() => this.SearhByCheckboxSelect(idx)}>
                          {item.displayName}
                          {item.CheckMark && (
                            <img className="dateByCheckMarkIcon" style={{ backgroundColor: "white"}} src={CheckmarkIcon} alt="CheckmarkIcon"/>
                          ) || (
                            <div style={{ backgroundColor: "white", color: 'white'}} className='dateByCheckMarkIcon'>.</div>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <button className='ReceiptSearchButton'
                onClick={() => this.onPressCheckReceipt()}>
                {language[this.props.currentLanguage].Check}
              </button>
              <div className='ReceiptContainer'>
                <div className='ReceiptDisplayText' style={{marginLeft: 5, whiteSpace: 'pre-wrap' }}>
                  {this.state.ProcessData}
                </div>
              </div>
            </div> 
            <div className='ReceiptFooterFeatureContainer'>
              {this.state.ReceiptData !== language[this.props.currentLanguage].NoTicket ? (
                <div className='ReceiptFeatureContainer'>
                  <button className='ReceiptFeatureButton'
                    onClick={() => this.onpressRebuy()}>
                    {language[this.props.currentLanguage].ReBuy}
                  </button>
                  <button className='ReceiptFeatureButton'
                    onClick={() => this.onpressEditRebuy()}>
                    {language[this.props.currentLanguage].EditReBuy}
                  </button>
                  {this.state.TicketStatus && (
                    <button className='ReceiptFeatureButton'
                      onClick={() => this.onpressCancel()}>
                      {language[this.props.currentLanguage].Cancel}
                    </button>
                  )}
                  <button className='ReceiptFeatureButton'
                    onClick={() => this.ShareWord()}>
                    {language[this.props.currentLanguage].Copy}
                  </button>
                </div>
              ):null}
              <button className='ReceiptFooterFeatureButton' 
                style={{ width: '26%', marginRight: 'auto'}}
                onClick={() => this.PreviousReceiptButton()}>
            ⇦
              </button>
              <button className='ReceiptFooterFeatureButton' 
                style={{ width: '44%'}}
                onClick={() => this.setSelectReceiptModalVisible()}>
                {this.state.SelectedNum} / {this.state.TotalNum}
              </button>
              <button className='ReceiptFooterFeatureButton' 
                style={{ width: '26%', marginLeft: 'auto'}}
                onClick={() => this.NextReceiptButton()}>
            ⇨
              </button>
            </div>
            <Modal transparent={true}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.SelectModeModalVisible}
              contentClassName="br23">
              <Modal.Body style={{ padding: 10}}>
                <div className='DateModalHeader'>
                  <div className='DateModalHeaderText'>
                    {language[this.props.currentLanguage].SelectModeHint}
                  </div>
                  <div className='DateModalHeaderText'>
                    <button className='DateModalCloseButton' onClick={() => this.setSelectModeModalVisible(!this.state.SelectModeModalVisible)}> 
                      {language[this.props.currentLanguage].Close}
                    </button>
                  </div>
                </div>
                {this.state.reBuyOption.map((item, idx) => {
                  return (
                    <button className='dateListButton' 
                      key={idx}
                      value={item}
                      onClick={() => this.SelectModeRebuy(item)}>
                      {item}
                    </button>
                  )
                })
                }
              </Modal.Body>
            </Modal>
            <Modal transparent={true}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.SelectReceiptModalVisible}
              contentClassName="br23">
              <Modal.Body className='DateModalBody' style={{ padding: 10}}>
                <div className='DateModalHeader'>
                  <div className='DateModalHeaderText'>
                    {language[this.props.currentLanguage].SelectReceipt}
                  </div>
                  <div className='DateModalHeaderText'>
                    <button className='DateModalCloseButton' onClick={() => this.setSelectReceiptModalVisible(!this.state.SelectReceiptModalVisible)}> 
                      {language[this.props.currentLanguage].Close}
                    </button>
                  </div>
                </div>
                {this.state.ReceiptDataList.map((item, idx) => {
                  return (
                    <button className='dateListButton' 
                      key={idx}
                      value={item}
                      onClick={() => this.SelectModalReceipt(idx)}>
                      {idx + 1})  {item.LatestUserNo ? item.LatestUserNo : item.UserOrderID}
                    </button>
                  )
                })}
              </Modal.Body>
            </Modal>
          </div> 
        </body> 
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    id: state.id,
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    checkResultdate: state.checkResultdate,
    checkResultPlatForm: state.checkResultPlatForm,
    UserIDForSearch: state.UserIDForSearch,
    UserForSearch: state.UserForSearch,
    currentLanguage: state.currentLanguage,
    UserAndDownlineData: state.UserAndDownlineData,
    AbleRed: state.AbleRed,
    AbleSGD: state.AbleSGD,
    AbleTHB: state.AbleTHB,
    oldTicketDate: state.oldTicketDate,
    oldTicketDateBy: state.oldTicketDateBy,
    oldTicketNumber: state.oldTicketNumber,
    oldTicketIDType: state.oldTicketIDType,
    oldTicketIndex: state.oldTicketIndex,
    oldTicketTotal: state.oldTicketTotal,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setReceiptData: (ReceiptData, BuyMode) => {
      dispatch({
        type: 'RECEIPTDATA_FOR_PASS',
        payload: {
          ReceiptData: ReceiptData,
          BuyMode: BuyMode,
        },
      });
    },
    SetRebuyData: (SelectedData, SelectedUserID, SelectedUser) => {
      dispatch({
        type: 'REBUYDATA_FOR_PASS',
        payload: {
          ReBuyData: SelectedData,
          ReBuyUserID: SelectedUserID,
          ReBuyUser: SelectedUser,
        },
      });
    },
    setLoginStatus: (loginStatus, ID, Username, UserToken, role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          login: loginStatus,
          id: ID,
          username: Username,
          token: UserToken,
          role: role,
        },
      });
    },
    setOldTicketInfo: (TicketDate, TicketDateBy, TicketNum, IDType, index, oldTotal) => {
      dispatch({
        type: 'OldTicketInfo',
        payload: {
          oldTicketDate: TicketDate,
          oldTicketDateBy: TicketDateBy,
          oldTicketNumber: TicketNum,
          oldTicketIDType: IDType,
          oldTicketIndex: index,
          oldTicketTotal: oldTotal,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(displayLongtermReceipt);
  