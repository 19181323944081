const language = {
  cn: {
    setting: '设置',
    Ticket: '投注',
    RedTicket: '投注 (SPR)',
    SimpleBT: '简易投注',
    TicketManagement: '单据管理',
    DrawResult: '开彩成绩',
    Report: '报告',
    RedNum: '红字',
    Logout: '登出',
    LogoutConfirmation: '确定登出吗？',
    Close: '关闭',
    Confirm: '确定',
    Send: '发送',
    Longterm: '长期',
    Reverse: '来回',
    IB: '保',
    ClearAllAlertTitle: '清空全部',
    ClearAllConfirmation: '确定清空全部输入的号码吗？',
    Clear: '清空',
    OnlyFourDAlert: '只能用于4D号码',
    OnlyFourDRangeAlert: '只能3D或4D且不能有其他模式或格式不对',
    Hint: '提示',
    Date: '日期',
    Platform: '游戏',
    Number: '号码',
    Special: '特别',
    Amount: '数额',
    Add: '加入',
    TotalAmount: '总额',
    Username: '用户名',
    Password: '密码',
    Login: '登录',
    OnlyNumber: '只能输入号码',
    MaxSixNum: '最多6个号码',
    NoSelectPlatformAlert: '请选择至少一个游戏',
    NumInvalidOrEmpty: '号码不可放空或者格式不对',
    AmountEmpty: '数额还没输入',
    MainTitle: '主目录',
    Change: '更改',
    From: '从 ',
    To: '至 ',
    PressToChange: '点击更改',
    Check: '查询',
    User: '用户',
    BrowseTicket: '浏览单据',
    ShowAutoTicket: '显示长期单据',
    Receipt: '单据',
    ReBuy: ' 重买 ',
    Remove: '移除 ',
    Print: ' 打印 ',
    NoTicket: '没有单据',
    TicketNumberInput: '单号(放空查询全部)',
    LongtermTicket: '长期单据',
    DrawDate: '开彩期',
    EmptyUsername: '请输入用户名',
    EmptyPW: '请输入密码',
    EmptyNewPW: '请输入新密码',
    EmptyReNewPW: '请输入重复密码',
    InvalidUser: '用户名错误',
    InvalidPW: '密码错误',
    Back: '返回',
    Menu: '菜单',
    Show: '显示',
    Hide: '隐藏',
    ReceiptRespond: '单据(回应)',
    Cancel: ' 取消 ',
    CancelRebuy: '取消重买',
    EditReBuy: '改后重买',
    Recall: ' 撤回 ',
    Mode: '模式',
    SelectModeHint: '选择更改模式',
    EmptyDateAlert: '日期还没输入',
    SelectReceipt: '选择单据',
    NoMore: '没有了',
    CancelConfirmation: '确定取消此单吗?',
    RebuyConfirmation: '确定重买此单吗?',
    RecallConfirmation: '确定撤回此单吗?',
    ReportRespond: '报告(回应)',
    NoBetPackage: '此用户没有配套',
    CancelExceedDate: '日期已超出, 无法取消',
    CancelOverDayTime: '时间已超出, 无法取消',
    RecallExceedDate: '日期已超出, 无法撤回',
    RecallOverDayTime: '时间已超出, 无法撤回',
    BTNoPlatForm: '没有输入平台下注',
    BTNoAmount: '没有输入数额下注',
    RangeBiggerNumber: '后置的号码需要大于前置输入的号码',
    AccountLock: '此用户名已经被封锁，请联系相关人士解释',
    LineErr: '以下行数有问题或不完整, 需修改\nLine: ',
    DateInputError: '输入的日期无效',
    FromDateBiggerThan: '日期(从)不能大于日期(至)',
    InvalidreferenceUser: '无效用户',
    NoData: '没有资料',
    CheckInputCorrect: '请确保用户您输入的格式是对的',
    Details: '详情',
    ReportDetails: '报告详情',
    Lai: '总来',
    Eat: '下线吃',
    UpperLineBet: '出给上线',
    UplineWater: '上线水钱',
    UplineWin: '上线中奖',
    UplineProfit: '上线输赢',
    WaterProfit: '水钱赚',
    ShareProfit: '股份赚',
    Sales: '来',
    Net: '净',
    Win: '中奖',
    Commission: '水钱',
    NoDateBet: '没有日期下注',
    TwoPeriod: '2期',
    ThreePeriod: '3期',
    UserNoPrint: '此用户不支持打印',
    Wrong5D6D: '5D6D 只能是 T 或 8 平台',
    PrintErr:
        '无法打印\n1)请确保打印设备已开机\n2)请确保蓝牙已经打开\n3)请确保打印设备与目前设备是连接的\n4)请确保选择的设备是打印设备',
    Normal: '普通',
    RedWord: '红字',
    All: '全部',
    KeyboardA: '键盘A',
    KeyboardB: '键盘B',
    KeyboardC: '键盘C',
    RemoveConfirmation: '确定删除这组号码吗?',
    BuyDateWarn: '购买日期有问题',
    superLoginErr: '此账号不支持手机登录',
    NoAuthoCancel: '此账号无权取消单',
    NoAuthRecall: '此账号无权撤回单',
    NoEnufDate: '目前没有提供日期下单',
    GameShutDown: '全部游戏目前关闭中',
    SGameShutDown: '新加坡目前关闭中',
    newOrder: '新单',
    SelectNewTicketMode: '选择模式',
    RebuyingHint: '重买中请稍等',
    DateNoAvailable: '当前的日期无法提供下单',
    SelectPrintereHint: '选择打印设备',
    AddNumber: '添加号码',
    AccountSetting: '账户设置',
    ChangePassword: '更改密码',
    currentPass: '当前密码',
    NewPass: '新密码',
    ReNewPass: '重复新密码',
    UserNoRed: '此用户不支持红单',
    ExceedPerLimit: '此用户已超出个人限制',
    Information: '资讯',
    Bet: '下注',
    Open: '开彩',
    DateBy: '日期根据',
    unCompleteReport: '未完整计算',
    CompleteReport: '已完整计算',
    SelfBet: '自下注',
    DownlinBet: '下线下注',
    TotalBet: '总下注',
    PassSame: '当前密码不能和新密码一样',
    newPassDif: '新密码与重复新密码不一致',
    VerSetting: '版本设置',
    VerChecking: '查看最新版本',
    currentVer: '当前版本',
    update: '更新',
    latestVer: '最新版本',
    verUptoDate: '目前已是最新版本',
    PlsUpdate: ' 为最新版本，请更新以获取更好的用户体验',
    firsTimeLog: '首次登录需更改密码',
    currentPassWrong: '当前密码错误',
    doneChgPass: '密码更改成功',
    PasteConfirm: '粘贴确认',
    PasteClearHint: '粘贴功能需要清空情况下才能使用',
    Paste: '粘贴',
    FunctionLanguage: '功能',
    More: '更多',
    Copy: '复制',
    DoneCopy: '复制成功',
    UserNegativeCommission: '水钱负数,请联络上线',
    AccountInactive: '此用户处于非激活状态',
    MKTSWBDoff: 'MKTSWBD目前关闭',
    AllGameoff: '所有游戏目前关闭',
    OverNumberLimit: '一张单只限4000字',
    RepeatOrder: '投注已重复',
    FormatError: '格式问题',
    PlatFormErr: '平台错误',
    Download: '下载',
    Version:'版本',
    FailedCopy: '复制失败',
    FailedPaste: '粘贴功能无效',
    Zong: '总',
    LaiLanguage: 'Lai',
    TongRepot: '报告(桶)',
    AlreadyCancel: '此单已被取消',
    NtgRever: '没有可来回的号码',
    SharesProfit: '股份净',
    WaterEarn: '水钱赚',
    PrizeEarn: '奖金赚',
    TotalWater: '总水钱',
    TotalWin: '总中奖',
    TotalProfit: '总输赢',
    Shares: '股份钱数',
    SharesWater: '股份水钱',
    SharesWin: '股份中奖',
    GiveUpline: '出给上线',
    AddNewLine: '加入空行',
    Buy: '买',
    keyChange: '键盘设置',
    KeyPad: '键盘',
    box: '打',
    GameDesired: '游戏下注限制',
    noMandarin: '密码不支持中文',
    CurrentAndNewSamePass: '当前密码和新密码相同',
    SelfWater: '自水钱',
    SelfWin: '自中奖',
    SelfProfit: '自输赢',
    SpecialSupportHint: 'Special只支持3D和4D',
    Delete: '删除',
    noEnufCashWall: '现金钱包不足',
    ChinaPress: '中国报',
    Remark: '备注',
    only3n4D: '只能用于4D',
    betReport: '下注报告',
    betChecking: '下注查询',
    reportCheck: '报告查询',
    SearchByNumber: '根据号码寻找',
    BrowseTicketNumber: '浏览单据(号码)',
    WaitOrderFinish: '还没进完单,请稍等片刻',
    myself: '自己',
    myselfnDownline: '自己与全部下线',
    onlySingleDownline: '单个下线',
    dailyReport: '每日报告',
    includeLongTerm: '包括长期',
    editPaste: '更改粘贴',
    ConvertPaste: '转换粘贴',
    AutoPrint: '自动打印',
    connectServerErr: '无法联通伺服器',
    ScrollViewReceipt: '滑动看单',
  },
  en: {
    setting: 'Settings',
    Ticket: 'Ticket',
    RedTicket: 'Ticket(SPR)',
    SimpleBT: 'Ticket (Simple)',
    TicketManagement: 'Ticket Manage',
    DrawResult: 'Draw Result',
    Report: 'Report',
    RedNum: 'Red Number',
    Logout: 'Logout',
    LogoutConfirmation: 'Confirm Logout?',
    Close: 'Close',
    Confirm: 'Confirm',
    Send: 'Send',
    Longterm: 'AUTO',
    Reverse: 'RV',
    IB: 'IB',
    ClearAllAlertTitle: 'Clear All',
    ClearAllConfirmation: 'Confirm Clear All Key in Number？',
    Clear: 'Clear',
    OnlyFourDAlert: 'Only 4D With Amount',
    OnlyFourDRangeAlert: 'Only 3D or 4D Without Any Others Special',
    Hint: 'Hint',
    Date: 'Date',
    Platform: 'Platform',
    Number: 'Number',
    Special: 'Special',
    Amount: 'Amount',
    Add: 'ADD',
    TotalAmount: 'Total',
    Username: 'Username',
    Password: 'Password',
    Login: 'Login',
    OnlyNumber: 'Only Number',
    MaxSixNum: 'Maximun 6 Number',
    NoSelectPlatformAlert: 'Please Select At Least One Platform',
    NumInvalidOrEmpty: 'Number is Invalid or Empty',
    AmountEmpty: 'Amount Cannot Be Empty',
    MainTitle: 'Main Page',
    Change: 'Change',
    From: 'From',
    To: 'To',
    PressToChange: 'Press Change',
    Check: 'Check',
    User: 'User',
    BrowseTicket: 'Browse Ticket',
    ShowAutoTicket: 'Show Auto Ticket',
    Receipt: 'Ticket',
    ReBuy: 'ReBuy',
    Remove: 'Remove',
    Print: 'Print',
    NoTicket: 'No Ticket',
    TicketNumberInput: '(Empty To Check All)',
    LongtermTicket: 'Auto Ticket',
    DrawDate: 'Draw Date',
    EmptyUsername: 'Please Insert Username',
    EmptyPW: 'Please Insert Password',
    EmptyNewPW: 'Please Insert New Password',
    EmptyReNewPW: 'Please Insert Re-New Password',
    InvalidUser: 'Invalid Username',
    InvalidPW: 'Invalid Password',
    Back: 'Back',
    Menu: 'Menu',
    Show: 'Show',
    Hide: 'Hide',
    ReceiptRespond: 'Receipt(Respond)',
    Cancel: 'Cancel',
    CancelRebuy: 'Cancel&Rebuy',
    EditReBuy: 'Edit ReBuy',
    Recall: 'Recall',
    Mode: 'Mode',
    SelectModeHint: 'Select Edit Mode',
    EmptyDateAlert: 'Please Key In Date',
    SelectReceipt: 'Select Ticket',
    NoMore: 'No More',
    CancelConfirmation: 'Confirm Cancel This Ticket?',
    RebuyConfirmation: 'Confirm Rebuy This Ticket?',
    RecallConfirmation: 'Confirm Recall This Ticket?',
    ReportRespond: 'Report(Respond)',
    NoBetPackage: "Current User Don't Have Package",
    CancelExceedDate: 'Exceed Date, Unable To Cancel',
    CancelOverDayTime: 'Exceed Time, Unable To Cancel',
    RecallExceedDate: 'Exceed Date, Unable To Recall',
    RecallOverDayTime: 'Exceed Time, Unable To Recall',
    BTNoPlatForm: 'No Key In Platform For Bet',
    BTNoAmount: 'No Key In Amount For Bet',
    RangeBiggerNumber:
        'The Back Number Input Needs To Be Larger Than Front Input Number ',
    AccountLock: 'Account Has Been Locked, Please Contact Admin to Unlock',
    LineErr:
        'Line Below Show Have Some Problem or Incomplete, Please Amend \nLine: ',
    DateInputError: 'Selected Date is Invalid',
    FromDateBiggerThan: 'Date From Cannot Bigger Than Date To',
    InvalidreferenceUser: 'Invalid referenceUser',
    NoData: 'No Data',
    CheckInputCorrect: 'Please Ensure User Your Input Format Is Correct',
    Details: 'Details',
    ReportDetails: 'Report Details',
    Lai: 'Sales',
    Eat: 'D/Line Eat',
    UpperLineBet: 'Pass-up',
    UplineWater: 'Pass-up Comm',
    UplineWin: 'Pass-up Strike',
    UplineProfit: 'Pass-up Net',
    WaterProfit: 'Comm Profit',
    ShareProfit: 'Fight Profit',
    Sales: 'S',
    Net: 'N',
    Win: 'W',
    Commission: 'C',
    NoDateBet: 'No Date To Bet',
    TwoPeriod: '2 Period',
    ThreePeriod: '3 Period',
    UserNoPrint: 'Current User No Support Print',
    Wrong5D6D: '5D6D Only Able For Platform T and 8',
    PrintErr:
        'Unable To Print\n1)Please Ensure The Printing Device Power Is On\n2)Please Ensure Bluetooth Is On\n3)Please Ensure The Printing Device Is Linking With Current Device\n4)Please Ensure Selected Deivice Is Printing Device',
    Normal: 'Normal',
    RedWord: 'RedWord',
    All: 'All',
    KeyboardA: 'Keyboard A',
    KeyboardB: 'Keyboard B',
    KeyboardC: 'Keyboard C',
    RemoveConfirmation: 'Confirm Remove This Number?',
    BuyDateOverWarn: 'Unable To Buy More Than 6 Period',
    BuyDateWarn: 'Unable To Buy For Those Date',
    superLoginErr: 'Current Account No Support Mobile Login',
    NoAuthoCancel: 'Current Account Dont Have Authorization To Cancel Ticket',
    NoAuthRecall: 'Current Account Dont Have Authorization To Recall Ticket',
    NoEnufDate: 'Currently No Date To Make Order',
    GameShutDown: 'All Game Currently Closed',
    SGameShutDown: 'Singapore Pools Currently Closed ',
    newOrder: 'New',
    SelectNewTicketMode: 'Select Mode',
    RebuyingHint: 'Rebuying Please Wait',
    DateNoAvailable: 'Current Date No Available To Order',
    SelectPrintereHint: 'Select Printer',
    AddNumber: 'Add Number',
    AccountSetting: 'Account Setting',
    ChangePassword: 'Change Password',
    currentPass: 'Current Password',
    NewPass: 'New Password',
    ReNewPass: 'Re-New Password',
    UserNoRed: 'Current User No Support Red Ticket',
    ExceedPerLimit: 'Current User Exceed Personal Daily Limit',
    Information: 'Information',
    Bet: 'Bet',
    Open: 'Draw',
    DateBy: 'Date By',
    unCompleteReport: 'Report Pending',
    CompleteReport: 'Report Complete',
    SelfBet: 'SelfBet',
    DownlinBet: 'DownlinBet',
    TotalBet: 'TotalBet',
    PassSame: 'Current Password Cannot Be Same With New Password',
    newPassDif: 'New Password No Match With Re-New Password',
    VerSetting: 'Version Setting',
    VerChecking: 'Latest Version Checking',
    currentVer: 'Current Version',
    update: 'Update',
    latestVer: 'Latest Version',
    verUptoDate: 'Current Version Already Up to Date',
    PlsUpdate: ' As Latest Version, Please Update For Better User Experience',
    firsTimeLog: 'For First Time Login Change Password is Needed',
    currentPassWrong: 'Wrong Current Password',
    doneChgPass: 'Password Changed Successfully',
    PasteConfirm: 'Paste Confirmation',
    PasteClearHint: 'Please Clear All Before Use Paste Function',
    Paste: 'Paste',
    FunctionLanguage: 'Function',
    More: 'More',
    Copy: 'Copy',
    DoneCopy: 'Done Copied',
    UserNegativeCommission: 'User Negative Commission, Please Contact Upline',
    AccountInactive: 'Current Account Inactive',
    MKTSWBDoff: 'MKTSWBD ShutDown',
    AllGameoff: 'All Game ShutDown',
    OverNumberLimit: 'Limit 4000 Word Per Ticket',
    RepeatOrder: 'Repeat Order',
    FormatError: 'Format Error',
    PlatFormErr: 'Platform Error',
    Download: 'Download',
    Version: 'Version',
    FailedCopy: 'Failed To Copy',
    FailedPaste: 'Paste is Not Working',
    Zong: 'Total',
    LaiLanguage: 'Lai',
    TongRepot: 'Report(TONG)',
    AlreadyCancel: 'This Ticket Already Been Cancelled',
    NtgRever: 'No Number For RV',
    SharesProfit: 'SharesProfit',
    WaterEarn: 'WaterProfit',
    PrizeEarn: 'WinProfit',
    TotalWater: 'TotalWater',
    TotalWin: 'TotalWin',
    TotalProfit: 'TotalProfit',
    Shares: 'Shares',
    SharesWater: 'SharesWater',
    SharesWin: 'SharesWin',
    GiveUpline: 'GiveUpline',
    AddNewLine: 'Add New Line',
    Buy: 'Buy',
    keyChange: 'Keyboard Setting',
    KeyPad: 'KeyPad',
    box: 'Box',
    GameDesired: 'Game Bet Limit',
    noMandarin: 'Mandarin Not Allow',
    CurrentAndNewSamePass: 'Current Password Match With New Password!',
    SelfWater: 'SelfWater',
    SelfWin: 'SelfWin',
    SelfProfit: 'SelfProfit',
    SpecialSupportHint: 'Special Only Allow For 3D and 4D',
    Delete: 'Cancel',
    noEnufCashWall: 'Not enough cash wallet',
    ChinaPress: 'China Press',
    Remark: 'Remark',
    only3n4D: 'Only 4D',
    betReport: 'Bet Report',
    betChecking: 'Check Betting',
    reportCheck: 'Check Report',
    SearchByNumber: 'Search By Number',
    BrowseTicketNumber: 'Ticket(By Number)',
    WaitOrderFinish: 'Order not finished yet, please wait for awhile',
    myself: 'MySelf',
    myselfnDownline: 'MySelf And All Downline',
    onlySingleDownline: 'Only Single Downline',
    dailyReport: 'Daily Report',
    includeLongTerm: 'Include LongTerm',
    editPaste: 'Edit&Paste',
    ConvertPaste: 'Convert&Paste',
    AutoPrint: 'AutoPrint',
    connectServerErr: 'Unable Connect To Server',
    ScrollViewReceipt: 'Scroll And View',
  },
};
  
exports.language = language;
  