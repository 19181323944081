import MLogo from '../img/PlatFormIcon/MLogo.png';
import KLogo from '../img/PlatFormIcon/KLogo.png';
import TLogo from '../img/PlatFormIcon/TLogo.png';
import SLogo from '../img/PlatFormIcon/SLogo.png';
import WLogo from '../img/PlatFormIcon/WLogo.png';
import BLogo from '../img/PlatFormIcon/BLogo.png';
import DLogo from '../img/PlatFormIcon/DLogo.png';
import btLogo from '../img/PlatFormIcon/8Logo.png';
import NineLogo from '../img/PlatFormIcon/NineLogo.png';
import MGrey from '../img/PlatFormIcon/MGrey.png';
import KGrey from '../img/PlatFormIcon/KGrey.png';
import TGrey from '../img/PlatFormIcon/TGrey.png';
import SGrey from '../img/PlatFormIcon/SGrey.png';
import WGrey from '../img/PlatFormIcon/WGrey.png';
import BGrey from '../img/PlatFormIcon/BGrey.png';
import DGrey from '../img/PlatFormIcon/DGrey.png';
import btGrey from '../img/PlatFormIcon/8Grey.png';
import NineGrey from '../img/PlatFormIcon/NineGrey.png';

export function processCheckboxPlatformPosition(providedPF, displayPlatform) {
  let processPlatformtoReturn = [];
  let PFConvertArray = [];
  let covertprovidedPF = providedPF.split('');
  for (var x = 0; x < covertprovidedPF.length; x++) {
    PFConvertArray.push(
      covertprovidedPF[x]
        .replace(/1/g, 'M')
        .replace(/2/g, displayPlatform === 'MPTSWBK' ? 'P' : 'K')
        .replace(/3/g, 'T')
        .replace(/4/g, 'S')
        .replace(/5/g, 'W')
        .replace(/6/g, 'B')
        .replace(/7/g, displayPlatform === 'MPTSWBK' ? 'K' : 'D')
        .replace(/8/g, '8') 
        .replace(/9/g, '9')
        .replace(/[*]/g, ''),
    );
  }
  for (var i = 0; i < PFConvertArray.length; i++) {
    if (PFConvertArray[i] !== '') {
      if (displayPlatform === 'MPTSWBK') {
        if (PFConvertArray[i] === 'M') {
          processPlatformtoReturn.push({
            PlatForm: PFConvertArray[i],
            CheckMark: false,
            ImageUri: MLogo,
            GreyImageUri: MGrey,
          });
        } else if (PFConvertArray[i] === 'P') {
          processPlatformtoReturn.push({
            PlatForm: PFConvertArray[i],
            CheckMark: false,
            ImageUri: KLogo,
            GreyImageUri: KGrey,
          });
        } else if (PFConvertArray[i] === 'T') {
          processPlatformtoReturn.push({
            PlatForm: PFConvertArray[i],
            CheckMark: false,
            ImageUri: TLogo,
            GreyImageUri: TGrey,
          });
        } else if (PFConvertArray[i] === 'S') {
          processPlatformtoReturn.push({
            PlatForm: PFConvertArray[i],
            CheckMark: false,
            ImageUri: SLogo,
            GreyImageUri: SGrey,
          });
        } else if (PFConvertArray[i] === 'W') {
          processPlatformtoReturn.push({
            PlatForm: PFConvertArray[i],
            CheckMark: false,
            ImageUri: WLogo,
            GreyImageUri: WGrey,
          });
        } else if (PFConvertArray[i] === 'B') {
          processPlatformtoReturn.push({
            PlatForm: PFConvertArray[i],
            CheckMark: false,
            ImageUri: BLogo,
            GreyImageUri: BGrey,
          });
        } else if (PFConvertArray[i] === 'K') {
          processPlatformtoReturn.push({
            PlatForm: PFConvertArray[i],
            CheckMark: false,
            ImageUri: DLogo,
            GreyImageUri: DGrey,
          });
        } else if (PFConvertArray[i] === '8') {
          processPlatformtoReturn.push({
            PlatForm: PFConvertArray[i],
            CheckMark: false,
            ImageUri: btLogo,
            GreyImageUri: btGrey,
          });
        } else if (PFConvertArray[i] === '-') {
          processPlatformtoReturn.push({
            PlatForm: '-',
            CheckMark: false,
          });
        }
      } else {
        if (PFConvertArray[i] === 'M') {
          processPlatformtoReturn.push({
            PlatForm: PFConvertArray[i],
            CheckMark: false,
            ImageUri: MLogo,
            GreyImageUri: MGrey,
          });
        } else if (PFConvertArray[i] === 'K') {
          processPlatformtoReturn.push({
            PlatForm: PFConvertArray[i],
            CheckMark: false,
            ImageUri: KLogo,
            GreyImageUri: KGrey,
          });
        } else if (PFConvertArray[i] === 'T') {
          processPlatformtoReturn.push({
            PlatForm: PFConvertArray[i],
            CheckMark: false,
            ImageUri: TLogo,
            GreyImageUri: TGrey,
          });
        } else if (PFConvertArray[i] === 'S') {
          processPlatformtoReturn.push({
            PlatForm: PFConvertArray[i],
            CheckMark: false,
            ImageUri: SLogo,
            GreyImageUri: SGrey,
          });
        } else if (PFConvertArray[i] === 'W') {
          processPlatformtoReturn.push({
            PlatForm: PFConvertArray[i],
            CheckMark: false,
            ImageUri: WLogo,
            GreyImageUri: WGrey,
          });
        } else if (PFConvertArray[i] === 'B') {
          processPlatformtoReturn.push({
            PlatForm: PFConvertArray[i],
            CheckMark: false,
            ImageUri: BLogo,
            GreyImageUri: BGrey,
          });
        } else if (PFConvertArray[i] === 'D') {
          processPlatformtoReturn.push({
            PlatForm: PFConvertArray[i],
            CheckMark: false,
            ImageUri: DLogo,
            GreyImageUri: DGrey,
          });
        } else if (PFConvertArray[i] === '8') {
          processPlatformtoReturn.push({
            PlatForm: PFConvertArray[i],
            CheckMark: false,
            ImageUri: btLogo,
            GreyImageUri: btGrey,
          });
        } else if (PFConvertArray[i] === '9') {
          processPlatformtoReturn.push({
            PlatForm: PFConvertArray[i],
            CheckMark: false,
            ImageUri: NineLogo,
            GreyImageUri: NineGrey,
          });
        } else if (PFConvertArray[i] === '-') {
          processPlatformtoReturn.push({
            PlatForm: '-',
            CheckMark: false,
          });
        }
      }
    }
  }
  //   console.log(processPlatformtoReturn);
  return processPlatformtoReturn;
}
