import {filterStartTag} from '../utility/filterStartTag';
export function processPad2KeyAmount(
  lineData,
  SelectedLine,
  toProcessFormula,
  PressNumber,
) {
  let processFormulaAmount = [];
  let CheckOri = lineData[SelectedLine].originalWord;
  let wordStartTag = '';
  if (CheckOri.startsWith('##')) {
    wordStartTag = '##';
  } else if (CheckOri.startsWith('#')) {
    wordStartTag = '#';
  }
  CheckOri = filterStartTag(CheckOri);
  let CheckTag = CheckOri.replace(/[^#]/g, '');
  let getAmount = CheckOri.substring(CheckOri.indexOf('#'));
  let getEachAmount = getAmount.split('#');
  let onlyWord = CheckOri.split('#')[0];
  for (var i = 0; i < CheckTag.length; i++) {
    if (toProcessFormula[i].selected === true) {
      processFormulaAmount.push('#' + PressNumber);
    } else if (toProcessFormula[i].selected === false) {
      processFormulaAmount.push('#' + getEachAmount[i + 1]);
    }
  }
  processFormulaAmount =
    wordStartTag + onlyWord + processFormulaAmount.join().replace(/([,])/g, '');
  return processFormulaAmount;
}
