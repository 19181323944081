import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import {language} from '../language';
import LoadingLogo from '../img/loading.gif';
import moment from 'moment-timezone';
const { getReportDetails } = require('../Api');

class displayReportDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      date: new Date(),
      DisplayReportData: '',
      todayDate: moment().format('YYYY-MM-DD'),
    }
  }

  async LogoutFunction() {
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('AbleRed');
    sessionStorage.removeItem('FirstTimeLogin');
    sessionStorage.removeItem('Username');
    sessionStorage.removeItem('loginStatus');
    sessionStorage.removeItem('getUserDownlineData');
    sessionStorage.removeItem('ReceiptData');
    sessionStorage.removeItem('UserIDForSearch');
    sessionStorage.removeItem('UserForSearch');
    sessionStorage.removeItem('SearchDateFrom');
    sessionStorage.removeItem('SearchDateTo');
    sessionStorage.removeItem('PatternForSearch');
    sessionStorage.removeItem('PlatformForSearch');
    sessionStorage.removeItem('ReportData');
    this.props.setLoginStatus(false, '', '', '', '');
  }

  async componentDidMount() {
    if (this.props.UserAndDownlineData === '') {
      this.props.history.push('/')
    }
    const ReportData = await getReportDetails(
      this.props.username,
      moment(this.props.SearchDateFrom).format('YYYY-MM-DD'),
      moment(this.props.SearchDateTo).format('YYYY-MM-DD'),
      this.props.UserIDForSearch,
      this.props.token,
      this.props.PlatformListToSearch,
      this.props.redModeToSearch,
    );
    if (ReportData === 'TimeOut') {
      this.LogoutFunction();
    }
    this.processNormal(ReportData);
    if (this.props.redModeToSearch === 'sgd') {
      this.ProcessSGD(ReportData);
    } else if (this.props.redModeToSearch === 'thai') {
      this.ProcessThai(ReportData);
    } else {
      this.processNormal(ReportData);
    }
    this.setState({loading: false});
  }

  async processNormal(ReportData) {
    const getDate = Object.keys(ReportData);
    let DisplayReportData = [];
    for (let i = 0; i < getDate.length; i += 1) {
      const Downline = Object.keys(ReportData[getDate[i]]);
      if (this.props.ReportData.message === 'No Data' || Downline.length <= 0) {
        this.setState({
          DisplayReportData: 'NO DATA',
          loading: false,
        });
      } else {
        // let TotalN = 0;
        Downline.forEach(Ditem => {
          DisplayReportData.push([
            `${ReportData[getDate[i]][Ditem].Username} (${moment(
              getDate[i],
            ).format('DD/MM')})` +
              '\n=Summary=' +
              '\n' +
              language[this.props.currentLanguage].TotalBet +
              ':' +
              ReportData[getDate[i]][Ditem].TotalBet +
              '\n' +
              language[this.props.currentLanguage].TotalWater +
              ':' +
              ReportData[getDate[i]][Ditem].TotalWater +
              '\n' +
              language[this.props.currentLanguage].TotalWin +
              ':' +
              ReportData[getDate[i]][Ditem].TotalWin +
              '\n' +
              language[this.props.currentLanguage].TotalProfit +
              ':' +
              ReportData[getDate[i]][Ditem].TotalProfit,
            '\n' +
              language[this.props.currentLanguage].Shares +
              ':' +
              ReportData[getDate[i]][Ditem].Shares +
              '\n' +
              language[this.props.currentLanguage].SharesWater +
              ':' +
              ReportData[getDate[i]][Ditem].SharesWater +
              '\n' +
              language[this.props.currentLanguage].SharesWin +
              ':' +
              ReportData[getDate[i]][Ditem].SharesWin +
              '\n' +
              language[this.props.currentLanguage].ShareProfit +
              ':' +
              ReportData[getDate[i]][Ditem].SharesProfit +
              '\n' +
              language[this.props.currentLanguage].GiveUpline +
              ':' +
              ReportData[getDate[i]][Ditem].Upline +
              '\n' +
              language[this.props.currentLanguage].UplineWater +
              ':' +
              ReportData[getDate[i]][Ditem].UplineWater +
              '\n' +
              language[this.props.currentLanguage].UplineWin +
              ':' +
              ReportData[getDate[i]][Ditem].UplineWin +
              '\n' +
              language[this.props.currentLanguage].UplineProfit +
              ':' +
              ReportData[getDate[i]][Ditem].UplineProfit +
              '\n\n=======================\n\n',
          ]);
        });
      }
    }
    this.setState({
      DisplayReportData: DisplayReportData,
      loading: false,
    });
  }

  async ProcessSGD(ReportData) {
    const getDate = Object.keys(ReportData);
    let DisplayReportData = [];
    for (let i = 0; i < getDate.length; i += 1) {
      const Downline = Object.keys(ReportData[getDate[i]]);
      if (this.props.ReportData.message === 'No Data' || Downline.length <= 0) {
        this.setState({
          DisplayReportData: 'NO DATA',
          loading: false,
        });
      } else {
        Downline.forEach(Ditem => {
          DisplayReportData.push([
            `${ReportData[getDate[i]][Ditem].Username} (${moment(
              getDate[i],
            ).format('DD/MM')})` +
              '\n=Summary=' +
              '\n' +
              language[this.props.currentLanguage].TotalBet +
              ':' +
              ReportData[getDate[i]][Ditem].TotalBet +
              '(' +
              ReportData[getDate[i]][Ditem].sgdTotalBet +
              ')' +
              '\n' +
              language[this.props.currentLanguage].TotalWater +
              ':' +
              ReportData[getDate[i]][Ditem].TotalWater +
              '(' +
              ReportData[getDate[i]][Ditem].sgdTotalWater +
              ')' +
              '\n' +
              language[this.props.currentLanguage].TotalWin +
              ':' +
              ReportData[getDate[i]][Ditem].TotalWin +
              '(' +
              ReportData[getDate[i]][Ditem].sgdTotalWin +
              ')' +
              '\n' +
              language[this.props.currentLanguage].TotalProfit +
              ':' +
              ReportData[getDate[i]][Ditem].TotalProfit +
              '(' +
              ReportData[getDate[i]][Ditem].sgdTotalProfit +
              ')' +
              '\n' +
              language[this.props.currentLanguage].Shares +
              ':' +
              ReportData[getDate[i]][Ditem].Shares +
              '(' +
              ReportData[getDate[i]][Ditem].sgdShares +
              ')' +
              '\n' +
              language[this.props.currentLanguage].SharesWater +
              ':' +
              ReportData[getDate[i]][Ditem].SharesWater +
              '(' +
              ReportData[getDate[i]][Ditem].sgdSharesWater +
              ')' +
              '\n' +
              language[this.props.currentLanguage].SharesWin +
              ':' +
              ReportData[getDate[i]][Ditem].SharesWin +
              '(' +
              ReportData[getDate[i]][Ditem].sgdSharesWin +
              ')' +
              '\n' +
              language[this.props.currentLanguage].ShareProfit +
              ':' +
              ReportData[getDate[i]][Ditem].SharesProfit +
              '(' +
              ReportData[getDate[i]][Ditem].sgdSharesProfit +
              ')' +
              '\n' +
              language[this.props.currentLanguage].GiveUpline +
              ':' +
              ReportData[getDate[i]][Ditem].Upline +
              '(' +
              ReportData[getDate[i]][Ditem].sgdUpline +
              ')' +
              '\n' +
              language[this.props.currentLanguage].UplineWater +
              ':' +
              ReportData[getDate[i]][Ditem].UplineWater +
              '(' +
              ReportData[getDate[i]][Ditem].sgdUplineWater +
              ')' +
              '\n' +
              language[this.props.currentLanguage].UplineWin +
              ':' +
              ReportData[getDate[i]][Ditem].UplineWin +
              '(' +
              ReportData[getDate[i]][Ditem].sgdUplineWin +
              ')' +
              '\n' +
              language[this.props.currentLanguage].UplineProfit +
              ':' +
              ReportData[getDate[i]][Ditem].UplineProfit +
              '(' +
              ReportData[getDate[i]][Ditem].sgdUplineProfit +
              ')' +
              '\n\n=======================\n\n',
          ]);
        });
      }
    }
    this.setState({
      DisplayReportData: DisplayReportData,
      loading: false,
    });
  }

  async ProcessThai(ReportData) {
    const getDate = Object.keys(ReportData);
    let DisplayReportData = [];
    for (let i = 0; i < getDate.length; i += 1) {
      const Downline = Object.keys(ReportData[getDate[i]]);
      if (this.props.ReportData.message === 'No Data' || Downline.length <= 0) {
        this.setState({
          DisplayReportData: 'NO DATA',
          loading: false,
        });
      } else {
        Downline.forEach(Ditem => {
          DisplayReportData.push([
            `${ReportData[getDate[i]][Ditem].Username} (${moment(
              getDate[i],
            ).format('DD/MM')})` +
              '\n=Summary=' +
              '\n' +
              language[this.props.currentLanguage].TotalBet +
              ':' +
              ReportData[getDate[i]][Ditem].TotalBet +
              '(' +
              ReportData[getDate[i]][Ditem].thaiTotalBet +
              ')' +
              '\n' +
              language[this.props.currentLanguage].TotalWater +
              ':' +
              ReportData[getDate[i]][Ditem].TotalWater +
              '(' +
              ReportData[getDate[i]][Ditem].thaiTotalWater +
              ')' +
              '\n' +
              language[this.props.currentLanguage].TotalWin +
              ':' +
              ReportData[getDate[i]][Ditem].TotalWin +
              '(' +
              ReportData[getDate[i]][Ditem].thaiTotalWin +
              ')' +
              '\n' +
              language[this.props.currentLanguage].TotalProfit +
              ':' +
              ReportData[getDate[i]][Ditem].TotalProfit +
              '(' +
              ReportData[getDate[i]][Ditem].thaiTotalProfit +
              ')' +
              '\n' +
              language[this.props.currentLanguage].Shares +
              ':' +
              ReportData[getDate[i]][Ditem].Shares +
              '(' +
              ReportData[getDate[i]][Ditem].thaiShares +
              ')' +
              '\n' +
              language[this.props.currentLanguage].SharesWater +
              ':' +
              ReportData[getDate[i]][Ditem].SharesWater +
              '(' +
              ReportData[getDate[i]][Ditem].thaiSharesWater +
              ')' +
              '\n' +
              language[this.props.currentLanguage].SharesWin +
              ':' +
              ReportData[getDate[i]][Ditem].SharesWin +
              '(' +
              ReportData[getDate[i]][Ditem].thaiSharesWin +
              ')' +
              '\n' +
              language[this.props.currentLanguage].ShareProfit +
              ':' +
              ReportData[getDate[i]][Ditem].SharesProfit +
              '(' +
              ReportData[getDate[i]][Ditem].thaiSharesProfit +
              ')' +
              '\n' +
              language[this.props.currentLanguage].GiveUpline +
              ':' +
              ReportData[getDate[i]][Ditem].Upline +
              '(' +
              ReportData[getDate[i]][Ditem].thaiUpline +
              ')' +
              '\n' +
              language[this.props.currentLanguage].UplineWater +
              ':' +
              ReportData[getDate[i]][Ditem].UplineWater +
              '(' +
              ReportData[getDate[i]][Ditem].thaiUplineWater +
              ')' +
              '\n' +
              language[this.props.currentLanguage].UplineWin +
              ':' +
              ReportData[getDate[i]][Ditem].UplineWin +
              '(' +
              ReportData[getDate[i]][Ditem].thaiUplineWin +
              ')' +
              '\n' +
              language[this.props.currentLanguage].UplineProfit +
              ':' +
              ReportData[getDate[i]][Ditem].UplineProfit +
              '(' +
              ReportData[getDate[i]][Ditem].thaiUplineProfit +
              ')' +
              '\n\n=======================\n\n',
          ]);
        });
      }
    }
    this.setState({
      DisplayReportData: DisplayReportData,
      loading: false,
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="LoginPageContainer" style={{ justifyContent: 'center'}}>
          <img class = "loadingSize" src={LoadingLogo} alt="BigCo Inc. logo"/>
        </div>
      );
    } else {
      return (
        <body>
          <div className='box'>
            <div className='Report-HEAD'>
              <div className='PageHeaderMainContainer'>
                <div className='PageHeaderLeftContainer'>
                  <div className='PageHeaderLeftBackButton'
                    onClick={() => this.props.history.goBack()}>
                    {language[this.props.currentLanguage].Back}
                  </div>
                </div>
                <div className='PageHeaderMiddleContainer'>
                  {language[this.props.currentLanguage].ReportDetails}
                </div>
                <div className='PageHeaderRightContainer'>
                </div>
              </div>
            </div>
            <div className='Report-body'>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{ width: '100%', textAlign: 'left', whiteSpace: 'pre-wrap', padding: 10, fontSize: '20px', fontWeight: 'bold' }}>
                  {this.state.DisplayReportData}
                </div>
              </div>
            </div>
          </div>
        </body> 
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    id: state.id,
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
    ReportData: state.ReportData,
    UserIDForSearch: state.UserIDForSearch,
    UserForSearch: state.UserForSearch,
    SearchDateFrom: state.SearchDateFrom,
    SearchDateTo: state.SearchDateTo,
    PlatformListToSearch: state.PlatformListToSearch,
    redModeToSearch: state.redModeToSearch,
    UserAndDownlineData: state.UserAndDownlineData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (loginStatus, ID, Username, UserToken, role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          login: loginStatus,
          id: ID,
          username: Username,
          token: UserToken,
          role: role,
        },
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(displayReportDetails);
  