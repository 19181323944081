export function processNumPad2Formula(formulaKey) {
  let formulaArray = [];
  let lengthChecking = formulaKey.length;
  if (lengthChecking >= 5) {
    lengthChecking = 5;
  }
  for (let i = 0; i < lengthChecking; i++) {
    formulaArray.push({
      key: formulaKey[i],
      selected: false,
      num: i + 1,
      autoClose: false,
    });
  }
  return formulaArray;
}
