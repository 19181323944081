const base = window.location.protocol === 'https:' ? 'https://s.987987.me/' : 'http://57.128.43.245/';
const btbase = window.location.protocol === 'https:' ? 'https://bs.987987.me/' : 'http://57.128.43.245/';
// const base = window.location.protocol === 'https:' ? 'http://s.7fzrg67.club/' : 'http://s.7fzrg67.club/';
// const btbase = window.location.protocol === 'https:' ? 'http://s.7fzrg67.club/' : 'http://s.7fzrg67.club/';
// const base = 'http://localhost:8003/'
// const btbase = 'http://localhost:8003/'
// const Resultbase = 'https://server.ace4dv2.live/';

async function massageRes(res, resolve) {
  const result = await res.json()
  if (result.error && result.error === 'Invalid credentials!') {
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('AbleRed');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('FirstTimeLogin');
    sessionStorage.removeItem('Username');
    sessionStorage.removeItem('loginStatus');
    sessionStorage.removeItem('getUserDownlineData');
    sessionStorage.removeItem('ReceiptData');
    sessionStorage.removeItem('UserIDForSearch');
    sessionStorage.removeItem('UserForSearch');
    sessionStorage.removeItem('SearchDateFrom');
    sessionStorage.removeItem('SearchDateTo');
    sessionStorage.removeItem('PatternForSearch');
    sessionStorage.removeItem('PlatformForSearch');
    sessionStorage.removeItem('ReportData');
    // window.location.href('/');
    resolve('TimeOut');
  } else {
    resolve(result)
  }
}
  
function getPublicKey(Username, Password) {
  return new Promise((resolve) => {
    fetch(`${base}public/pbkey`, {
      method: 'post',
      headers: {
        Authorization: 'hC2XdjZh0Wg47Wqm',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, Password }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

export function getReport(
  Username,
  fromDate,
  toDate,
  referenceUser,
  token,
  redMode,
  platform,
  fromMobile,
) {
  return new Promise(resolve => {
    fetch(`${base}main/getReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        fromDate,
        toDate,
        referenceUser,
        redMode,
        platform,
        fromMobile,
      }),
    }).then(res => {
      massageRes(res, resolve)
    });
  });
}

export function getBetReportMobile(
  Username,
  fromDate,
  toDate,
  referenceUser,
  redMode,
  token,
  platform,
) {
  return new Promise(resolve => {
    fetch(`${base}report/totalBetReportMobileHandler`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        fromDate,
        toDate,
        referenceUser,
        redMode,
        platform,
      }),
    }).then(res => {
      massageRes(res, resolve)
    });
  });
}


export function getReportDetails(
  Username,
  fromDate,
  toDate,
  referenceUser,
  token,
  platform,
  redMode,
) {
  return new Promise(resolve => {
    fetch(`${base}main/getReportMobile`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        fromDate,
        toDate,
        referenceUser,
        platform,
        redMode,
      }),
    }).then(res => {
      massageRes(res, resolve)
    });
  });
}

function LoginAccount(
  Username, 
  Password, 
  loginMethod,
) {
  return new Promise((resolve) => {
    fetch(`${base}public/login`, {
      method: 'post',
      headers: {
        Authorization: 'hC2XdjZh0Wg47Wqm',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        Username,
        Password, 
        loginMethod,
      }),
    }).then((res) => {
      resolve(res.json());
    });
  })
}

function getInformation (Username, Mode, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getInformation`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, Mode }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

export function chgPassword(userID, currentPwd, newPwd, Username, token) {
  return new Promise(resolve => {
    fetch(`${base}main/chgPwd`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userID,
        currentPwd,
        newPwd,
        Username,
      }),
    }).then(res => {
      massageRes(res, resolve)
    });
  });
}


export function getVersion(Username, token) {
  return new Promise(resolve => {
    fetch(`${base}configuration/appVersion`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
      }),
    })
      .then(res => {
        if (res.status === 200 || res.status === 400) {
          massageRes(res, resolve)
        } else {
          resolve('getVersionConnectionError');
        }
      })
      .catch(error => {
        // console.log(error);
        resolve('getVersionConnectionError');
      });
  });
}


export function getDrawResult(Username, Date, Token) {
  return new Promise(resolve => {
    fetch(`${base}main/getResultV2`, {
      method: 'post',
      headers: {
        Authorization: Token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        Date,
      }),
    }).then(res => {
      massageRes(res, resolve)
    });
  });
}

function getUserAndDownline (userID, Username, Token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getDownline`, {
      method: 'post',
      headers: {
        Authorization: Token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function CreateBetOrder(
  UserID,
  originalText,
  Token,
  Username,
  red,
  rebuy,
  nameOfReceipt,
  loginMethod,
) {
  return new Promise(resolve => {
    fetch(`${btbase}main/createBetOrder`, {
      method: 'post',
      headers: {
        Authorization: Token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        UserID,
        originalText,
        red,
        rebuy,
        nameOfReceipt,
        loginMethod,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function CancelBet(orderID, token, Username) {
  return new Promise(resolve => {
    fetch(`${base}main/CancelReceipt`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        orderID,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getOrderOriginalText(
  UserID,
  UserOrderID,
  Username,
  token,
) {
  return new Promise(resolve => {
    fetch(`${base}main/getOrderOriginalText`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserID,
        UserOrderID,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getBetReceipt(
  UserID,
  MinDate,
  MaxDate,
  OrderID,
  ServerID,
  ReceiptOptions,
  token,
  Username,
) {
  return new Promise(resolve => {
    fetch(`${base}main/getBetReceipt`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserID,
        MinDate,
        MaxDate,
        OrderID,
        ServerID,
        ReceiptOptions,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getAutoReceipt(UserID, OrderID, ServerID, token, Username) {
  return new Promise(resolve => {
    fetch(`${base}main/getAuto`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserID,
        OrderID,
        ServerID,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function CreateBetOrderSGD(UserID, originalText, token, Username, sgd, rebuy, nameOfReceipt, loginMethod) {
  return new Promise(resolve => {
    fetch(`${base}main/createBetOrder`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        UserID,
        originalText,
        sgd,
        rebuy,
        nameOfReceipt,
        loginMethod,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getSGDRate(Username, token) {
  return new Promise(resolve => {
    fetch(`${base}configuration/getSGDRate`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function orderNumberReport(
  Username,
  token,
  selectedWord,
  referenceUserID,
) {
  return new Promise(resolve => {
    fetch(`${base}report/orderNumberReport`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        selectedWord,
        referenceUserID,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getCashWalletAmt(selectedUser, Username, token) {
  return new Promise(resolve => {
    fetch(`${base}main/getCashWalletAmt`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        selectedUser,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function todaySalesReportMobile(
  selectedDate,
  platform,
  mode,
  referenceID,
  includeLongTerm,
  Username,
  token,
) {
  return new Promise(resolve => {
    fetch(`${base}report/todaySalesReportMobile`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        selectedDate,
        platform,
        mode,
        referenceID,
        includeLongTerm,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getCapchaCode() {
  return new Promise((resolve) => {
    fetch(`${base}public/getSecretCode`, {
      method: 'post',
      headers: {
        Authorization: 'hC2XdjZh0Wg47Wqm',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function CreateBetOrderTHB(UserID, originalText, token, Username, thai, rebuy, nameOfReceipt, loginMethod) {
  return new Promise(resolve => {
    fetch(`${base}main/createBetOrder`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        UserID,
        originalText,
        thai,
        rebuy,
        nameOfReceipt,
        loginMethod,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}

function getTHAIRate(Username, token) {
  return new Promise(resolve => {
    fetch(`${base}configuration/getTHAIRate`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  });
}


export {
  getPublicKey,
  LoginAccount,
  getInformation,
  getUserAndDownline,
  CreateBetOrder,
  CancelBet,
  getOrderOriginalText,
  getBetReceipt,
  getAutoReceipt,
  CreateBetOrderSGD,
  getSGDRate,
  orderNumberReport,
  getCashWalletAmt,
  todaySalesReportMobile,
  getCapchaCode,
  CreateBetOrderTHB,
  getTHAIRate,
};