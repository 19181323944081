import '../App.css';
import JSEncrypt from 'jsencrypt';
import React from 'react';
import { connect } from 'react-redux';
import {language} from '../language';
import LoadingLogo from '../img/loading.gif';
import Modal from 'react-bootstrap/Modal';
const { getPublicKey, LoginAccount, getCapchaCode } = require('../Api');

class login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      hidePass: true,
      loading: true,
      languageChg: '',
      AlertVisible: false,
      AlertMsg: '',
      captchaInput: '',
      captchaImgCode: '',
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.NumberOnly = this.NumberOnly.bind(this);
    this.captchaInputOnly = this.captchaInputOnly.bind(this);
  }

  async setHidePass() {
    const CheckHidePass = this.state.hidePass;
    if (CheckHidePass === true) {
      this.setState({hidePass: false});
    } else {
      this.setState({hidePass: true});
    }
  }

  async componentDidMount() {
    if (window.location.href.endsWith("v2")) {
      this.props.setLoginStatus('', '', '', '', '', true);
      sessionStorage.setItem('loginv2', true);
    }
    const LanguageCacheData = localStorage.getItem('CacheLanguage') || '';
    const UsernameData = localStorage.getItem('CacheUsername') || '';
    let LanguageShow = '';
    if (LanguageCacheData === '' || LanguageCacheData === 'cn') {
      this.props.setLanguage('cn');
      LanguageShow = 'English';
    } else {
      this.props.setLanguage(LanguageCacheData);
      LanguageShow = '中文';
    }
    this.setState({username: UsernameData, languageChg: LanguageShow, loading: false});
  }
  async handleSubmit() {
    this.setState({ loading: true });
    if (this.state.username === '') {
      alert(language[this.props.currentLanguage].EmptyUsername);
      this.props.setLoginStatus('', '', '', '', '');
      this.setState({ 
        loading: false, 
        AlertVisible: true, 
        AlertMsg: language[this.props.currentLanguage].EmptyUsername})
    } else if (this.state.password === '') {
      alert(language[this.props.currentLanguage].EmptyPW);
      this.setState({ loading: false})
      this.props.setLoginStatus('', '', '', '', '');
    }
    else {
      const Password = this.state.password;
      const PublicKey = await getPublicKey();
      const jsEncrypt = new JSEncrypt();
      jsEncrypt.setPublicKey(PublicKey.publicKey);
      const EnPassword = jsEncrypt.encrypt(Password);
      const usernameData = await LoginAccount(
        this.state.username,
        EnPassword,
        'mobile',
      );
      if (usernameData.message || usernameData.error) {
        this.setState({loading: false});
        if (usernameData.error === 'Invalid Account') {
          alert(language[this.props.currentLanguage].InvalidUser);
        } else if (usernameData.error === 'Invalid Password') {
          alert(language[this.props.currentLanguage].InvalidPW);
        } else if (
          usernameData.error ===
            'Account Has Been Locked, Please Find Admin to Unlock'
        ) {
          alert(language[this.props.currentLanguage].AccountLock);
        } else if (usernameData.message === 'Unable to Login') {
          alert(language[this.props.currentLanguage].superLoginErr);
        } else if (usernameData.message) {
          alert(usernameData.message);
        } else if (usernameData.error) {
          alert(usernameData.error);
        }
      } else {
        localStorage.setItem('CacheUsername', this.state.username);
        sessionStorage.setItem('AbleRed', usernameData.AbleRed);
        sessionStorage.setItem('id', usernameData.ID);
        sessionStorage.setItem('token',  usernameData.token);
        sessionStorage.setItem('userRole',  usernameData.userRole);
        sessionStorage.setItem('FirstTimeLogin',  usernameData.FirstTimeLogin);
        sessionStorage.setItem('loginStatus',  true);
        sessionStorage.setItem('AbleRed',  usernameData.AbleRed);
        sessionStorage.setItem('OneStarBao',  usernameData.OneStarBao);
        sessionStorage.setItem('AbleSGD',  usernameData.AbleSGD);
        sessionStorage.setItem('AbleTHB',  usernameData.AbleTHAI);
        this.props.setAbleRed(usernameData.AbleRed);
        this.props.setAbleSGD(usernameData.AbleSGD);
        this.props.setAbleTHB(usernameData.AbleTHAI);
        this.props.setOneStarBao(usernameData.OneStarBao);
        this.props.setLoginStatus(
          true,
          usernameData.ID,
          usernameData.username,
          usernameData.token,
          usernameData.userRole,
          parseInt(usernameData.FirstTimeLogin),
          true,
        );
      }
    }
  }
  async ChangeLangauge() {
    let LanguageShow = '';
    if (this.state.languageChg === '中文') {
      LanguageShow = 'English';
      this.props.setLanguage('cn');
      await localStorage.setItem('CacheLanguage', 'cn');
    } else {
      LanguageShow = '中文';
      this.props.setLanguage('en');
      await localStorage.setItem('CacheLanguage', 'en');
    }
    this.setState({languageChg: LanguageShow});
  }

  async setAlertVisible() {
    const CheckVisible = this.state.AlertVisible;
    if (CheckVisible === true) {
      this.setState({AlertVisible: false});
    } else {
      this.setState({AlertVisible: true});
    }
  }

  async onKeyEnter(e) {
    if (e.charCode === 13) {
      document.getElementById("LoginButton").click()
    }
  }


  handleChange(value, field) {
    this.setState({ [field]: value });
  }

  NumberOnly(value, field) {
    this.setState({ [field]: value });
  }

  captchaInputOnly(value, field) {
    this.setState({ [field]: value });
  }


  render() {
    if (this.state.loading) {
      return (
        <div className="LoginPageContainer" style={{ justifyContent: 'center'}}>
          <img className= "loadingSize" src={LoadingLogo} alt="BigCo Inc. logo"/>
        </div>
      );
    } else {
      return (
        <div className="LoginPageContainer">
          <div className="LanguageButtonContainer">
            <button className="LanguageButton"  onClick={() => this.ChangeLangauge()}>
              <div className="LanguageButtonText">
                {this.state.languageChg}
              </div>
            </button>
          </div>
          <div className="UserPwContainer">
            <div className="LoginUsernameContainer">
              <input
                className="LoginUsernameInputTextBox"
                type="text"
                // style={{ marginBottom: 30}}
                value={this.state.username}
                onChange={(e) => this.NumberOnly(e.target.value, 'username')}
                ref={(input) => { this.UsernameInput = input; }}
                placeholder={language[this.props.currentLanguage].Username}
              />
            </div>
            <div className="LoginUsernameContainer">
              <input
                className="PasswordInputTextBox"
                type={this.state.hidePass ? 'Password' : 'text'}
                value={this.state.password}
                onChange={(e) => this.handleChange(e.target.value, 'password')}
                placeholder={language[this.props.currentLanguage].Password}
                ref={(input) => { this.PasswordInput = input; }}
                onKeyPress={(e) => this.onKeyEnter(e)}
              />
              <button className='showhideButton' onClick={() => this.setHidePass(!this.state.hidePass)}>
                <div className="showHidepwText"
                  style={{ marginLeft: 'auto'}}>
                  {this.state.hidePass
                    ? language[this.props.currentLanguage].Show
                    : language[this.props.currentLanguage].Hide}
                </div>
              </button>
            </div>
            <button className="LoginButton"  onClick={this.handleSubmit}>
              <div className="LoginButtonText">
                {language[this.props.currentLanguage].Login}
              </div>
            </button>
          </div>
          <Modal transparent={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.AlertVisible}
            contentClassName="br23">
            <Modal.Body className='AlertModalBody' style={{ padding: 10}}>
              <div className='AlertModalHeader'>
                <div className='AlertModalHeaderText'>
                  {language[this.props.currentLanguage].Hint}
                </div>
              </div>
              <div className='AlertModalContentBody' style={{whiteSpace: 'pre-wrap'}}>
                {this.state.AlertMsg}
              </div>
              <div className='AlertModalContentFooter'>
                <button className='AlertModalCloseButton' onClick={() =>  this.setAlertVisible(!this.state.AlertVisible)}> 
                  {language[this.props.currentLanguage].Close}
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      );
    }
   
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    currentLanguage: state.currentLanguage,
    token: state.token,
    role: state.role,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (
      loginStatus,
      ID,
      Username,
      UserToken,
      role,
      firstTimeLog,
    ) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          login: loginStatus,
          id: ID,
          username: Username,
          token: UserToken,
          role: role,
          firstTimeLog: firstTimeLog,
        },
      });
    },
    setAbleRed: AbleOrNo => {
      dispatch({
        type: 'Able_Red',
        payload: {
          AbleRed: AbleOrNo,
        },
      });
    },
    setOneStarBao: OneStarBao => {
      dispatch({
        type: 'OneStarBao',
        payload: {
          OneStarBao: OneStarBao,
        },
      });
    },
    setAbleSGD: AbleOrNo => {
      dispatch({
        type: 'Able_SGD',
        payload: {
          AbleSGD: AbleOrNo,
        },
      });
    },
    setAbleTHB: AbleOrNo => {
      dispatch({
        type: 'Able_THB',
        payload: {
          AbleTHB: AbleOrNo,
        },
      });
    },
    setLanguage: lg => {
      dispatch({type: 'SET_LANGUAGE', payload: lg});
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(login);