export function processAC(keyInFormula) {
  let keyinFormulaReturn = [];
  keyinFormulaReturn = keyInFormula.filter(
    item => item === 'A' || item === 'C',
  );
  if (keyinFormulaReturn.length === 2 && keyinFormulaReturn[0] === 'A') {
    keyinFormulaReturn = ['A', 'C'];
  } else if (
    keyinFormulaReturn.length === 2 &&
    keyinFormulaReturn[0] === 'C'
  ) {
    keyinFormulaReturn = ['C', 'A'];
  } else if (
    keyinFormulaReturn.length === 1 &&
    keyinFormulaReturn[0] === 'A'
  ) {
    keyinFormulaReturn = ['A'];
  } else if (
    keyinFormulaReturn.length === 1 &&
    keyinFormulaReturn[0] === 'C'
  ) {
    keyinFormulaReturn = ['C'];
  } else if (keyinFormulaReturn.length === 0) {
    keyinFormulaReturn = ['A', 'C'];
  }
  return keyinFormulaReturn;
}