export function processPlatformPosition(providedPF, displayPlatform, OriWord) {
  let processWordtoReturn = [];
  let PFConvertArray = [];
  let covertprovidedPF = providedPF.split('');
  for (var x = 0; x < covertprovidedPF.length; x++) {
    PFConvertArray.push(
      covertprovidedPF[x]
        .replace(/1/g, '<green>M</green>')
        .replace(
          /2/g,
          displayPlatform === 'MPTSWBK'
            ? '<green>P</green>'
            : '<green>K</green>',
        )
        .replace(/3/g, '<green>T</green>')
        .replace(/4/g, '<green>S</green>')
        .replace(/5/g, '<green>W</green>')
        .replace(/6/g, '<green>B</green>')
        .replace(
          /7/g,
          displayPlatform === 'MPTSWBK'
            ? '<green>K</green>'
            : '<green>D</green>',
        )
        .replace(/8/g, '<green>8</green>')
        .replace(/9/g, '<green>9</green>')
        .replace(/[*]/g, '🚫'),
    );
  }
  for (var i = 0; i < OriWord.length; i++) {
    if (OriWord[i] >= 0 && OriWord[i] <= 9 && providedPF.includes(OriWord[i])) {
      processWordtoReturn.push(PFConvertArray[OriWord[i] - 1]);
    } else if (OriWord[i] === '#') {
      processWordtoReturn.push('#');
    } else {
      processWordtoReturn.push('🚫');
    }
  }

  return processWordtoReturn;
}
